import { CustomField } from "../interfaces/api/get-custom-fields";

export const CardFields: { [key: string]: CustomField } = {
  assignments: {
    id: "standard-assignments",
    name: "Asignaciones",
    type: "relationship",
  },
  contacts: {
    id: "standard-contacts",
    name: "Contactos",
    type: "relationship",
  },
  companies: {
    id: "standard-companies",
    name: "Companías",
    type: "relationship",
  },
  budget: {
    id: "standard-budget",
    name: "Presupuesto",
    type: "number",
  },
  endDate: {
    id: "standard-endDate",
    name: "Fecha de finalización",
    type: "date",
  },
  comments: {
    id: "standard-endDate",
    name: "Comentarios",
    type: "relationship",
  },
  emails: {
    id: "standard-emails",
    name: "Emails",
    type: "email",
  },
  currency: {
    id: "standard-currency",
    name: "Moneda",
    type: "text",
  },
  tags: {
    id: "standard-tags",
    name: "Etiquetas",
    type: "text",
  },
  eventos: {
    id: "standard-events",
    name: "Eventos",
    type: "relationship",
  },
  createdByAutomation: {
    id: "standard-createdByAutomation",
    name: "Automatizado",
    type: "boolean",
  },
  products: {
    id: "standard-products",
    name: "Productos",
    type: "relationship",
  },
  title: {
    id: "standard-title",
    name: "Titulo",
    type: "text",
  },
};
