import React, { useState, useEffect, ReactNode } from "react";
import { checkServerStatus } from "../../cores/utils";
import { useGlobalCore } from "../../cores/globals";
import { useSocketCore } from "../../cores/socket";

const useServerStatus = (url: string) => {
  const { isOnline, setIsOnline } = useGlobalCore();
  const { disconnected, setDisconnected } = useSocketCore();
  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (disconnected) {
      setIsOnline(null);
      checkServerStatus(url, (isOnline) => setIsOnline(isOnline));

      interval = setInterval(() => {
        checkServerStatus(url, (isOnline) => {
          if (isOnline) setDisconnected(false);
          setIsOnline(isOnline);
        });
      }, 10000);
    } else {
      setIsOnline(null);
      checkServerStatus(url, (isOnline) => setIsOnline(isOnline));
      if (interval) clearInterval(interval);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [disconnected, url]);

  return isOnline;
};

const ServerStatusComponent: React.FC<{
  url: string;
  renderLoading: ReactNode;
  renderOnline: ReactNode;
  renderOffline: ReactNode;
}> = ({ url, renderOffline, renderOnline, renderLoading }) => {
  const isServerOnline = useServerStatus(url);

  return (
    <div>
      {isServerOnline === null
        ? renderLoading
        : isServerOnline
        ? renderOnline
        : renderOffline}
    </div>
  );
};

export default ServerStatusComponent;
