import _ from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { FacebookProvider, Login } from "react-facebook";
import { Button } from "reactstrap";
import {
  INSTAGRAM_GET_LOADING,
  INSTAGRAM_SYNC_LOADING,
} from "../../../../constants/loading.constant";
import { INSTAGRAM_BIND_MODAL } from "../../../../constants/modal.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { useInstagramCore } from "../../../../cores/instagram";
import { APP_ID, SCOPE } from "../../../../cores/instagram/config";
import { useMeCore } from "../../../../cores/me";
import {
  SocialProviderId,
  SocialProviderName,
} from "../../../../cores/socialPost/config";
import { useIntl } from "react-intl";
import { SocialProvider } from "../../../../cores/socialPost/interfaces/socialProvider";
import { daysRemainingFromToday } from "../../../utils/dateUtils";
import { InstagramIcon } from "./InstagramIcon";
import InstagramLoginForm from "./InstagramLoginForm";

interface InstagramProviderResponse {
  tokenDetail: { accessToken: string };
}

export default function InstagramAuthButton() {
  const intl = useIntl();
  const { get, settings, unbind, getAccounts } = useInstagramCore();
  const { company, getMeSocialSettings } = useMeCore();
  const { turnOnModal, isLoading } = useGlobalCore();

  useEffect(() => {
    get(company!.id);
  }, [company, get]);

  const socialProvider = useMemo(
    (): SocialProvider => ({
      id: SocialProviderId.Instagram,
      name: SocialProviderName.Instagram,
      icon: <InstagramIcon size="5x" active={!_.isEmpty(settings)} />,
      displayName: settings?.name,
      avatar: settings?.avatarUrl,
    }),
    [settings]
  );

  const remainingBindDays = useMemo(
    () =>
      settings?.expiresIn ? daysRemainingFromToday(settings?.expiresIn) : null,
    [settings]
  );

  const handleOnError = useCallback(
    (error: string) => console.error(error),
    []
  );
  const handleOnCompleted = useCallback(
    async ({ tokenDetail: { accessToken } }: InstagramProviderResponse) => {
      await getAccounts(company!.id, accessToken);
      turnOnModal(
        INSTAGRAM_BIND_MODAL,
        <InstagramLoginForm accessToken={accessToken} />
      );
    },
    [company, getAccounts, turnOnModal]
  );

  const handleUnbind = useCallback(() => {
    unbind(company!.id, () => {
      getMeSocialSettings(company?.id!);
    });
  }, [company, unbind, getMeSocialSettings]);

  const button = useMemo(() => {
    return _.isEmpty(settings) ? (
      <FacebookProvider version="v9.0" appId={APP_ID}>
        <Login
          scope={SCOPE}
          onCompleted={handleOnCompleted}
          onError={handleOnError}
        >
          {({ handleClick }: { handleClick: () => void }) => (
            <Button
              className="w-100 mx-auto"
              color="primary"
              onClick={handleClick}
            >
              {intl.formatMessage({ id: "app.home.syncUp" })}
            </Button>
          )}
        </Login>
      </FacebookProvider>
    ) : (
      <Button className="w-100" color="primary" outline onClick={handleUnbind}>
        {intl.formatMessage({ id: "app.home.disconnect" })}
      </Button>
    );
  }, [handleOnCompleted, handleOnError, handleUnbind, settings, intl]);

  return button;
}
