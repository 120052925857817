import { Event } from "../../event/interface/api/get-event";
import {
  GetCalendarEventResponse,
  GetCalendarEventsResponse,
  OfficeEvent,
} from "../interface/api/get-office365-calendar";
import {
  GetOfficeEvent,
  Wembii365Event,
} from "../interface/api/get-office365-event";
import { GetOffice365LoginResponse } from "../interface/api/get-office365-login";
import {
  Office365Setting,
  PostOffice365BindResponse,
} from "../interface/api/post-office365-bind";
import { PostOffice365EmailResponse } from "../interface/api/post-office365-email";

export const office365SettingMapper = {
  mapResponseToId(response: PostOffice365EmailResponse) {
    const { id } = response?.payload;
    return id;
  },

  mapResponseToOffice365Setting(
    response: PostOffice365BindResponse
  ): Office365Setting {
    const { office365Setting } = response?.payload;
    return office365Setting;
  },
  mapResponseToOffice365Events(
    response: GetCalendarEventsResponse
  ): OfficeEvent[] {
    return response?.payload.calendars.value;
  },
  mapResponseToOffice365Event(response: GetOfficeEvent): Event {
    return response.payload.event;
  },

  mapResponseToOffice365LoginUrl(response: GetOffice365LoginResponse): {
    url: string;
  } {
    const { url } = response?.payload;
    return { url };
  },
};
