import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { LinkedIn } from "react-linkedin-login-oauth2";
import { Button } from "reactstrap";
import {
  LINKED_IN_GET_LOADING,
  LINKED_IN_SYNC_LOADING,
} from "../../../../constants/loading.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { useLinkedInCore } from "../../../../cores/linkedIn";
import { APP_ID, SCOPE } from "../../../../cores/linkedIn/config";
import { useMeCore } from "../../../../cores/me";
import { useRouterCore } from "../../../../cores/router";
import {
  SocialProviderId,
  SocialProviderName,
} from "../../../../cores/socialPost/config";
import { useIntl } from "react-intl";
import { SocialProvider } from "../../../../cores/socialPost/interfaces/socialProvider";
import { daysRemainingFromToday } from "../../../utils/dateUtils";
import SocialCard from "../SocialCard";
import { LinkedInIcon } from "./LinkedInIcon";

export default function LinkedInAuthButton() {
  const intl = useIntl();
  const { get, settings, bind, unbind } = useLinkedInCore();
  const { company, getMeSocialSettings } = useMeCore();
  const { routes } = useRouterCore();
  const { isLoading } = useGlobalCore();

  useEffect(() => {
    get(company!.id);
  }, [company, get]);

  const socialProvider = useMemo(
    (): SocialProvider => ({
      id: SocialProviderId.LinkedIn,
      name: SocialProviderName.LinkedIn,
      icon: <LinkedInIcon size="5x" active={!_.isEmpty(settings)} />,
      displayName: settings?.name,
      avatar: settings?.avatarUrl,
    }),
    [settings]
  );

  const remainingBindDays = useMemo(
    () =>
      settings?.expiresIn ? daysRemainingFromToday(settings?.expiresIn) : null,
    [settings]
  );

  const onFailure = useCallback((error: string) => console.error(error), []);
  const handleBind = useCallback(
    ({ code }: { code: string }) =>
      bind(company!.id, code, () => {
        getMeSocialSettings(company?.id!);
      }),
    [bind, company, getMeSocialSettings]
  );

  const handleUnbind = useCallback(
    () => unbind(company!.id),
    [company, unbind]
  );

  const button = useMemo(() => {
    return _.isEmpty(settings) ? (
      <LinkedIn
        clientId={APP_ID}
        onFailure={onFailure}
        onSuccess={handleBind}
        redirectUri={`${window.location.origin}${routes.linkedInPopUp}`}
        scope={SCOPE}
        renderElement={({ onClick }: { onClick: () => void }) => (
          <Button className="w-100 mx-auto" color="primary" onClick={onClick}>
            {intl.formatMessage({ id: "app.home.syncUp" })}
          </Button>
        )}
      ></LinkedIn>
    ) : (
      <Button className="w-100" color="primary" outline onClick={handleUnbind}>
        {intl.formatMessage({ id: "app.home.disconnect" })}
      </Button>
    );
  }, [
    handleBind,
    handleUnbind,
    onFailure,
    routes.linkedInPopUp,
    settings,
    intl,
  ]);

  return button;
}
