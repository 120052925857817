export const LINKED_IN_BUSINESS_SYNC_LOADING =
  "LINKED_IN_BUSINESS_SYNC_LOADING";
export const LINKED_IN_BUSINESS_GET_LOADING = "LINKED_IN_BUSINESS_GET_LOADING";
export const LINKED_IN_BUSINESS_POST_LOADING =
  "LINKED_IN_BUSINESS_POST_LOADING";

export const INSTAGRAM_MENSSEGER_SYNC_LOADING =
  "INSTAGRAM_MENSSEGER_SYNC_LOADING";
export const INSTAGRAM_MENSSEGER_GET_LOADING =
  "INSTAGRAM_MENSSEGER_GET_LOADING";

export const INSTAGRAM_SYNC_LOADING = "INSTAGRAM_SYNC_LOADING";
export const INSTAGRAM_POST_LOADING = "INSTAGRAM_POST_LOADING";
export const INSTAGRAM_GET_LOADING = "INSTAGRAM_GET_LOADING";
export const INSTAGRAM_INSIGHT_LOADING = "INSTAGRAM_INSIGHT_LOADING";

export const LINKED_IN_SYNC_LOADING = "LINKED_IN_SYNC_LOADING";
export const LINKED_IN_GET_LOADING = "LINKED_IN_GET_LOADING";
export const LINKED_IN_POST_LOADING = "LINKED_IN_POST_LOADING";
export const LINKED_IN_PROFILES_GET_LOADING = "LINKED_IN_PROFILES_GET_LOADING";

export const FACEBOOK_SYNC_LOADING = "FACEBOOK_SYNC_LOADING";
export const FACEBOOK_POST_LOADING = "FACEBOOK_POST_LOADING";
export const FACEBOOK_GET_LOADING = "FACEBOOK_GET_LOADING";
export const FACEBOOK_INSIGHT_LOADING = "FACEBOOK_INSIGHT_LOADING";

export const FACEBOOK_ADS_SYNC_LOADING = "FACEBOOK_ADS_SYNC_LOADING";
export const FACEBOOK_ADS_GET_LOADING = "FACEBOOK_ADS_GET_LOADING";

export const GOOGLE_SYNC_LOADING = "GOOGLE_SYNC_LOADING";
export const GOOGLE_POST_LOADING = "GOOGLE_POST_LOADING";
export const GOOGLE_GET_LOADING = "GOOGLE_GET_LOADING";
export const GOOGLE_INSIGHT_LOADING = "GOOGLE_INSIGHT_LOADING";

export const GMAIL_SYNC_LOADING = "GMAIL_SYNC_LOADING";
export const GMAIL_GET_LOADING = "GMAIL_GET_LOADING";
export const GMAIL_POST_LOADING = "GMAIL_POST_LOADING";

export const OFFICE365_SYNC_LOADING = "OFFICE365_SYNC_LOADING";
export const OFFICE365_GET_LOADING = "OFFICE365_GET_LOADING";
export const OFFICE365_POST_LOADING = "OFFICE365_POST_LOADING";

export const OFFICE365_ME_SYNC_LOADING = "OFFICE365_ME_SYNC_LOADING";
export const OFFICE365_ME_POST_LOADING = "OFFICE365_ME_POST_LOADING";
export const OFFICE365_ME_GET_LOADING = "OFFICE365_ME_GET_LOADING";

export const EVENTS_GET_LOADING = "EVENTS_GET_LOADING";
export const EVENT_GET_LOADING = "EVENT_GET_LOADING";
export const EVENT_POST_LOADING = "EVENT_POST_LOADING";
export const CALENDAR_TIME_LOADING = "CALENDAR_TIME_LOADING";

export const GOOGLE_CALENDAR_SYNC_LOADING = "GOOGLE_CALENDAR_SYNC_LOADING";
export const GOOGLE_CALENDAR_GET_LOADING = "GOOGLE_CALENDAR_GET_LOADING";
export const GOOGLE_CALENDAR_POST_LOADING = "GOOGLE_CALENDAR_POST_LOADING";

export const SMTP_SYNC_LOADING = "SMTP_SYNC_LOADING";
export const SMTP_GET_LOADING = "SMTP_GET_LOADING";
export const SMTP_POST_LOADING = "SMTP_POST_LOADING";

export const WHATSAPP_SYNC_LOADING = "WHATSAPP_SYNC_LOADING";
export const WHATSAPP_GET_LOADING = "WHATSAPP_GET_LOADING";
export const WHATSAPP_POST_LOADING = "WHATSAPP_POST_LOADING";

export const GMAIL_ME_SYNC_LOADING = "GMAIL_ME_SYNC_LOADING";
export const GMAIL_ME_POST_LOADING = "GMAIL_ME_POST_LOADING";
export const GMAIL_ME_GET_LOADING = "GMAIL_ME_GET_LOADING";

export const GOOGLE_CALENDAR_ME_SYNC_LOADING =
  "GOOGLE_CALENDAR_ME_SYNC_LOADING";
export const GOOGLE_CALENDAR_ME_POST_LOADING =
  "GOOGLE_CALENDAR_ME_POST_LOADING";
export const GOOGLE_CALENDAR_ME_GET_LOADING = "GOOGLE_CALENDAR_ME_GET_LOADING";

export const SMTP_ME_SYNC_LOADING = "SMTP_ME_SYNC_LOADING";
export const SMTP_ME_GET_LOADING = "SMTP_ME_GET_LOADING";

export const JUMPSELLER_SYNC_LOADING = "JUMPSELLER_SYNC_LOADING";
export const JUMPSELLER_POST_LOADING = "JUMPSELLER_POST_LOADING";
export const JUMPSELLER_GET_LOADING = "JUMPSELLER_GET_LOADING";
export const JUMPSELLER_INSIGHT_LOADING = "JUMPSELLER_INSIGHT_LOADING";
export const JUMPSELLER_ORDER_LOADING = "JUMPSELLER_ORDER_LOADING";
export const JUMPSELLER_ORDER_UPDATING = "JUMPSELLER_ORDER_UPDATING";

export const MAILCHIMP_SYNC_LOADING = "MAILCHIMP_SYNC_LOADING";
export const MAILCHIMP_AUDIENCE_LOADING = "MAILCHIMP_AUDIENCE_LOADING";
export const MAILCHIMP_GET_LOADING = "MAILCHIMP_GET_LOADING";

export const MAIN_LAYOUT_LOADING = "MAIN_LAYOUT_LOADING";
export const LAYOUT_LOADING = "LAYOUT_LOADING";
export const VIDEO_UPLOAD_LOADING = "VIDEO_UPLOAD_LOADING";

export const TWITTER_SYNC_LOADING = "TWITTER_SYNC_LOADING";
export const TWITTER_POST_LOADING = "TWITTER_POST_LOADING";
export const TWITTER_GET_LOADING = "TWITTER_GET_LOADING";
export const TWITTER_PROFILES_GET_LOADING = "TWITTER_PROFILES_GET_LOADING";


export const TIKTOK_SYNC_LOADING = "TIKTOK_SYNC_LOADING";
export const TIKTOK_POST_LOADING = "TIKTOK_POST_LOADING";
export const TIKTOK_GET_LOADING = "TIKTOK_GET_LOADING";
export const TIKTOK_PROFILES_GET_LOADING = "TIKTOK_PROFILES_GET_LOADING";

export const OPENAI_SYNC_LOADING = "OPENAI_SYNC_LOADING";
export const OPENAI_GET_LOADING = "OPENAI_GET_LOADING";
export const OPENAI_GET_TEMPLATES_LOADING = "OPENAI_GET_TEMPLATES_LOADING";

export const WOO_COMMERCE_SYNC_LOADING = "WOO_COMMERCE_SYNC_LOADING";
export const WOO_COMMERCE_GET_LOADING = "WOO_COMMERCE_GET_LOADING";
export const WOO_COMMERCE_INSIGHT_LOADING = "WOO_COMMERCE_INSIGHT_LOADING";
export const WOO_COMMERCE_ORDER_LOADING = "WOO_COMMERCE_ORDER_LOADING";
export const WOO_COMMERCE_ORDER_UPDATING = "WOO_COMMERCE_ORDER_UPDATING";

export const MERCADOLIBRE_SYNC_LOADING = "MERCADOLIBRE_SYNC_LOADING";
export const MERCADOLIBRE_GET_LOADING = "MERCADOLIBRE_GET_LOADING";
export const MERCADOLIBRE_INSIGHT_LOADING = "MERCADOLIBRE_INSIGHT_LOADING";
export const MERCADOLIBRE_ORDER_LOADING = "MERCADOLIBRE_ORDER_LOADING";

export const ADVERTISEMENT_LOADING = "ADVERTISEMENT_LOADING";
export const ADVERTISEMENT_POST_LOADING = "ADVERTISEMENT_POST_LOADING";

export const CONTACTS_LOADING = "CONTACTS_LOADING";
export const CONTACT_LOADING = "CONTACT_LOADING";
export const CONTACTS_CSV_LOADING = "CONTACTS_CSV_LOADING";
export const CONTACT_POST_LOADING = "CONTACT_POST_LOADING";
export const CONTACTS_IMPORT_LOADING = "CONTACTS_IMPORT_LOADING";
export const CONTACT_COMPANIES_LOADING = "CONTACT_COMPANIES_LOADING";
export const CONTACTS_EXPORT_LOADING = "CONTACTS_EXPORT_LOADING";
export const COMPANIES_EXPORT_LOADING = "COMPANIES_EXPORT_LOADING";

export const PRODUCTS_LOADING = "PRODUCTS_LOADING";

export const AUTOMATIONS_LOADING = "AUTOMATIONS_LOADING";

export const ADMIN_USER_LOADING = "ADMIN_USER_LOADING";
export const ADMIN_COMPANY_LOADING = "ADMIN_COMPANY_LOADING";

export const ACCOUNT_POST_LOADING = "ACCOUNT_POST_LOADING";
export const ACCOUNT_PASSWORD_POST_LOADING = "ACCOUNT_PASSWORD_POST_LOADING";
export const ACCOUNT_PASSWORD_EMAIL_LOADING = "ACCOUNT_PASSWORD_EMAIL_LOADING";
export const RESEND_CONFIRMATION_LOADING = "RESEND_CONFIRMATION_LOADING";

export const RESELLER_LOADING = "RESELLER_LOADING";

export const HISTORY_POST_LOADING = "HISTORY_POST_LOADING";

export const WEB_FORMS_LOADING = "WEB_FORMS_LOADING";
export const FACEBOOK_FORMS_LOADING = "FACEBOOK_FORMS_LOADING";
export const FACEBOOK_FORM_LEADS_LOADING = "FACEBOOK_FORM_LEADS_LOADING";

export const CHAT_MESSAGES_LOADING = "CHAT_MESSAGES_LOADING";

export const CHAT_MESSAGES_SENDING = "CHAT_MESSAGES_SENDING";

export const SOCIAL_CHAT_LOADING = "SOCIAL_CHAT_LOADING";
export const INSTAGRAM_CHAT_LOADING = "INSTAGRAM_CHAT_LOADING";
export const FACEBOOK_CHAT_LOADING = "FACEBOOK_CHAT_LOADING";
export const WHATSAPP_CHAT_LOADING = "WHATSAPP_CHAT_LOADING";
export const WEB_CHAT_LOADING = "WEB_CHAT_LOADING";

export const GENERATING_REPORT = "GENERATING_REPORT";

export const PLAN_LOADING = "PLAN_LOADING";
export const UPGRADE_PLAN_LOADING = "UPGRADE_PLAN_LOADING";
export const PLAN_COUPON_LOADING = "PLAN_COUPON_LOADING";
export const CARD_CHECK_LOADING = "CARD_CHECK_LOADING";

export const BOARDS_LOADING = "BOARDS_LOADING";
export const BOARDS_LIST_RESTRICTIONS_LOADING = "BOARDS_LIST_RESTRICTIONS_LOADING";
export const BOARD_LOADING = "BOARD_LOADING";
export const BOARD_EXPORT_LOADING = "BOARD_EXPORT_LOADING";
export const BOARD_ACTIVITY_EXPORT_LOADING = "BOARD_ACTIVITY_EXPORT_LOADING";
export const BOARD_CARD_LOADING = "BOARD_CARD_LOADING";
export const BOARD_CARD_UPDATE_LOADING = "BOARD_CARD_UPDATE_LOADING";
export const BOARD_CARD_MOVE_LOADING = "BOARD_CARD_MOVE_LOADING";
export const UPLOADING_BOARD_FILES = "UPLOADING_BOARD_FILES";
export const BOARDS_INSIGHT_LOADING = "BOARDS_INSIGHT_LOADING";
export const MOVE_CARDS_LOADING = "MOVE_CARDS_LOADING";
export const BOARD_IMPORT_LOADING = "BOARD_IMPORT_LOADING";

export const TASKS_LOADING = "TASKS_LOADING";
export const TASKS_EXPORT_LOADING = "TASKS_EXPORT_LOADING";
export const TASK_LOADING = "TASK_LOADING";

export const EDIT_ME_USER_LOADING = "EDIT_ME_USER_LOADING";
export const EDIT_ME_AVATAR_USER_LOADING = "EDIT_ME_AVATAR_USER_LOADING";

export const EVENT_CREATION_LOADING = "EVENT_CREATION_LOADING";

export const AUTOMATION_LOADING = "AUTOMATION_LOADING";
