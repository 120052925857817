import { DeleteCompanyResponse } from "../interfaces/api/delete-company";
import { DeleteCustomFieldResponse } from "../interfaces/api/delete-custom-field";
import {
  GetCompaniesPayloadResponse,
  GetCompaniesResponse,
} from "../interfaces/api/get-companies";
import {
  GetCompaniesExportPayloadResponse,
  GetCompaniesExportResponse,
} from "../interfaces/api/get-companies-export";
import {
  GetCompanyPayloadResponse,
  GetCompanyResponse,
} from "../interfaces/api/get-company";
import { GetContactConfigResponse } from "../interfaces/api/get-config";
import {
  GetContactPayloadResponse,
  GetContactResponse,
} from "../interfaces/api/get-contact";
import {
  GetContactStatesPayloadResponse,
  GetContactStatesResponse,
} from "../interfaces/api/get-contact-states";
import {
  GetContactTagsResponse,
  GetContactTagsPayloadResponse,
} from "../interfaces/api/get-contact-tags";
import {
  GetContactsResponse,
  GetPayloadCountResponse,
  GetPayloadResponse,
} from "../interfaces/api/get-contacts";
import {
  GetContactsExportPayload,
  GetContactsExportResponse,
} from "../interfaces/api/get-contacts-export";
import {
  GetCustomFieldsPayload,
  GetCustomFieldsResponse,
} from "../interfaces/api/get-custom-fields";
import {
  CommentPayload,
  PostCommentResponse,
} from "../interfaces/api/post-comment";
import {
  PostCompanyPayload,
  PostCompanyResponse,
} from "../interfaces/api/post-company";
import { PostContactConfigResponse } from "../interfaces/api/post-config";
import {
  PostCustomFieldPayload,
  PostCustomFieldResponse,
} from "../interfaces/api/post-custom-field";
import { PutCompanyResponse } from "../interfaces/api/put-company";
import {
  PutContactPayloadResponse,
  PutContactResponse,
} from "../interfaces/api/put-contact";
import { PutCustomFieldResponse } from "../interfaces/api/put-custom-field";

export const contactsMapper = {
  mapResponseToSimpleContact(
    response: PutContactResponse
  ): PutContactPayloadResponse {
    const { contact } = response?.payload;
    return { contact };
  },
  mapResponseToContactsCount(
    response: GetContactsResponse
  ): GetPayloadCountResponse {
    const { total } = response?.payload;
    return { total };
  },
  mapResponseToContacts(response: GetContactsResponse): GetPayloadResponse {
    const { contacts, total } = response?.payload;
    return { contacts, total };
  },
  mapResponseToContact(
    response: GetContactResponse
  ): GetContactPayloadResponse {
    const { contact, cards } = response?.payload;
    return { contact, cards };
  },
  mapResponseToContactsExport(
    response: GetContactsExportResponse
  ): GetContactsExportPayload {
    const { contacts } = response?.payload;
    return { contacts };
  },
  mapResponseToCustomFields(
    response: GetCustomFieldsResponse
  ): GetCustomFieldsPayload {
    const { fields } = response?.payload;
    return { fields };
  },
  mapResponseToCustomField(
    response: PostCustomFieldResponse | PutCustomFieldResponse
  ): PostCustomFieldPayload {
    const { field } = response?.payload;
    return { field };
  },
  mapResponseToContactTags(
    response: GetContactTagsResponse
  ): GetContactTagsPayloadResponse {
    const { tags } = response?.payload;
    return { tags };
  },
  mapResponseToContactState(
    response: GetContactStatesResponse
  ): GetContactStatesPayloadResponse {
    const { states } = response?.payload;
    return { states };
  },
  mapResponseToCompanies(
    response: GetCompaniesResponse
  ): GetCompaniesPayloadResponse {
    const { companies, total } = response?.payload;
    return { companies, total };
  },
  mapResponseToCompaniesCount(response: GetCompaniesResponse): {
    total: number;
  } {
    const { total } = response?.payload;
    return { total };
  },
  mapResponseToExportCompanies(
    response: GetCompaniesExportResponse
  ): GetCompaniesExportPayloadResponse {
    const { companies } = response?.payload;
    return { companies };
  },
  mapResponseToContactCompany(
    response: GetCompanyResponse
  ): GetCompanyPayloadResponse {
    const { company } = response?.payload;
    return { company };
  },
  mapResponseToCompany(
    response: PostCompanyResponse | PutCompanyResponse
  ): PostCompanyPayload {
    const { company } = response?.payload;
    return { company };
  },
  mapResponseToId(response: DeleteCompanyResponse | DeleteCustomFieldResponse) {
    const { id } = response?.payload;
    return { id };
  },
  mapResponseToContactConfigs(response: GetContactConfigResponse) {
    const { config } = response?.payload;
    return { config };
  },
  mapResponseToContactConfig(response: PostContactConfigResponse) {
    const { config } = response?.payload;
    return { config };
  },
  mapResponseToComment(response: PostCommentResponse): CommentPayload {
    const comment = response?.payload?.comment;
    return { comment };
  },
};
