import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  OPENAI_GET_LOADING,
  OPENAI_GET_TEMPLATES_LOADING,
  OPENAI_SYNC_LOADING,
} from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { openAiApiService } from "./services/api";
import { useIntl } from "react-intl";
import {
  getOpenAiFailureAction,
  getOpenAiInitAction,
  getOpenAiSuccessAction,
  getSocialTemplatesFailureAction,
  getSocialTemplatesInitAction,
  getSocialTemplatesSuccessAction,
  postBindOpenAiInitAction,
  postBindOpenAiSuccessAction,
  postUnbindOpenAiFailureAction,
  postUnbindOpenAiInitAction,
  postUnbindOpenAiSuccessAction,
} from "./store/api-actions";
import {
  settingSelector,
  showTemplatesSelector,
  socialTemplatesSelector,
} from "./store/selector";
import { GetSocialTemplateRequest } from "./interface/api/get-social-templates";
import { setShowTemplatesAction } from "./store/actions";
import { Errors } from "../../constants/errors";

export function useOpenAiCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const settings = useSelector(settingSelector);
  const templates = useSelector(socialTemplatesSelector);
  const showTemplates = useSelector(showTemplatesSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();

  const unbind = useCallback(
    async (companyId: string, onSuccess?: () => void) => {
      try {
        dispatch(postUnbindOpenAiInitAction());
        await openAiApiService.unbind(companyId);
        dispatch(postUnbindOpenAiSuccessAction());
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postUnbindOpenAiFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const bind = useCallback(
    async (
      companyId: string,
      name: string,
      token: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postBindOpenAiInitAction());
        turnOnLoading(OPENAI_SYNC_LOADING);
        const response = await openAiApiService.bind(companyId, {
          name,
          token,
        });
        dispatch(postBindOpenAiSuccessAction({ settings: response }));
        turnOffLoading(OPENAI_SYNC_LOADING, 500);
        toast.success(intl.formatMessage({ id: "app.toast.sucess55" }));
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(OPENAI_SYNC_LOADING);
        toast.error(intl.formatMessage({ id: "app.toast.error81" }));
        console.error(error);
        dispatch(postUnbindOpenAiFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const get = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getOpenAiInitAction());
        turnOnLoading(OPENAI_GET_LOADING);
        const response = await openAiApiService.get(companyId);
        dispatch(getOpenAiSuccessAction({ settings: response }));
        turnOffLoading(OPENAI_GET_LOADING, 1500);
      } catch (error: any) {
        turnOffLoading(OPENAI_GET_LOADING);
        console.error(error);
        dispatch(getOpenAiFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );

  const getSocialTemplate = useCallback(
    async (
      companyId: string,
      body: GetSocialTemplateRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(getSocialTemplatesInitAction());
        turnOnLoading(OPENAI_GET_TEMPLATES_LOADING);
        const response = await openAiApiService.getSocialPosts(companyId, body);
        dispatch(getSocialTemplatesSuccessAction({ templates: response }));
        turnOffLoading(OPENAI_GET_TEMPLATES_LOADING, 1500);
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(OPENAI_GET_TEMPLATES_LOADING);
        if (error.request.status === 403) {
          const code: number = JSON.parse(error.request.response).code || 0;
          const message: string = JSON.parse(error.request.response).message;
          switch (code) {
            case Errors.InsufficientQuota:
              toast.warning(
                intl.formatMessage({ id: "app.social.openai.quota" })
              );
              dispatch(
                getSocialTemplatesFailureAction({
                  errorCode: Errors.InsufficientQuota,
                  errorMessage: message,
                })
              );
              break;
            case Errors.InvalidApiKey:
              toast.warning(
                intl.formatMessage({ id: "app.social.openai.invalidapikey" })
              );
              dispatch(
                getSocialTemplatesFailureAction({
                  errorCode: Errors.InvalidApiKey,
                  errorMessage: message,
                })
              );
              break;
            default:
              toast.error(intl.formatMessage({ id: "app.toast.error82" }));
              console.error(error, code);
          }
        } else {
          toast.error(intl.formatMessage({ id: "app.toast.error82" }));
          console.error(error);
        }
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );
  const getEmailTemplate = useCallback(
    async (
      companyId: string,
      body: GetSocialTemplateRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(getSocialTemplatesInitAction());
        turnOnLoading(OPENAI_GET_TEMPLATES_LOADING);
        const response = await openAiApiService.getEmailPosts(companyId, body);
        dispatch(getSocialTemplatesSuccessAction({ templates: response }));
        turnOffLoading(OPENAI_GET_TEMPLATES_LOADING, 1500);
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(OPENAI_GET_TEMPLATES_LOADING);
        if (error.request.status === 403) {
          const code: number = JSON.parse(error.request.response).code || 0;
          const message: string = JSON.parse(error.request.response).message;
          switch (code) {
            case Errors.InsufficientQuota:
              toast.warning(
                intl.formatMessage({ id: "app.social.openai.quota" })
              );
              dispatch(
                getSocialTemplatesFailureAction({
                  errorCode: Errors.InsufficientQuota,
                  errorMessage: message,
                })
              );
              break;
            case Errors.InvalidApiKey:
              toast.warning(
                intl.formatMessage({ id: "app.social.openai.invalidapikey" })
              );
              dispatch(
                getSocialTemplatesFailureAction({
                  errorCode: Errors.InvalidApiKey,
                  errorMessage: message,
                })
              );
              break;
            default:
              toast.error(intl.formatMessage({ id: "app.toast.error82" }));
              console.error(error, code);
          }
        } else {
          toast.error(intl.formatMessage({ id: "app.toast.error82" }));
          console.error(error);
        }
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  return {
    settings,
    templates,
    showTemplates,
    setShowTemplates: (showTemplates: boolean) =>
      dispatch(setShowTemplatesAction({ showTemplates })),
    getSocialTemplate,
    unbind,
    bind,
    get,
    getEmailTemplate,
  };
}
