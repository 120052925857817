import React, { useEffect, useMemo } from "react";
import { useBoardCore } from "../../../cores/boards";
import AddListContainer from "./creator/AddListContainer";
import ColumnBoard from "./ColumnBoard";
import { Container } from "react-smooth-dnd";
import { useMeCore } from "../../../cores/me";
import {
  Board,
  BoardList,
} from "../../../cores/boards/interfaces/api/get-board";

interface Props {
  board: Board | null;
}

interface DropResult {
  removedIndex: number | null;
  addedIndex: number | null;
  payload?: BoardList;
}

export default function BoardPanel({ board }: Props) {
  const { company, isAdmin, isReseller, companyRole, user } = useMeCore();
  const { lists, putListOrder, changeListOrder } =
    useBoardCore();

  useEffect(() => {}, [board]);

  const onDragEnd = (dropResult: DropResult) => {
    const { removedIndex, addedIndex, payload } = dropResult;

    if (removedIndex === addedIndex) return;

    changeListOrder(payload?.id!, addedIndex!);
    putListOrder(company?.id!, board?.id!, payload?.id!, addedIndex!);
  };

  const canModify = useMemo(
    () =>
      isAdmin ||
      isReseller ||
      companyRole?.owner ||
      companyRole?.admin ||
      board?.userRoles.find((role) => role.user.id === user?.id)?.owner,
    [isAdmin, isReseller, user, companyRole, board]
  );

  return (
    <div className="d-flex overflow-auto my-4" style={{ height: "100vh" }}>
      <Container
      orientation="horizontal"
        autoScrollEnabled={false}
        onDrop={onDragEnd}
        getChildPayload={(index) => lists[index]}
        dropPlaceholder={{
          animationDuration: 150,
          showOnTop: true,
          className: "cards-drop-preview",
        }}
        dragHandleSelector=".column-drag-handle"
      >
        
        {lists.map((list) => (
          <ColumnBoard key={list.id} board={board} list={list}  />
        ))}
      </Container>
      {canModify && <AddListContainer board={board?.id!} />}
    </div>
  );
}
