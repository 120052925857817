import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { Col, Container, Row } from "reactstrap";
import { useTiktokCore } from "../../../../cores/tiktok";
import { useSocialPostCore } from "../../../../cores/socialPost";
import { SocialProviderId } from "../../../../cores/socialPost/config";
import { PostError } from "../../../../cores/socialPost/interfaces/postError";
import { ErrorList } from "./ErrorList";
import { MediaPreview } from "./MediaPreview";

export const TiktokPostPreview = () => {
  const { settings } = useTiktokCore();
  const { text, mentions, postErrors } = useSocialPostCore();
  const { name, avatarUrl } = settings || {};

  const providerErrors: PostError[] = useMemo(
    () =>
      postErrors.filter(
        ({ socialProviderId }) => socialProviderId === SocialProviderId.TikTok
      ),
    [postErrors]
  );

  const formattedText = useMemo(() => {
    const orderMentions = mentions.sort((a, b) =>
      a.offset > b.offset ? -1 : 1
    );

    let txt = text;
    orderMentions.forEach((mention) => {
      const submention = mention.mentions.find(
        (submention) => submention.socialProviderId === SocialProviderId.TikTok
      );
      const name = submention
        ? `<span style="color:#FE2C55;">@${submention.username}</span>`
        : mention.mentions[0].username;
      const offset = mention.offset + mention.mentions[0].username.length + 1;
      txt = txt.slice(0, mention.offset) + name + txt.slice(offset);
    });

    return txt;
  }, [text, mentions]);

  return providerErrors.length > 0 ? (
    <ErrorList errors={providerErrors} />
  ) : (
    <div
      style={{ width: "290px", height: "512px" }}
      className="tiktokPreview-container neu-out "
    >
      <Container fluid className="position-relative tiktok-preview-wrapper ">
        <div className="tiktok-preview-header my-3 d-flex">
          <FontAwesomeIcon
            size="1x"
            icon={["fas", "tv"]}
            className="text-white font-bold my-auto mx-4"
          />
          <div className=" container justify-content-between d-flex ">
            <span
              style={{ fontSize: "10px" }}
              className="text-light font-bold text-sm"
            >
              Explorar
            </span>
            <span
              style={{ fontSize: "10px" }}
              className="text-light font-bold text-sm"
            >
              Siguiendo
            </span>
            <span
              style={{ fontSize: "10px" }}
              className="text-light font-bold text-sm"
            >
              Para ti
            </span>
          </div>
          <FontAwesomeIcon
            size="1x"
            icon={["fas", "search"]}
            className="text-white font-bold my-auto mx-4"
          />
        </div>
        <Row className="tiktok-preview-content">
          <Col xs="12" className="p-0">
            <MediaPreview className="tiktok-preview-media opacity-50" />
          </Col>
        </Row>
        <div className="position-absolute bottom-0 w-100 mx-auto">
          <div className="container bg-dark w-100 h-100 background-drop"></div>
          <p style={{ fontSize: "12px" }} className="font-bold px-2 mt-1">
            {name}
          </p>
          <p style={{ fontSize: "10px" }} className="px-2 mt-1">
            {formattedText.slice(0, 50)}...
          </p>
        </div>
        <div className="tiktok-preview-sidebar">
          <div className="action-column">
            <div className="action-icon">
              <FontAwesomeIcon
                style={{ fontSize: "16px" }}
                size="xs"
                icon={["fas", "heart"]}
              />
            </div>
            <span style={{ fontSize: "8px" }} className="action-count">
              123.4K
            </span>
          </div>
          <div className="action-column">
            <div className="action-icon">
              <FontAwesomeIcon
                style={{ fontSize: "16px" }}
                icon={["fas", "comment"]}
              />
            </div>
            <span style={{ fontSize: "8px" }} className="action-count">
              1,234
            </span>
          </div>
          <div className="action-column">
            <div className="action-icon">
              <FontAwesomeIcon
                style={{ fontSize: "16px" }}
                icon={["fas", "bookmark"]}
              />
            </div>
            <span style={{ fontSize: "8px" }} className="action-count">
              10.2K
            </span>
          </div>
          <div className="action-column">
            <div className="action-icon">
              <FontAwesomeIcon
                style={{ fontSize: "16px" }}
                icon={["fas", "share"]}
              />
            </div>
            <span style={{ fontSize: "8px" }} className="action-count">
              23,2k
            </span>
            <img
              src={avatarUrl}
              alt="avatar"
              className="rounded-circle my-2"
              style={{ width: "32px", height: "32px" }}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};
