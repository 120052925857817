import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import {
  GOOGLE_CALENDAR_ME_GET_LOADING,
  GOOGLE_CALENDAR_ME_SYNC_LOADING,
  OFFICE365_SYNC_LOADING,
} from "../../../../constants/loading.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { useGoogleCalendarCore } from "../../../../cores/googleCalendar";
import { useMeCore } from "../../../../cores/me";
import {
  SocialProviderId,
  SocialProviderName,
} from "../../../../cores/socialPost/config";
import { SocialProvider } from "../../../../cores/socialPost/interfaces/socialProvider";
import { daysRemainingFromToday } from "../../../utils/dateUtils";
import { GoogleButton } from "../../social/Google/GoogleButton";
import { GoogleCalendarIcon } from "../../social/GoogleCalendar/GoogleCalendarIcon";
import SocialRow from "./SocialRow";
import Office365BindCard from "../../social/Office365/Office365BindCard";
import Office365Button from "../../social/Office365/Office365Button";
import { useOffice365Core } from "../../../../cores/office365";
import { Office365Icon } from "../../social/Office365/Office365Icon";

export const OfficeCalendar365BindRow: React.FC<{
  onlySyncText?: boolean;
  removeShadow?: boolean;
}> = ({ onlySyncText, removeShadow }) => {
  const intl = useIntl();

  const { isLoading } = useGlobalCore();
  const { get, settings, unbind, login } = useOffice365Core();
  const { company, getMeSocialSettings, getMeUserSettings } = useMeCore();
  useEffect(() => {
    get(company!.id);
  }, [company, get, login]);

  const remainingBindDays = useMemo(
    () =>
      settings?.expiresIn ? daysRemainingFromToday(settings?.expiresIn) : null,
    [settings]
  );

  const canBind = useMemo(
    () => remainingBindDays && remainingBindDays <= 10,
    [remainingBindDays]
  );
  const isActive = useMemo((): boolean => !!settings?.email, [settings]);

  const handleBind = useCallback(async () => {
    const loginUrl = await login(company!.id);
    if (loginUrl) window.open(loginUrl, "_self");
  }, [company, login]);

  const handleUnbind = useCallback(() => {
    unbind(company!.id, false, () => {
      getMeSocialSettings(company?.id!);
    });
  }, [company, unbind, getMeSocialSettings]);
  const socialProvider = useMemo(
    (): SocialProvider => ({
      id: SocialProviderId.Office365,
      name: SocialProviderName.Office365,
      icon: <Office365Icon size="2x" active={isActive} />,
      displayName: settings?.name,
      avatar: settings?.avatarUrl,
      email: settings?.email,
    }),
    [settings, isActive]
  );

  return (
    <SocialRow
      removeShadow={removeShadow}
      social={socialProvider}
      bindLoading={isLoading(GOOGLE_CALENDAR_ME_SYNC_LOADING)}
      getLoading={isLoading(GOOGLE_CALENDAR_ME_GET_LOADING)}
      isSync={isActive}
    >
      <div className="w-100 px-1">
        {canBind ? (
          <div className="small text-danger mb-1 d-flex">
            <FontAwesomeIcon
              size={"2x"}
              icon={["fas", "exclamation-circle"]}
              className="mr-2"
            />
            {intl.formatMessage(
              { id: "app.home.expiration" },
              {
                remainingDays: remainingBindDays,
              }
            )}
          </div>
        ) : (
          <></>
        )}
        <Office365Button
          isActive={isActive}
          isLoading={isLoading(OFFICE365_SYNC_LOADING)}
          onBind={handleBind}
          onUnbind={handleUnbind}
        />
      </div>
    </SocialRow>
  );
};
