import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewNotificationAction,
  socketDisconnectedAction,
} from "./store/actions";
import {
  disconnectedSelector,
  notificationSelector,
  onlineClientsSelector,
} from "./store/selector";
import {
  leaveFromChannelAction,
  sendMessageToChannelAction,
  subscribeToChannelAction,
} from "./store/socket-actions";
import { CHAT_MESSAGES_SENDING } from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";

export function useSocketCore() {
  const dispatch = useDispatch();
  const { turnOffLoading } = useGlobalCore();
  const onlineClients = useSelector(onlineClientsSelector);
  const disconnected = useSelector(disconnectedSelector);
  const notification = useSelector(notificationSelector);

  const subscribeToChannel = useCallback(
    async (
      userId: string,
      conversationId: string | null,
      channel: string,
      companyId: string
    ) => {
      try {
        dispatch(
          subscribeToChannelAction({
            userId,
            conversationId,
            channel,
            companyId,
          })
        );
      } catch (error: any) {
        console.error(error);
      }
    },
    [dispatch]
  );
  const leaveFromChannel = useCallback(
    async (channel: string) => {
      try {
        dispatch(leaveFromChannelAction({ channel }));
      } catch (error: any) {
        console.error(error);
      }
    },
    [dispatch]
  );
  const sendMessageToChannel = useCallback(
    async (channel: string, message: any) => {
      try {
        dispatch(sendMessageToChannelAction({ channel, message }));
        turnOffLoading(CHAT_MESSAGES_SENDING);
      } catch (error: any) {
        turnOffLoading(CHAT_MESSAGES_SENDING);
        console.error(error);
      }
    },
    [dispatch, turnOffLoading]
  );

  return {
    onlineClients,
    notification,
    subscribeToChannel,
    sendMessageToChannel,
    leaveFromChannel,
    disconnected,
    setDisconnected: (disconnected: boolean) =>
      dispatch(socketDisconnectedAction({ disconnected })),
    addNewNotification: (id: string) =>
      dispatch(addNewNotificationAction({ id })),
  };
}
