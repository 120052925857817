import React, { ReactNode, useEffect } from "react";
import { useMeCore } from "../../cores/me";
import { Link } from "react-router-dom";
import { useRouterCore } from "../../cores/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContactCore } from "../../cores/contacts";
import { useIntl } from "react-intl";
import Avatar from "../component/Avatar";
import { getFirstLetters } from "../utils/stringUtils";

import {
  faFileAlt,
  faShareAlt,
  faClock,
  faRobot,
  faArrowRight,
  faUserFriends,
  faBuilding,
  faChartLine,
  faQuestionCircle,
  faExternalLinkAlt,
  faRocket,
  faFire,
  faBolt,
  faGem,
} from "@fortawesome/free-solid-svg-icons";
import {
  faWhatsapp,
  faFacebookF,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { UpgradePlanButton } from "../component/plan/UpgradePlanButton";

const Home = () => {
  const { routes } = useRouterCore();
  const { user, company, companyPlan } = useMeCore();
  const intl = useIntl();
  const {
    getNewContactsCount,
    newContactsCount,
    newCompaniesCount,
    getCompaniesCount,
  } = useContactCore();

  const getGreet = () => {
    const date = new Date();
    const hour = date.getHours();
    if (hour >= 0 && hour <= 12) return `¡Buen día, ${user?.firstName}!`;
    if (hour >= 13 && hour <= 18) return `¡Buenas tardes, ${user?.firstName}!`;
    return `¡Buenas noches, ${user?.firstName}!`;
  };

  useEffect(() => {
    getCompaniesCount(company?.id!);
    getNewContactsCount(company?.id!);
  }, [company?.id, getCompaniesCount, getNewContactsCount]);

  const quickActions = [
    {
      path: "/binding",
      icon: "circle-nodes",
      label: "Gestionar Integraciones",
      color: "primary",
      bgImage: "linear-gradient(135deg, #FF6B6B 0%, #FF2D2D 100%)",
    },
    {
      path: "/boards/business",
      icon: "tasks",
      label: "app.home.createTask",
      color: "primary",
      bgImage: "linear-gradient(135deg, #4A90E2 0%, #357ABD 100%)",
    },
    {
      path: "/calendar",
      icon: "calendar",
      label: "app.home.bookMeet",
      color: "success",
      bgImage: "linear-gradient(135deg, #2ECC71 0%, #27AE60 100%)",
    },
    {
      path: "/boards/business",
      icon: "people-robbery",
      label: "app.home.createOpportunity",
      color: "info",
      bgImage: "linear-gradient(135deg, #9B59B6 0%, #8E44AD 100%)",
    },
    {
      path: "/automations",
      icon: "robot",
      label: "app.home.automateProcess",
      color: "warning",
      bgImage: "linear-gradient(135deg, #F1C40F 0%, #F39C12 100%)",
    },
    {
      path: "/contacts",
      icon: "address-book",
      label: "app.home.createContacts",
      color: "danger",
      bgImage: "linear-gradient(135deg, #E74C3C 0%, #C0392B 100%)",
    },
    {
      path: "/companies/contact",
      icon: "city",
      label: "app.home.createEnterprise",
      color: "secondary",
      bgImage: "linear-gradient(135deg, #34495E 0%, #2C3E50 100%)",
    },
  ];

  return (
    <div className="home-container">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <div className="user-welcome">
            <Avatar
              image={user?.avatarUrl}
              height="80"
              width="80"
              label={getFirstLetters(`${user?.firstName} ${user?.lastName}`)}
              className="hero-avatar"
            />
            <div className="welcome-text">
              <h1 className="display-5 fw-bold ">{getGreet()}</h1>
              <p className="lead mb-0 text-muted">
                <FontAwesomeIcon icon={faRocket} className="me-2" />
                Comienza tu día con energía
              </p>
            </div>
          </div>
        </div>
      </section>

      <main className="dashboard-content">
        {/* Quick Stats */}
        <section className="stats-grid ">
          <div className="stat-card gradient-primary">
            <div className="stat-icon">
              <FontAwesomeIcon icon={faUserFriends} />
            </div>
            <div className="stat-info">
              <h3>+{newContactsCount}</h3>
              <p>Nuevos Contactos</p>
            </div>
            <div className="stat-trend up">
              <FontAwesomeIcon icon={faChartLine} />
            </div>
          </div>
          <div className="stat-card gradient-success">
            <div className="stat-icon">
              <FontAwesomeIcon icon={faBuilding} />
            </div>
            <div className="stat-info">
              <h3>+{newCompaniesCount}</h3>
              <p>Nuevas Empresas</p>
            </div>
            <div className="stat-trend up">
              <FontAwesomeIcon icon={faChartLine} />
            </div>
          </div>
        </section>
        {/* Quick Actions Grid */}
        <section className="actions-section ">
          <h2 className="section-title">
            <FontAwesomeIcon icon={faBolt} className="me-2" />
            Acciones Rápidas
          </h2>
          <div className="actions-grid ">
            {quickActions.map((action, idx) => (
              <Link
                key={idx}
                to={action.path}
                className="action-card"
                style={{ background: action.bgImage }}
              >
                <div className="action-content">
                  <FontAwesomeIcon
                    icon={["fas", action.icon as any]}
                    className="action-icon"
                  />
                  <h3>{intl.formatMessage({ id: action.label })}</h3>
                </div>
                <div className="action-overlay" />
              </Link>
            ))}
            <a
              href="https://support.wembii.com"
              target="_blank"
              className="action-card"
              style={{
                background:
                  "linear-gradient(135deg,rgb(26, 121, 215) 0%, rgb(26, 121, 215) 100%)",
              }}
            >
              <div className="action-content">
                <FontAwesomeIcon
                  icon={["fas", "headset"]}
                  className="action-icon"
                />
                <h3>Centro de ayuda</h3>
              </div>
              <div className="action-overlay" />
            </a>
          </div>
        </section>

        {/* Plan Features */}
        <section className="plan-section">
          <div className="plan-header">
            <h2 className="section-title">
              <FontAwesomeIcon icon={faGem} className="me-2" />
              Tu Plan
            </h2>
            <div className="plan-badge">
              {companyPlan?.name.split(".")[2]}
              <FontAwesomeIcon icon={faFire} className="ms-2" />
            </div>
          </div>
          <div className="features-grid">
            <PlanFeature
              icon={faUserFriends}
              label="Equipo"
              value={`${companyPlan?.rules.teamSize} miembros`}
              gradient="linear-gradient(135deg,rgb(139, 137, 137) 0%, rgb(124, 122, 122) 100%)"
            />
            <PlanFeature
              icon={faFileAlt}
              label="Formularios"
              value={companyPlan?.rules.webFormsAmount}
              gradient="linear-gradient(135deg,rgb(139, 137, 137) 0%, rgb(124, 122, 122) 100%)"
            />
            <PlanFeature
              icon={faChartLine}
              label="Informes"
              value={companyPlan?.rules.reportsAmount}
              gradient="linear-gradient(135deg,rgb(139, 137, 137) 0%, rgb(124, 122, 122) 100%)"
            />
            <PlanFeature
              icon={faClock}
              label="Publicaciones"
              value={
                companyPlan?.rules.allowScheduledPost ? "Activo" : "Inactivo"
              }
              gradient="linear-gradient(135deg,rgb(139, 137, 137) 0%, rgb(124, 122, 122) 100%)"
            />
            <PlanFeature
              icon={faRobot}
              label="Automatización"
              value={companyPlan?.rules.allowAutomation ? "Activo" : "Inactivo"}
              gradient="linear-gradient(135deg,rgb(139, 137, 137) 0%, rgb(124, 122, 122) 100%)"
            />
          </div>
          <div className="plan-actions">
            <UpgradePlanButton />
            <a
              href="https://wembii.com/planes"
              target="_blank"
              rel="noopener noreferrer"
              className="btn-outline-glow"
            >
              Comparar Planes
              <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-2" />
            </a>
          </div>
        </section>
        {/* Social Integration */}
        <section className="social-section">
          <div className="social-content">
            <h2 className="section-title text-center mb-4">
              <FontAwesomeIcon icon={faShareAlt} className="me-2" />
              Conecta tus Redes
            </h2>
            <div className="social-grid">
              <a href="/binding" className="social-item whatsapp">
                <FontAwesomeIcon icon={faWhatsapp} />
              </a>
              <a href="/binding" className="social-item facebook">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a href="/binding" className="social-item instagram">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a href="/binding" className="social-item linkedin">
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
            </div>
            <Link to={routes.binding} className="btn-glow mt-4">
              Gestionar Integraciones
              <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
            </Link>
          </div>
        </section>
      </main>

      <footer className="app-footer">
        <div className="container">
          <div className="footer-content">
            <p className="copyright">
              2025 © Wembii SpA™ - Revolucionando la gestión empresarial
            </p>
            <a
              href="https://support.wembii.com"
              target="_blank"
              rel="noopener noreferrer"
              className="help-link"
            >
              <FontAwesomeIcon icon={faQuestionCircle} className="me-2" />
              Centro de Ayuda
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

const PlanFeature: React.FC<{
  icon: any;
  label: string;
  value: ReactNode;
  gradient: string;
}> = ({ icon, label, value, gradient }) => (
  <div className="feature-card" style={{ background: gradient }}>
    <FontAwesomeIcon icon={icon} className="feature-icon" />
    <div className="feature-content">
      <h4>{label}</h4>
      <p>{value}</p>
    </div>
  </div>
);

export default Home;
