import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode, useRef, useState } from "react";
import { Button, Input } from "reactstrap";
import { CustomField } from "../../../cores/boards/interfaces/api/get-custom-fields";

const FieldRestrictionItem: React.FC<{
  field: CustomField;
  data: any;
  setData: any;
}> = ({ field, data, setData }) => {
  const inputRef = useRef<any>(null);
  const [conditions, setConditions] = useState<{
    [key: string]: string;
  }>({});

  const compareOptions = [
    { label: "Seleccione", value: "" },
    { label: "Mayor que (>)", value: "ht" },
    { label: "Menor que (<)", value: "lt" },
    { label: "Igual a (=)", value: "eq" },
  ];
  const renderOptions = (type: string) => {
    if (type === "date" || type === "number")
      return compareOptions.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ));
  };
  const renderConditionLabel = (type: string) => {
    if (type === "date" || type === "number") return "sea";
    if (type === "text") return "contenga";
  };
  return (
    <div className="border   my-2" style={{ borderRadius: "1rem" }}>
      <div className="d-flex    my-auto justify-content-between">
        <h6 className="w-100 mx-2 my-auto pb-3 pt-3 px-3 ">
          <b>{field.name}</b>
        </h6>
        <div className="d-flex   my-auto  position-relative">
          <Input
            checked={(data[field.id] ?? { required: false }).required}
            onChange={({ target }) => {
              setData({
                ...data,
                [field.id]: {
                  ...data[field.id],
                  required: target.checked,
                },
              });
            }}
            type="checkbox"
            className="d-flex position-relative mt-1 "
          />
        </div>
        <p className=" mb-2 flex my-auto position-relative mx-2"> Requerido*</p>
      </div>
      <div
        key={field.id}
        className="d-flex justify-content-between   px-2 mb-2 "
      >
        <p className="w-100 mx-22 my-auto m-3 text-capitalize ">
          {renderConditionLabel(field.type)}
        </p>
        <FontAwesomeIcon
          icon={["fas", "arrow-right"]}
          className="mx-auto  my-auto text-primary"
          size="1x"
        />
        {field.type === "date" || field.type === "number" ? (
          <select
            defaultValue=""
            onChange={({ target }) => {
              const fieldId = field.id;
              const newData = { ...data };
              if (!target.value.length) {
                if (!newData[fieldId].required) {
                  delete newData[fieldId];
                } else {
                  newData[fieldId].value = "";
                  newData[fieldId].condition = "";
                }
                setData(newData);
              }

              setConditions({
                ...conditions,
                [fieldId]: target.value,
              });
            }}
            className="form-control mx-4 my-auto rounded-pill"
          >
            {renderOptions(field.type)}
          </select>
        ) : (
          <Input
            className="mx-4 rounded-pill my-auto"
            type={field.type as any}
            value={(data[field.id] ?? { value: "" }).value}
            onChange={({ target }) =>
              setData({
                ...data,
                [field.id]: {
                  condition: conditions[field.id] ?? "contains",
                  value: target.value,
                },
              })
            }
            placeholder="Condición"
          />
        )}
        {(field.type === "date" || field.type === "number") && (
          <>
            <FontAwesomeIcon
              icon={["fas", "arrow-right"]}
              className="mx-auto my-auto text-primary"
              size="1x"
            />
            <Input
              ref={inputRef}
              className="mx-4 rounded-pill my-auto"
              type={field.type as any}
              disabled={conditions[field.id] ? false : true}
              value={(data[field.id] ?? { value: "" }).value}
              onChange={({ target }) =>
                setData({
                  ...data,
                  [field.id]: {
                    ...data[field.id],
                    condition: conditions[field.id] ?? "text",
                    value: target.value,
                  },
                })
              }
              placeholder="Condición"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default FieldRestrictionItem;
