import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const globalsRoot = (state: State) => state.globals;

export const loadingsSelector = createSelector(
  globalsRoot,
  ({ loadings }) => loadings
);
export const activeGroupsSelector = createSelector(
  globalsRoot,
  ({ activeGroups }) => activeGroups
);
export const isOnlineSelector = createSelector(
  globalsRoot,
  ({ isOnline }) => isOnline
);

export const modalsSelector = createSelector(
  globalsRoot,
  ({ modals }) => modals.ids.map((id) => modals.entities[id])
);

export const modalEntitiesSelector = createSelector(
  globalsRoot,
  ({ modals }) => modals.entities
);

export const internalModalsSelector = createSelector(
  globalsRoot,
  ({ internalModals }) => internalModals.ids.map((id) => internalModals.entities[id])
);

export const internalModalEntitiessSelector = createSelector(
  globalsRoot,
  ({ internalModals }) => internalModals.entities
);

export const globalLoadingSelector = createSelector(
  globalsRoot,
  (state) => state.globalLoading
);

export const isSideBarOpenSelector = createSelector(
  globalsRoot,
  (state) => state.inSideBarOpen
);

export const isSideBarCollapsedSelector = createSelector(
  globalsRoot,
  (state) => state.isSideBarCollapsed
);
