import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BOARDS_LOADING,
  BOARD_CARD_LOADING,
  BOARD_CARD_MOVE_LOADING,
  BOARD_CARD_UPDATE_LOADING,
  BOARD_EXPORT_LOADING,
  BOARD_IMPORT_LOADING,
  BOARD_LOADING,
  MOVE_CARDS_LOADING,
  UPLOADING_BOARD_FILES,
} from "../../constants/loading.constant";
import { toast } from "react-toastify";
import { useGlobalCore } from "../globals";
import { PanelFile } from "./interfaces/PanelFile";
import { boardApiService } from "./services/api";
import { useIntl } from "react-intl";
import {
  addListRestrictionAction,
  changeCardOrderAction,
  changeListOrderAction,
  cleanCardAction,
  clearFilterAction,
  removeListRestrictionAction,
  setBoardUpdateAction,
  setFilterAction,
  setQuoteAction,
  setShowTagTextAction,
} from "./store/actions";
import {
  deleteAssignmentFailureAction,
  deleteAssignmentInitAction,
  deleteAssignmentSuccessAction,
  deleteBoardFailureAction,
  deleteBoardInitAction,
  deleteBoardSuccessAction,
  deleteCardFailureAction,
  deleteCardInitAction,
  deleteCardSuccessAction,
  deleteCardTagFailureAction,
  deleteCardTagInitAction,
  deleteCardTagSuccessAction,
  deleteCommentFailureAction,
  deleteCommentInitAction,
  deleteCommentSuccessAction,
  deleteCompanyFailureAction,
  deleteCompanyInitAction,
  deleteCompanySuccessAction,
  deleteContactFailureAction,
  deleteContactInitAction,
  deleteContactSuccessAction,
  deleteCustomFieldFailureAction,
  deleteCustomFieldInitAction,
  deleteCustomFieldSuccessAction,
  deleteFileFailureAction,
  deleteFileInitAction,
  deleteFileSuccessAction,
  deleteListFailureAction,
  deleteListInitAction,
  deleteListSuccessAction,
  deleteProductFailureAction,
  deleteProductInitAction,
  deleteProductSuccessAction,
  deleteTagFailureAction,
  deleteTagInitAction,
  deleteTagSuccessAction,
  getBoardActivityInitAction,
  getBoardBusinessInitAction,
  getBoardExportFailureAction,
  getBoardExportInitAction,
  getBoardExportSuccessAction,
  getBoardFailureAction,
  getBoardsFailureAction,
  getBoardsInitAction,
  getBoardsSuccessAction,
  getBoardSuccessAction,
  getCardFailureAction,
  getCardInitAction,
  getCardSuccessAction,
  getCardTagsFailureAction,
  getCardTagsInitAction,
  getCardTagsSuccessAction,
  getCustomFieldsFailureAction,
  getCustomFieldsInitAction,
  getCustomFieldsSuccessAction,
  getListBudgetFailureAction,
  getListBudgetInitAction,
  getListBudgetSuccessAction,
  getListCardsFailureAction,
  getListCardsInitAction,
  getListCardsSuccessAction,
  getListContactsFailureAction,
  getListContactsInitAction,
  getListContactsSuccessAction,
  getListRestrictionsInitAction,
  getListRestrictionsSuccessAction,
  getListsFailureAction,
  getListsInitAction,
  getListsSuccessAction,
  postAssignmentFailureAction,
  postAssignmentInitAction,
  postAssignmentSuccessAction,
  postBoardFailureAction,
  postBoardImportFailureAction,
  postBoardImportInitAction,
  postBoardImportSuccessAction,
  postBoardInitAction,
  postBoardSuccessAction,
  postCardFailureAction,
  postCardInitAction,
  postCardSuccessAction,
  postCardTagFailureAction,
  postCardTagInitAction,
  postCardTagSuccessAction,
  postCommentFailureAction,
  postCommentInitAction,
  postCommentSuccessAction,
  postCompanyFailureAction,
  postCompanyInitAction,
  postCompanySuccessAction,
  postContactFailureAction,
  postContactInitAction,
  postContactSuccessAction,
  postCustomFieldFailureAction,
  postCustomFieldInitAction,
  postCustomFieldSuccessAction,
  postFilesFailureAction,
  postFilesInitAction,
  postFilesSuccessAction,
  postListFailureAction,
  postListInitAction,
  postListRestrictionsInitAction,
  postListSuccessAction,
  postProductFailureAction,
  postProductInitAction,
  postProductSuccessAction,
  postQuoteFailureAction,
  postQuoteInitAction,
  postQuoteSuccessAction,
  postTagFailureAction,
  postTaggingFailureAction,
  postTaggingInitAction,
  postTaggingSuccessAction,
  postTagInitAction,
  postTagSuccessAction,
  putBoardFailureAction,
  putBoardInitAction,
  putBoardSuccessAction,
  putCardFailureAction,
  putCardInitAction,
  putCardOrderFailureAction,
  putCardOrderInitAction,
  putCardOrderSuccessAction,
  putCardSuccessAction,
  putCardTagFailureAction,
  putCardTagInitAction,
  putCardTagSuccessAction,
  putCommentFailureAction,
  putCommentInitAction,
  putCommentSuccessAction,
  putCustomFieldFailureAction,
  putCustomFieldInitAction,
  putCustomFieldSuccessAction,
  putListFailureAction,
  putListInitAction,
  putListOrderFailureAction,
  putListOrderInitAction,
  putListOrderSuccessAction,
  putListSuccessAction,
  putMoveCardsFailureAction,
  putMoveCardsInitAction,
  putMoveCardsSuccessAction,
  putProductFailureAction,
  putProductInitAction,
  putProductSuccessAction,
} from "./store/api-actions";
import {
  cardSelector,
  commentsSelector,
  listsSelector,
  boardsSelector,
  filesSelector,
  tasksSelector,
  filtersSelector,
  emailsSelector,
  listNamesSelector,
  errorCodeSelector,
  errorMessageSelector,
  tagsSelector,
  allTagsSelector,
  showTagTextSelector,
  boardActivitySelector,
  boardBusinessSelector,
  eventsSelector,
  activitiesSelector,
  quoteSelector,
  quotesSelector,
  boardSelector,
  customFieldsSelector,
  restrictionsSelector,
} from "./store/selector";
import { Filters, PanelFilters } from "./interfaces/PanelFilters";
import { PutListBody } from "./interfaces/api/put-list";
import { Errors } from "../../constants/errors";
import { PutCardRequest } from "./interfaces/api/put-card";
import { PutProductRequest } from "./interfaces/api/put-product";
import { PostProductRequest } from "./interfaces/api/post-product";
import { Quote } from "./interfaces/Quote";
import { PostQuoteRequest } from "./interfaces/api/post-quote";
import { Board } from "./interfaces/Board";
import { ListRestriction, UserRoles } from "./interfaces/api/get-board";
import { PostCustomFieldRequest } from "./interfaces/api/post-custom-field";
import { PutCustomFieldRequest } from "./interfaces/api/put-custom-field";
import { PutMoveCardsRequest } from "./interfaces/api/put-move-cards";
import { CardFields } from "./constants/card-fields.constant";

export function useBoardCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const boards = useSelector(boardsSelector);
  const boardActivity = useSelector(boardActivitySelector);
  const boardBusiness = useSelector(boardBusinessSelector);
  const standardFields = CardFields;
  const board = useSelector(boardSelector);
  const lists = useSelector(listsSelector);
  const comments = useSelector(commentsSelector);
  const files = useSelector(filesSelector);
  const emails = useSelector(emailsSelector);
  const tasks = useSelector(tasksSelector);
  const events = useSelector(eventsSelector);
  const quotes = useSelector(quotesSelector);
  const activities = useSelector(activitiesSelector);
  const tags = useSelector(tagsSelector);
  const allTags = useSelector(allTagsSelector);
  const card = useSelector(cardSelector);
  const filters = useSelector(filtersSelector);
  const listNames = useSelector(listNamesSelector);
  const showTagText = useSelector(showTagTextSelector);
  const quote = useSelector(quoteSelector);
  const customFields = useSelector(customFieldsSelector);
  const errorCode = useSelector(errorCodeSelector);
  const errorMessage = useSelector(errorMessageSelector);
  const restrictions = useSelector(restrictionsSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();
  const getActivityBoards = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getBoardsInitAction());
        turnOnLoading(BOARDS_LOADING);
        const { boards } = await boardApiService.getActivityBoards(companyId);
        dispatch(getBoardsSuccessAction({ boards }));
        turnOffLoading(BOARDS_LOADING, 500);
      } catch (error: any) {
        console.error(error);
        turnOffLoading(BOARDS_LOADING);
        dispatch(getBoardsFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );
  const getActivityBoard = useCallback(
    async (
      companyId: string,
      boardId: string,
      filters: Filters,
      showLoading = true,
      onSuccess?: () => void
    ) => {
      try {
        showLoading && dispatch(getBoardActivityInitAction());
        showLoading && turnOnLoading(BOARD_LOADING);
        const response = await boardApiService.getActivityBoard(
          companyId,
          boardId,
          filters
        );
        dispatch(getBoardSuccessAction(response));
        showLoading && turnOffLoading(BOARD_LOADING, 500);
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        showLoading && turnOffLoading(BOARD_LOADING);
        dispatch(getBoardFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );
  const getBusinessBoards = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getBoardsInitAction());
        turnOnLoading(BOARDS_LOADING);
        const { boards } = await boardApiService.getBusinessBoards(companyId);
        dispatch(getBoardsSuccessAction({ boards }));
        turnOffLoading(BOARDS_LOADING, 500);
      } catch (error: any) {
        console.error(error);
        turnOffLoading(BOARDS_LOADING);
        dispatch(getBoardsFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );
  const getBusinessBoard = useCallback(
    async (
      companyId: string,
      boardId: string,
      filters: Filters,
      showLoading = true,
      onSuccess?: () => void
    ) => {
      try {
        showLoading && dispatch(getBoardBusinessInitAction());
        showLoading && turnOnLoading(BOARD_LOADING);
        const response = await boardApiService.getBusinessBoard(
          companyId,
          boardId,
          filters
        );
        dispatch(getBoardSuccessAction(response));
        showLoading && turnOffLoading(BOARD_LOADING, 500);
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        showLoading && turnOffLoading(BOARD_LOADING);
        dispatch(getBoardFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );
  const getBoardExport = useCallback(
    async (companyId: string, boardId: string, filters: Filters) => {
      try {
        dispatch(getBoardExportInitAction());
        turnOnLoading(BOARD_EXPORT_LOADING);
        const response = await boardApiService.getBoardExport(
          companyId,
          boardId,
          filters
        );
        dispatch(getBoardExportSuccessAction());
        turnOffLoading(BOARD_EXPORT_LOADING, 500);
        return response;
      } catch (error: any) {
        console.error(error);
        turnOffLoading(BOARD_EXPORT_LOADING);
        dispatch(getBoardExportFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );
  const getCard = useCallback(
    async (
      companyId: string,
      boardId: string,
      listId: string,
      cardId: string,
      showLoading = false,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(getCardInitAction());
        showLoading && turnOnLoading(BOARD_CARD_LOADING);
        const response = await boardApiService.getCard(
          companyId,
          cardId,
          boardId,
          listId
        );
        dispatch(getCardSuccessAction(response));
        showLoading && turnOffLoading(BOARD_CARD_LOADING);
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        showLoading && turnOffLoading(BOARD_CARD_LOADING);
        dispatch(getCardFailureAction({ error, errorCode: Errors.CardLoad }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );
  const postBoard = useCallback(
    async (
      companyId: string,
      name: string,
      color: string,
      currency: string,
      type: string,
      userRoles: UserRoles[],
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postBoardInitAction());
        const response = await boardApiService.postBoard(
          companyId,
          name,
          color,
          currency,
          type,
          userRoles
        );
        dispatch(postBoardSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postBoardFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const putBoard = useCallback(
    async (
      companyId: string,
      boardId: string,
      name: string,
      color: string,
      currency: string,
      userRoles: UserRoles[],
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putBoardInitAction());
        const response = await boardApiService.putBoard(
          companyId,
          boardId,
          name,
          color,
          currency,
          userRoles
        );
        dispatch(putBoardSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(putBoardFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const deleteBoard = useCallback(
    async (companyId: string, boardId: string, onSuccess?: () => void) => {
      try {
        dispatch(deleteBoardInitAction());
        const response = await boardApiService.deleteBoard(companyId, boardId);
        dispatch(deleteBoardSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteBoardFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const getLists = useCallback(
    async (companyId: string, boardId: string) => {
      try {
        dispatch(getListsInitAction());
        const { lists } = await boardApiService.getLists(companyId, boardId);
        dispatch(getListsSuccessAction({ lists }));
        return lists;
      } catch (error: any) {
        console.error(error);
        dispatch(getListsFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postList = useCallback(
    async (companyId: string, name: string, boardId: string) => {
      try {
        dispatch(postListInitAction());
        const response = await boardApiService.postList(
          companyId,
          name,
          boardId
        );
        dispatch(postListSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(postListFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const putList = useCallback(
    async (
      companyId: string,
      boardId: string,
      listId: string,
      body: PutListBody,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putListInitAction());
        const response = await boardApiService.putList(
          companyId,
          boardId,
          listId,
          body
        );
        dispatch(putListSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(putListFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const deleteList = useCallback(
    async (
      companyId: string,
      boardId: string,
      listId: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(deleteListInitAction());
        const response = await boardApiService.deleteList(
          companyId,
          boardId,
          listId
        );
        dispatch(deleteListSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteListFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const getListCards = useCallback(
    async (
      companyId: string,
      boardId: string,
      listId: string,
      filters: Filters,
      offset: number,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(getListCardsInitAction());
        const { cards } = await boardApiService.getListCards(
          companyId,
          boardId,
          listId,
          filters,
          offset
        );
        dispatch(getListCardsSuccessAction({ cards, list: listId }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(getListCardsFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const getListContacts = useCallback(
    async (
      companyId: string,
      boardId: string,
      listId: string,
      filters: Filters
    ) => {
      try {
        dispatch(getListContactsInitAction());
        const { contacts } = await boardApiService.getListContacts(
          companyId,
          boardId,
          listId,
          filters
        );
        dispatch(getListContactsSuccessAction({ contacts }));
        return contacts;
      } catch (error: any) {
        console.error(error);
        dispatch(getListContactsFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postCard = useCallback(
    async (
      companyId: string,
      listId: string,
      title: string,
      boardId: string,
      contacts: string[],
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postCardInitAction());
        const response = await boardApiService.postCard(
          companyId,
          title,
          boardId,
          listId,
          contacts
        );
        dispatch(postCardSuccessAction(response));
        onSuccess?.();
        return response.card;
      } catch (error: any) {
        console.error(error);
        dispatch(postCardFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const putCard = useCallback(
    async (
      companyId: string,
      boardId: string,
      listId: string,
      cardId: string,
      body: PutCardRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putCardInitAction());
        turnOnLoading(BOARD_CARD_UPDATE_LOADING);
        const response = await boardApiService.putCard(
          companyId,
          boardId,
          listId,
          cardId,
          body
        );
        dispatch(putCardSuccessAction(response));
        turnOffLoading(BOARD_CARD_UPDATE_LOADING, 200);
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(BOARD_CARD_UPDATE_LOADING);
        if (error.request.status === 403) {
          const code: number = JSON.parse(error.request.response).code || 0;
          const message: string = JSON.parse(error.request.response).message;
          switch (code) {
            case Errors.DuplicatedData:
              dispatch(
                putCardFailureAction({
                  errorCode: Errors.DuplicatedData,
                  errorMessage: message,
                })
              );
              break;
            default:
              toast.error(intl.formatMessage({ id: "app.toast.error74" }));
              console.error(error, code);
          }
        } else {
          toast.error(intl.formatMessage({ id: "app.toast.error74" }));
          console.error(error);
        }
      }
    },
    [dispatch, turnOnLoading, turnOffLoading, intl]
  );
  const deleteCard = useCallback(
    async (
      companyId: string,
      boardId: string,
      listId: string,
      cardId: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(deleteCardInitAction());
        const response = await boardApiService.deleteCard(
          companyId,
          boardId,
          listId,
          cardId
        );
        dispatch(deleteCardSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteCardFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postComment = useCallback(
    async (
      companyId: string,
      boardId: string,
      listId: string,
      cardId: string,
      text: string,
      type: number,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postCommentInitAction());
        const response = await boardApiService.postComment(
          companyId,
          boardId,
          listId,
          cardId,
          text,
          type
        );
        dispatch(postCommentSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postCommentFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postTagging = useCallback(
    async (
      companyId: string,
      boardId: string,
      listId: string,
      cardId: string,
      users: string[],
      text: string
    ) => {
      try {
        dispatch(postTaggingInitAction());
        await boardApiService.postTagging(
          companyId,
          boardId,
          listId,
          cardId,
          users,
          text
        );
        dispatch(postTaggingSuccessAction());
      } catch (error: any) {
        console.error(error);
        dispatch(postTaggingFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postAssignment = useCallback(
    async (
      companyId: string,
      boardId: string,
      listId: string,
      cardId: string,
      user: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postAssignmentInitAction());
        const response = await boardApiService.postAssignment(
          companyId,
          boardId,
          listId,
          cardId,
          user
        );
        dispatch(postAssignmentSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postAssignmentFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const deleteAssignment = useCallback(
    async (
      companyId: string,
      boardId: string,
      listId: string,
      cardId: string,
      userId: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(deleteAssignmentInitAction());
        const response = await boardApiService.deleteAssignment(
          companyId,
          boardId,
          listId,
          cardId,
          userId
        );
        dispatch(deleteAssignmentSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteAssignmentFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postContact = useCallback(
    async (
      companyId: string,
      boardId: string,
      listId: string,
      cardId: string,
      contact: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postContactInitAction());
        const response = await boardApiService.postContact(
          companyId,
          boardId,
          listId,
          cardId,
          contact
        );
        dispatch(postContactSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postContactFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const deleteContact = useCallback(
    async (
      companyId: string,
      boardId: string,
      listId: string,
      cardId: string,
      contactId: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(deleteContactInitAction());
        const response = await boardApiService.deleteContact(
          companyId,
          boardId,
          listId,
          cardId,
          contactId
        );
        dispatch(deleteContactSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteContactFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postCompany = useCallback(
    async (
      companyId: string,
      boardId: string,
      listId: string,
      cardId: string,
      company: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postCompanyInitAction());
        const response = await boardApiService.postCompany(
          companyId,
          boardId,
          listId,
          cardId,
          company
        );
        dispatch(postCompanySuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postCompanyFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const deleteCompany = useCallback(
    async (
      companyId: string,
      boardId: string,
      listId: string,
      cardId: string,
      company: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(deleteCompanyInitAction());
        const response = await boardApiService.deleteCompany(
          companyId,
          boardId,
          listId,
          cardId,
          company
        );
        dispatch(deleteCompanySuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteCompanyFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const putComment = useCallback(
    async (
      companyId: string,
      boardId: string,
      listId: string,
      cardId: string,
      commentId: string,
      text: string,
      type: number,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putCommentInitAction());
        const response = await boardApiService.putComment(
          companyId,
          boardId,
          listId,
          cardId,
          commentId,
          text,
          type
        );
        dispatch(putCommentSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(putCommentFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const deleteComment = useCallback(
    async (
      companyId: string,
      boardId: string,
      listId: string,
      cardId: string,
      commentId: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(deleteCommentInitAction());
        const response = await boardApiService.deleteComment(
          companyId,
          boardId,
          listId,
          cardId,
          commentId
        );
        dispatch(deleteCommentSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteCommentFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const putListOrder = useCallback(
    async (
      companyId: string,
      boardId: string,
      listId: string,
      index: number,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putListOrderInitAction());
        const response = await boardApiService.putListOrder(
          companyId,
          boardId,
          listId,
          index
        );
        dispatch(putListOrderSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(putListOrderFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const putCardOrder = useCallback(
    async (
      companyId: string,
      boardId: string,
      listId: string,
      cardId: string,
      listDestinationId: string,
      index: number,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putCardOrderInitAction());
        turnOnLoading(BOARD_CARD_MOVE_LOADING);
        const response = await boardApiService.putCardOrder(
          companyId,
          boardId,
          listId,
          cardId,
          listDestinationId,
          index
        );
        dispatch(putCardOrderSuccessAction(response));
        turnOffLoading(BOARD_CARD_MOVE_LOADING, 200);
        onSuccess?.();
      } catch (error: any) {
        if (
          error.response.status === 400 &&
          error.response.data.message.message ===
            "Card Cannot be reordered because it doesnt comply with the listing policy"
        ) {
          const joinedFields = error.response.data.message.fields
            .map(({ field, condition, value }: ListRestriction) => {
              return `"${
                (field as any as string).includes("standard")
                  ? standardFields[(field as any as string).split("-")[1]].name
                  : field
              }" ${condition} ${value ? `${value}` : ""} `;
            })
            .join(",");
          toast.error(
            `La tarjeta de ${error.response.data.message.currentList} no puede moverse a ${error.response.data.message.listName} hasta que  cumpla con las condiciones: ${joinedFields} `
          );
        }
        turnOffLoading(BOARD_CARD_MOVE_LOADING);
        dispatch(putCardOrderFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );
  const postFiles = useCallback(
    async (
      companyId: string,
      boardId: string,
      listId: string,
      cardId: string,
      files: PanelFile[],
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postFilesInitAction());
        turnOnLoading(UPLOADING_BOARD_FILES);
        const response = await boardApiService.postFiles(
          companyId,
          boardId,
          listId,
          cardId,
          files
        );
        dispatch(postFilesSuccessAction(response));
        turnOffLoading(UPLOADING_BOARD_FILES, 500);
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(UPLOADING_BOARD_FILES);
        console.error(error);
        toast.error(intl.formatMessage({ id: "app.toast.error8" }));
        dispatch(postFilesFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading, intl]
  );
  const deleteFile = useCallback(
    async (
      companyId: string,
      boardId: string,
      listId: string,
      cardId: string,
      fileId: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(deleteFileInitAction());
        const response = await boardApiService.deleteFile(
          companyId,
          boardId,
          listId,
          cardId,
          fileId
        );
        dispatch(deleteFileSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteFileFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const getCardTags = useCallback(
    async (companyId: string, boardId: string) => {
      try {
        dispatch(getCardTagsInitAction());
        const response = await boardApiService.getCardTags(companyId, boardId);
        dispatch(getCardTagsSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(getCardTagsFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const getListBudgets = useCallback(
    async (
      companyId: string,
      boardId: string,
      listId: string,
      filters: Filters
    ) => {
      try {
        dispatch(getListBudgetInitAction());
        const response = await boardApiService.getListBudgets(
          companyId,
          boardId,
          listId,
          filters
        );
        dispatch(getListBudgetSuccessAction({ ...response, id: listId }));
      } catch (error: any) {
        console.error(error);
        dispatch(getListBudgetFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postCardTag = useCallback(
    async (
      companyId: string,
      boardId: string,
      name: string,
      textColor: string,
      backgroundColor: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postCardTagInitAction());
        const response = await boardApiService.postCardTag(
          companyId,
          boardId,
          name,
          textColor,
          backgroundColor
        );
        dispatch(postCardTagSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postCardTagFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const putCardTag = useCallback(
    async (
      companyId: string,
      boardId: string,
      tagId: string,
      name: string,
      textColor: string,
      backgroundColor: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putCardTagInitAction());
        const response = await boardApiService.putCardTag(
          companyId,
          boardId,
          tagId,
          name,
          textColor,
          backgroundColor
        );
        dispatch(putCardTagSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(putCardTagFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const deleteCardTag = useCallback(
    async (
      companyId: string,
      boardId: string,
      cardTagId: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(deleteCardTagInitAction());
        const response = await boardApiService.deleteCardTag(
          companyId,
          boardId,
          cardTagId
        );
        dispatch(deleteCardTagSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteCardTagFailureAction({ error }));
        if (error.request.status === 403) {
          toast.error(intl.formatMessage({ id: "app.toast.error75" }));
        }
      }
    },
    [dispatch, intl]
  );
  const postTag = useCallback(
    async (
      companyId: string,
      boardId: string,
      cardId: string,
      tagId: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postTagInitAction());
        const response = await boardApiService.postTag(
          companyId,
          boardId,
          cardId,
          tagId
        );
        dispatch(postTagSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postTagFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const deleteTag = useCallback(
    async (
      companyId: string,
      boardId: string,
      cardId: string,
      tagId: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(deleteTagInitAction());
        const response = await boardApiService.deleteTag(
          companyId,
          boardId,
          cardId,
          tagId
        );
        dispatch(deleteTagSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteTagFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postProduct = useCallback(
    async (
      companyId: string,
      boardId: string,
      listId: string,
      cardId: string,
      body: PostProductRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postProductInitAction());
        const response = await boardApiService.postProduct(
          companyId,
          boardId,
          listId,
          cardId,
          body
        );
        dispatch(postProductSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postProductFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const putProduct = useCallback(
    async (
      companyId: string,
      boardId: string,
      listId: string,
      cardId: string,
      productId: string,
      body: PutProductRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putProductInitAction());
        const response = await boardApiService.putProduct(
          companyId,
          boardId,
          listId,
          cardId,
          productId,
          body
        );
        dispatch(putProductSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(putProductFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const deleteProduct = useCallback(
    async (
      companyId: string,
      boardId: string,
      listId: string,
      cardId: string,
      productId: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(deleteProductInitAction());
        const response = await boardApiService.deleteProduct(
          companyId,
          boardId,
          listId,
          cardId,
          productId
        );
        dispatch(deleteProductSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteProductFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postQuote = useCallback(
    async (
      companyId: string,
      boardId: string,
      listId: string,
      cardId: string,
      quote: PostQuoteRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postQuoteInitAction());
        const response = await boardApiService.postQuote(
          companyId,
          boardId,
          listId,
          cardId,
          quote
        );
        dispatch(postQuoteSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postQuoteFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const getCustomFields = useCallback(
    async (companyId: string, boardId: string) => {
      try {
        dispatch(getCustomFieldsInitAction());
        const response = await boardApiService.getCustomFields(
          companyId,
          boardId
        );
        dispatch(getCustomFieldsSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(getCustomFieldsFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const getListRestrictions = useCallback(
    async (companyId: string, boardId: string, listId: string) => {
      try {
        dispatch(getListRestrictionsInitAction());
        const response = await boardApiService.getListRestrictions(
          companyId,
          boardId,
          listId
        );
        dispatch(getListRestrictionsSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(getCustomFieldsFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const deleteListRestrictions = useCallback(
    async (
      companyId: string,
      restrictionId: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(getListRestrictionsInitAction());
        boardApiService
          .deleteListRestriction(companyId, restrictionId)
          .then((response) => onSuccess?.());
      } catch (error: any) {
        console.error(error);
        toast.error(intl.formatMessage({ id: "app.toasts.errorRestriction" }));
      }
    },
    [dispatch]
  );
  const postListRestrictions = useCallback(
    async (
      companyId: string,
      restrictions: {
        [key: string]: {
          condition: string;
          value: string;
          required?: boolean;
        };
      },
      listId: string,
      onSuccess?: (restriction: ListRestriction) => void
    ) => {
      try {
        dispatch(postListRestrictionsInitAction());
        const response = await boardApiService.postListRestrictions(
          companyId,
          restrictions,
          listId
        );
        onSuccess?.(response.restrictions[0]);
      } catch (error: any) {
        console.error(error);
        dispatch(getCustomFieldsFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postCustomField = useCallback(
    async (
      companyId: string,
      boardId: string,
      body: PostCustomFieldRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postCustomFieldInitAction());
        const response = await boardApiService.postCustomField(
          companyId,
          boardId,
          body
        );
        dispatch(postCustomFieldSuccessAction(response));
        onSuccess?.();
        return response.field.id;
      } catch (error: any) {
        console.error(error);
        dispatch(postCustomFieldFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const putCustomField = useCallback(
    async (
      companyId: string,
      boardId: string,
      id: string,
      body: PutCustomFieldRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putCustomFieldInitAction());
        const response = await boardApiService.putCustomField(
          companyId,
          boardId,
          id,
          body
        );
        dispatch(putCustomFieldSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(putCustomFieldFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const deleteCustomField = useCallback(
    async (
      companyId: string,
      boardId: string,
      id: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(deleteCustomFieldInitAction());
        await boardApiService.deletCustomField(id, companyId, boardId);
        dispatch(deleteCustomFieldSuccessAction({ id }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteCustomFieldFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const putMoveCards = useCallback(
    async (
      companyId: string,
      boardId: string,
      body: PutMoveCardsRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putMoveCardsInitAction());
        turnOnLoading(MOVE_CARDS_LOADING);
        await boardApiService.putMoveCards(companyId, boardId, body);
        dispatch(putMoveCardsSuccessAction());
        onSuccess?.();
        turnOffLoading(MOVE_CARDS_LOADING, 1000);
      } catch (error: any) {
        if (
          error.response.status === 400 &&
          error.response.data.message.message
        ) {
          const joinedFields = error.response.data.message.fields
            .map(({ field, condition, value }: ListRestriction) => {
              return `"${
                (field as any as string).includes("standard")
                  ? standardFields[(field as any as string).split("-")[1]].name
                  : field
              }" ${condition} ${value ? `${value}` : ""} `;
            })
            .join(",");
          toast.error(
            intl.formatMessage(
              {
                id: "app.toasts.cardMovementError",
              },
              {
                currentList: error.response.data.message.currentList,
                listName: error.response.data.message.listName,
                joinedFields,
              }
            )
          );
        }

        turnOffLoading(MOVE_CARDS_LOADING);
        dispatch(putMoveCardsFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );
  const postImport = useCallback(
    async (
      companyId: string,
      name: string,
      file: File,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postBoardImportInitAction());
        turnOnLoading(BOARD_IMPORT_LOADING);
        await boardApiService.postImport(companyId, name, file);
        dispatch(postBoardImportSuccessAction());
        onSuccess?.();
        turnOffLoading(BOARD_IMPORT_LOADING, 1000);
      } catch (error: any) {
        console.error(error);
        turnOffLoading(BOARD_IMPORT_LOADING);
        dispatch(postBoardImportFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );
  const changeListOrder = useCallback(
    (id: string, index: number) => {
      dispatch(changeListOrderAction({ id, index }));
    },
    [dispatch]
  );
  const changeCardOrder = useCallback(
    (
      id: string,
      listSourceId: string,
      listDestinationId: string,
      index: number
    ) => {
      dispatch(
        changeCardOrderAction({ id, listSourceId, listDestinationId, index })
      );
    },
    [dispatch]
  );
  const setFilters = useCallback(
    (filters: PanelFilters) => {
      dispatch(setFilterAction({ filters }));
    },
    [dispatch]
  );
  const getFilters = useCallback(
    (id: string) => {
      const data = filters[`${id}`];
      const filter = {
        assignTo: data ? data.assignTo : [],
        contacts: data ? data.contacts : [],
        status: data ? data.status : [],
        tags: data ? data.tags : [],
        lists: data ? data.lists : [],
        createdSince: data ? data.createdSince : "",
        createdUntil: data ? data.createdUntil : "",
        expiresSince: data ? data.expiresSince : "",
        expiresUntil: data ? data.expiresUntil : "",
        inactivityDays: data ? data.inactivityDays : "",
        inactivityComparator: data ? data.inactivityComparator : 1,
        text: data ? data.text : "",
      };

      return filter;
    },
    [filters]
  );
  const clearFilters = useCallback(
    (id: string) => {
      dispatch(clearFilterAction({ id }));
    },
    [dispatch]
  );
  const setShowTagText = useCallback(
    (showTagText: boolean) => {
      dispatch(setShowTagTextAction({ showTagText }));
    },
    [dispatch]
  );
  const setQuote = useCallback(
    (quote: Quote) => {
      dispatch(setQuoteAction({ quote }));
    },
    [dispatch]
  );
  const cleanCard = useCallback(() => {
    dispatch(cleanCardAction());
  }, [dispatch]);
  const setBoardUpdate = useCallback(
    (board: Board) => {
      dispatch(setBoardUpdateAction({ board }));
    },
    [dispatch]
  );
  const addListRestriction = useCallback(
    (restriction: ListRestriction, listId: string) => {
      dispatch(addListRestrictionAction({ listId, restriction }));
    },
    [dispatch]
  );
  const removeListRestriction = useCallback(
    (listId: string, restrictionId: string) => {
      dispatch(removeListRestrictionAction({ listId, restrictionId }));
    },
    [dispatch]
  );

  return {
    boards,
    boardActivity,
    boardBusiness,
    lists,
    comments,
    files,
    emails,
    tasks,
    events,
    quotes,
    activities,
    tags,
    allTags,
    card,
    filters,
    listNames,
    showTagText,
    quote,
    customFields,
    errorCode,
    errorMessage,
    board,
    restrictions,
    standardFields,

    addListRestriction,
    removeListRestriction,
    getActivityBoards,
    getActivityBoard,
    getBusinessBoards,
    getBusinessBoard,
    getCard,
    postBoard,
    getBoardExport,
    putBoard,
    postList,
    putList,
    postCard,
    putCard,
    postComment,
    putComment,
    deleteComment,
    deleteCard,
    deleteBoard,
    deleteList,
    putListOrder,
    changeListOrder,
    putCardOrder,
    changeCardOrder,
    postAssignment,
    deleteAssignment,
    postContact,
    deleteContact,
    postCompany,
    deleteCompany,
    postProduct,
    putProduct,
    deleteProduct,
    postTagging,
    postFiles,
    deleteFile,
    setFilters,
    clearFilters,
    getLists,
    getCardTags,
    postCardTag,
    putCardTag,
    deleteCardTag,
    postTag,
    postQuote,
    deleteTag,
    setShowTagText,
    getListCards,
    getFilters,
    getListBudgets,
    getCustomFields,
    postCustomField,
    putCustomField,
    deleteCustomField,
    postImport,
    getListContacts,
    setQuote,
    cleanCard,
    setBoardUpdate,
    putMoveCards,
    postListRestrictions,
    getListRestrictions,
    deleteListRestrictions,
  };
}
