import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Badge, Button, Col, Container, Form, Row } from "reactstrap";
import { EVENT_POST_LOADING } from "../../../../constants/loading.constant";
import {
  EVENT_CARD_MODAL,
  EVENT_CONTACTS_MODAL,
  EVENT_USERS_MODAL,
} from "../../../../constants/modal.constant";
import { COLOR_PRIMARY, COLOR_WARNING } from "../../../../constants/theme";
import { Contact } from "../../../../cores/contacts/interfaces/api/get-contacts";
import { useGlobalCore } from "../../../../cores/globals";
import { useMeCore } from "../../../../cores/me";
import Avatar from "../../Avatar";
import ContactList from "../../boards/modal/ContactList";
import { InputWembii } from "../../InputWembii";
import { LabelWembii } from "../../LabelWembii";
import { UpdateLoadingCard } from "../../UpdateLoadingCard";
import { User } from "../../../../cores/session/interfaces/models/user";
import { EventTextEditor } from "../creator/EventTextEditor";
import UserList from "../../boards/modal/UserList";
import { CheckboxWembii } from "../../CheckboxWembii";
import DatePicker from "react-date-picker";
import moment from "moment";
import { useEventCore } from "../../../../cores/event";
import { EVENT_DURATION } from "../../../../cores/event/constants/eventDuration.constant";
import { EVENT_TIMEZONE } from "../../../../cores/event/constants/eventTimezone.constant";
import { GoogleMeetIcon } from "../../social/Google/GoogleMeetIcon";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";
import { GoogleCalendarBindRow } from "../../editMeUser/social/GoogleCalendarBindRow";
import { useOffice365Core } from "../../../../cores/office365";
import { OfficeCalendar365BindRow } from "../../editMeUser/social/OfficeCalendar365BindRow";

interface Props {
  contacts: Contact[];
  cardId?: string;
  onSuccess?: () => void;
}

export default function CreateEventForm(props: Props) {
  const intl = useIntl();
  const { settings } = useOffice365Core();
  const { user, company, userSettings } = useMeCore();
  const {
    postEvent,
    description,
    calendar,
    time: timeAvailable,
    getAvailableTime,
  } = useEventCore();
  const { isLoading, turnOnModal, turnOffModal } = useGlobalCore();
  const [title, setTitle] = useState("");
  const [date, setDate] = useState<Date>(moment().toDate());
  const [time, setTime] = useState("");
  const [duration, setDuration] = useState(15);
  const [timezone, setTimezone] = useState("Chile/Continental");
  const [internalNote, setInternalNote] = useState("");
  const [syncCalendar, setSyncCalendar] = useState(false);
  const [syncCalendar365, setSyncCalendar365] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState<
    "wembii" | "google" | "office365"
  >(
    userSettings?.googleCalendar
      ? "google"
      : settings?.token
      ? "office365"
      : "wembii"
  );
  const [meet, setMeet] = useState(false);
  const [addInternalNote, setAddInternalNote] = useState(false);
  const [contacts, setContacts] = useState<Contact[]>(props.contacts);
  const [users, setUsers] = useState<User[]>([]);

  
  useEffect(() => {
    const date = moment();
    setDate(date.toDate());
  }, []);

  useEffect(() => {
    if (calendar?.calendarId) {
      getAvailableTime(
        calendar?.calendarId!,
        moment(date).format("YYYY/MM/DD"),
        timezone,
        duration
      );
    }
  }, [calendar, date, timezone, duration, getAvailableTime]);

  const onFormSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      const usrs = users.map((user) => user.id);
      const cnts = contacts.map((contact) => contact.id);
      postEvent(
        company?.id!,
        {
          title,
          description,
          date: moment(date).format("YYYY/MM/DD"),
          time,
          duration,
          internalNote,
          contacts: cnts,
          timezone,
          users: usrs,
          syncCalendar,
          syncCalendar365: selectedEmail === "office365" || syncCalendar365,
          cardId: props.cardId,
          meet,
          googleCalendarUser:
            selectedEmail === "google" ||
            (selectedEmail === "wembii" && syncCalendar)
              ? {
                  id: `${userSettings?.googleCalendar?.profileId}`,
                  email: `${userSettings?.googleCalendar?.email}`,
                  name: `${userSettings?.googleCalendar?.name}`,
                }
              : null,
        },
        () => {
          props.onSuccess?.();
          turnOffModal(EVENT_CARD_MODAL);
        }
      );
    },
    [
      company,
      title,
      description,
      date,
      time,
      duration,
      internalNote,
      contacts,
      users,
      syncCalendar,
      timezone,
      meet,
      props,
      postEvent,
      turnOffModal,
    ]
  );

  const disabled = useCallback(
    () => !title || !duration || !date || !time || contacts.length === 0,
    [title, duration, date, time, contacts]
  );

  const toggleInternalNote = useCallback(() => {
    setAddInternalNote(!addInternalNote);
    setInternalNote("");
  }, [addInternalNote]);

  const toggleMeet = useCallback(() => {
    setMeet(!meet);
  }, [meet]);

  const toggleSyncCalendar = useCallback(() => {
    setSyncCalendar(!syncCalendar);
  }, [syncCalendar]);
  const toggleSyncCalendar365 = useCallback(() => {
    setSyncCalendar365(!syncCalendar365);
  }, [syncCalendar]);

  const handleRemoveContact = useCallback(
    (id: string) => {
      setContacts(contacts.filter((contact) => contact.id !== id));
    },
    [contacts]
  );

  const handleRemoveUser = useCallback(
    (id: string) => {
      setUsers(users.filter((user) => user.id !== id));
    },
    [users]
  );

  const openContactsModal = useCallback(() => {
    turnOnModal(
      EVENT_CONTACTS_MODAL,
      <ContactList
        assignments={contacts}
        onAssign={(contact) => {
          setContacts([...contacts, contact]);
          turnOffModal(EVENT_CONTACTS_MODAL);
        }}
      />,
      Position.LEFT
    );
  }, [turnOnModal, turnOffModal, contacts]);

  const openUsersModal = useCallback(() => {
    turnOnModal(
      EVENT_USERS_MODAL,
      <UserList
        assignments={[user!, ...users]}
        onAssign={(user) => {
          setUsers([...users, user]);
          turnOffModal(EVENT_USERS_MODAL);
        }}
      />,
      Position.LEFT
    );
  }, [turnOnModal, turnOffModal, users, user]);

  const availableTime = useMemo(
    () => (timeAvailable.length > 0 ? timeAvailable : []),
    [timeAvailable]
  );
  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column h-100 p-4">
            <h2 className="h2 d-flex justify-content-center align-items-center mb-4">
              {intl.formatMessage({ id: "app.createEvent.event" })}
            </h2>
            <Form className="px-2 h-50" onSubmit={onFormSubmit}>
              <LabelWembii>
                {intl.formatMessage({ id: "app.createEvent.organizer" })}
              </LabelWembii>

              <div className="p-2 mb-2">
                <InputWembii
                  type="select"
                  name="from"
                  id="from"
                  value={selectedEmail}
                  onChange={(value) => {
                    if (value === "office365") {
                      setSyncCalendar365(true);
                    } else {
                      setSyncCalendar365(false);
                    }
                    if (value === "google") {
                      setSyncCalendar(true);
                    } else {
                      setSyncCalendar(false);
                    }
                    setSelectedEmail(value);
                  }}
                >
                  <>
                    {userSettings?.googleCalendar ? (
                      <>
                        <option value="google">
                          {`Google Calendar: ${user?.firstName} ${
                            user?.lastName
                          } <${
                            userSettings?.googleCalendar.email ??
                            "Email desconocido"
                          }>`}
                        </option>
                      </>
                    ) : (
                      <></>
                    )}
                    <option value="wembii">
                      {` Wembii Calendar: ${user?.firstName} ${user?.lastName} <${user?.email}>`}
                    </option>
                    {settings?.token ? (
                      <>
                        <option value="office365">
                          {`Outlook Calendar: ${settings?.name}
                           <${settings?.email ?? "Email desconocido"}>`}
                        </option>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                </InputWembii>
                <div className="my-4">
                  {userSettings?.googleCalendar ? (
                    <>
                      {" "}
                      {userSettings?.googleCalendar &&
                        selectedEmail === "wembii" && (
                          <CheckboxWembii
                            disabled={!userSettings?.googleCalendar}
                            label={intl.formatMessage({
                              id: "app.createEvent.syncGoogleCalendar",
                            })}
                            id="sync-calendar"
                            className="ml-2"
                            mb={false}
                            checked={syncCalendar}
                            onChange={toggleSyncCalendar}
                          />
                        )}
                      {syncCalendar && userSettings?.googleCalendar && (
                        <CheckboxWembii
                          disabled={!syncCalendar}
                          label={intl.formatMessage({
                            id: "app.createEvent.googleMeet",
                          })}
                          id="meet"
                          className="ml-4 my-2"
                          mb={false}
                          checked={meet}
                          onChange={toggleMeet}
                          icon={
                            <GoogleMeetIcon active={true} className="mr-2" />
                          }
                        />
                      )}
                    </>
                  ) : (
                    <div className=" mx-auto">
                      <GoogleCalendarBindRow onlySyncText removeShadow />
                    </div>
                  )}{" "}
                  {settings?.token ? (
                    <>
                      {" "}
                      {settings?.token && selectedEmail === "wembii" && (
                        <CheckboxWembii
                          disabled={!settings?.token}
                          label={intl.formatMessage({
                            id: "app.createEvent.syncOutlookCalendar",
                          })}
                          id="sync-calendar"
                          className="ml-2 my-2"
                          mb={false}
                          checked={syncCalendar365}
                          onChange={toggleSyncCalendar365}
                        />
                      )}
                    </>
                  ) : (
                    <div className=" mx-auto">
                      <OfficeCalendar365BindRow onlySyncText removeShadow />
                    </div>
                  )}{" "}
                </div>
              </div>
              <InputWembii
                label={intl.formatMessage({ id: "app.createEvent.title" })}
                type="text"
                name="subject"
                id="subject"
                required
                placeholder={intl.formatMessage({
                  id: "app.createEvent.title",
                })}
                value={title}
                onChange={setTitle}
              />

              <div className="d-flex align-items-center flex-wrap">
                <div className="d-flex flex-column mr-2">
                  <LabelWembii required>
                    {intl.formatMessage({ id: "app.createEvent.date" })}
                  </LabelWembii>
                  <DatePicker
                    onChange={setDate}
                    value={date}
                    clearIcon={null}
                    format="dd/MM/y"
                  />
                </div>
                <InputWembii
                  mb={false}
                  type="select"
                  label={intl.formatMessage({ id: "app.createEvent.time" })}
                  className="mr-2"
                  required
                  value={time}
                  onChange={setTime}
                >
                  <option value=""></option>
                  {availableTime.map((hour) => (
                    <option key={hour} value={hour}>
                      {hour}
                    </option>
                  ))}
                </InputWembii>
                <InputWembii
                  mb={false}
                  type="select"
                  label={intl.formatMessage({
                    id: "app.createEvent.duration",
                  })}
                  required
                  value={duration}
                  onChange={setDuration}
                >
                  {EVENT_DURATION.map(({ name, minutes }) => (
                    <option key={minutes} value={minutes}>
                      {name}
                    </option>
                  ))}
                </InputWembii>
              </div>
              {availableTime.length === 0 && (
                <span className="text-muted">
                  <FontAwesomeIcon
                    icon={["fas", "exclamation-triangle"]}
                    size="1x"
                    color={COLOR_WARNING}
                    className="mr-1"
                  />
                  No hay disponibilidad horaria
                </span>
              )}
              <div className="mt-2 d-flex">
                <InputWembii
                  mb={false}
                  type="select"
                  label={intl.formatMessage({
                    id: "app.createEvent.timezone",
                  })}
                  required
                  value={timezone}
                  onChange={setTimezone}
                >
                  {EVENT_TIMEZONE.map((timezone) => (
                    <option key={timezone} value={timezone}>
                      {timezone}
                    </option>
                  ))}
                </InputWembii>
              </div>

              <LabelWembii className="mt-4">
                {intl.formatMessage({ id: "app.createEvent.users" })}
              </LabelWembii>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-wrap align-items-center">
                  {users.map((user) => (
                    <Badge
                      key={user.id}
                      color="secondary"
                      className="text-light m-1 d-flex align-items-center pointer"
                      pill
                    >
                      <span>{`${user.firstName} ${user.lastName}`}</span>
                      <FontAwesomeIcon
                        icon={["fas", "times"]}
                        size="1x"
                        color="white"
                        className="m-1 z-index-50 ml-1"
                        onClick={() => handleRemoveUser(user.id)}
                      />
                    </Badge>
                  ))}
                </div>
                <Avatar
                  className="text-dark-50 pointer m-1"
                  onClick={openUsersModal}
                  icon={
                    <FontAwesomeIcon
                      icon={["fas", "plus"]}
                      size="1x"
                      color={COLOR_PRIMARY}
                    />
                  }
                />
              </div>

              <LabelWembii required>
                {intl.formatMessage({ id: "app.createEvent.contacts" })}
              </LabelWembii>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-wrap align-items-center">
                  {contacts.map((contact) => (
                    <Badge
                      key={contact.id}
                      color="secondary"
                      className="text-light m-1 d-flex align-items-center pointer"
                      pill
                    >
                      <span>
                        {contact.name || contact.email || contact.phoneNumber}
                      </span>
                      <FontAwesomeIcon
                        icon={["fas", "times"]}
                        size="1x"
                        color="white"
                        className="m-1 z-index-50 ml-1"
                        onClick={() => handleRemoveContact(contact.id)}
                      />
                    </Badge>
                  ))}
                </div>
                <Avatar
                  className="text-dark-50 pointer m-1"
                  onClick={openContactsModal}
                  icon={
                    <FontAwesomeIcon
                      icon={["fas", "plus"]}
                      size="1x"
                      color={COLOR_PRIMARY}
                    />
                  }
                />
              </div>

              <LabelWembii>
                {intl.formatMessage({ id: "app.createEvent.description" })}
              </LabelWembii>
              <div className="pt-2 px-2 wembii-border-small">
                <EventTextEditor />
              </div>

              <CheckboxWembii
                label={intl.formatMessage({
                  id: "app.createEvent.addInternalNote",
                })}
                className="mt-4"
                checked={addInternalNote}
                onChange={toggleInternalNote}
              />
              <InputWembii
                type="textarea"
                name="intenalNote"
                flat
                required
                value={internalNote}
                onChange={setInternalNote}
                disabled={!addInternalNote}
              />

              {isLoading(EVENT_POST_LOADING) ? (
                <UpdateLoadingCard
                  loadingId={EVENT_POST_LOADING}
                  text={intl.formatMessage({
                    id: "app.createEvent.creating",
                  })}
                />
              ) : (
                <div className="d-flex align-items-center justify-content-between">
                  <Button
                    color="primary"
                    className="px-4 my-auto w-100 "
                    disabled={disabled()}
                  >
                    {intl.formatMessage({ id: "app.createEvent.create" })}
                  </Button>
                </div>
              )}
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
