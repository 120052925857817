import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  EVENT_GET_LOADING,
  OFFICE365_GET_LOADING,
  OFFICE365_POST_LOADING,
  OFFICE365_SYNC_LOADING,
} from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { office365ApiService } from "./services/api";
import { useIntl } from "react-intl";
import {
  deleteOffice365CalendarEventSuccessAction,
  getOffice365CalendarEventsSuccessAction,
  getOffice365CalendarEventSuccessAction,
  getOffice365FailureAction,
  getOffice365InitAction,
  getOffice365SuccessAction,
  postBindOffice365InitAction,
  postBindOffice365SuccessAction,
  postUnbindOffice365FailureAction,
  postUnbindOffice365InitAction,
  postUnbindOffice365SuccessAction,
} from "./store/api-actions";
import {
  calendarEventSelector,
  calendarEventsSelector,
  settingSelector,
  userSettingSelector,
} from "./store/selector";
import { Email } from "./interface/api/post-office365-email";
import { EmailFile } from "../email/interface/emailFile";
import { OfficeEvent } from "./interface/api/get-office365-calendar";

export function useOffice365Core() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const settings = useSelector(settingSelector);
  const userSettings = useSelector(userSettingSelector);
  const calendarEvents = useSelector(calendarEventsSelector);
  const calendarEvent = useSelector(calendarEventSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();

  const unbind = useCallback(
    async (
      companyId: string,
      asUser: boolean = false,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postUnbindOffice365InitAction());
        await office365ApiService.unbind(companyId, asUser);
        dispatch(postUnbindOffice365SuccessAction({ asUser }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postUnbindOffice365FailureAction({ error }));
      }
    },
    [dispatch]
  );

  const bind = useCallback(
    async (
      companyId: string,
      code: string,
      asUser: boolean = false,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postBindOffice365InitAction());
        turnOnLoading(OFFICE365_SYNC_LOADING);
        const response = await office365ApiService.bind(
          companyId,
          code,
          asUser
        );
        dispatch(
          postBindOffice365SuccessAction({ settings: response, asUser })
        );
        turnOffLoading(OFFICE365_SYNC_LOADING, 500);
        toast.success(intl.formatMessage({ id: "app.toast.sucess56" }));
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(OFFICE365_SYNC_LOADING);
        toast.error(intl.formatMessage({ id: "app.toast.error67" }));
        console.error(error);
        dispatch(postUnbindOffice365FailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const get = useCallback(
    async (companyId: string, asUser: boolean = false) => {
      try {
        dispatch(getOffice365InitAction());
        turnOnLoading(OFFICE365_GET_LOADING);
        const response = await office365ApiService.get(companyId, asUser);
        dispatch(getOffice365SuccessAction({ settings: response, asUser }));
        turnOffLoading(OFFICE365_GET_LOADING, 1500);
      } catch (error: any) {
        turnOffLoading(OFFICE365_GET_LOADING);
        console.error(error);
        dispatch(getOffice365FailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );
  const getCalendarEvents = useCallback(
    async (companyId: string, asUser: boolean = false) => {
      try {
        dispatch(getOffice365InitAction());
        turnOnLoading(OFFICE365_GET_LOADING);
        const response = await office365ApiService.getCalendarEvents(
          companyId,
          asUser
        );
        dispatch(getOffice365CalendarEventsSuccessAction({ events: response }));
        turnOffLoading(OFFICE365_GET_LOADING, 1500);
      } catch (error: any) {
        turnOffLoading(OFFICE365_GET_LOADING);
        console.error(error);
        dispatch(getOffice365FailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );
  const getCalendarEvent = useCallback(
    async (companyId: string, asUser: boolean = false, eventId: string) => {
      try {
        dispatch(getOffice365InitAction());
        turnOnLoading(EVENT_GET_LOADING);
        const response = await office365ApiService.getCalendarEvent(
          companyId,
          asUser,
          eventId
        );
        dispatch(
          getOffice365CalendarEventSuccessAction({
            selectedEvent: response,
          })
        );
        turnOffLoading(EVENT_GET_LOADING, 1500);
      } catch (error: any) {
        turnOffLoading(EVENT_GET_LOADING);
        console.error(error);
        dispatch(getOffice365FailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );
  const deleteCalendarEvent = useCallback(
    async (
      companyId: string,
      asUser: boolean = false,
      eventId: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(getOffice365InitAction());
        turnOnLoading(EVENT_GET_LOADING);
        await office365ApiService.deleteCalendarEvent(
          companyId,
          asUser,
          eventId
        );
        dispatch(
          deleteOffice365CalendarEventSuccessAction({
            id: eventId,
          })
        );
        turnOffLoading(EVENT_GET_LOADING, 1500);
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(EVENT_GET_LOADING);
        console.error(error);
        dispatch(getOffice365FailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );

  const login = useCallback(
    async (companyId: string, asUser: boolean = false) => {
      try {
        turnOnLoading(OFFICE365_SYNC_LOADING);
        const response = await office365ApiService.login(companyId, asUser);
        return response.url;
      } catch (error: any) {
        turnOffLoading(OFFICE365_SYNC_LOADING);
        console.error(error);
        toast.error(intl.formatMessage({ id: "app.toast.error83" }));
      }
    },
    [turnOffLoading, turnOnLoading, intl]
  );

  const postEmail = useCallback(
    async (
      companyId: string,
      email: Email,
      files: EmailFile[],
      asUser: boolean = false,
      cardId: string | null,
      onSuccess?: () => void
    ) => {
      try {
        turnOnLoading(OFFICE365_POST_LOADING);
        const id = await office365ApiService.postEmail(
          companyId,
          email,
          files,
          asUser,
          cardId
        );
        turnOffLoading(OFFICE365_POST_LOADING, 500);
        onSuccess?.();
        toast.success(intl.formatMessage({ id: "app.toast.sucess47" }));
        return id;
      } catch (error: any) {
        turnOffLoading(OFFICE365_POST_LOADING);
        console.error(error);
        toast.error(intl.formatMessage({ id: "app.toast.error69" }));
      }
    },
    [turnOffLoading, turnOnLoading, intl]
  );

  return {
    settings,
    userSettings,
    calendarEvents,
    calendarEvent,
    unbind,
    bind,
    get,
    login,
    postEmail,
    getCalendarEvents,
    getCalendarEvent,
    deleteCalendarEvent,
  };
}
