import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";

export const addOnlineClientAction = createAction(
  "[Socket] Set new online client",
  props<{ id: string }>()
);

export const deleteOnlineClientAction = createAction(
  "[Socket] Delete online client",
  props<{ id: string }>()
);

export const socketDisconnectedAction = createAction(
  "[Socket] Socket disconnected action",
  props<{ disconnected: boolean }>()
);
export const addNewNotificationAction = createAction(
  "[Socket] Add new notification",
  props<{ id: string }>()
);

const actions = joinActions({
  addOnlineClientAction,
  socketDisconnectedAction,
  deleteOnlineClientAction,
  addNewNotificationAction,
});

export type SocketAction = typeof actions;
