import mainApiClient from "../../helpers/services/main-api-client";
import { DeleteCommentResponse } from "../interfaces/api/delete-comment";
import { DeleteCompanyResponse } from "../interfaces/api/delete-company";
import { DeleteContactInputResponse } from "../interfaces/api/delete-config";
import {
  GetCompaniesParams,
  GetCompaniesResponse,
} from "../interfaces/api/get-companies";
import { GetCompaniesExportResponse } from "../interfaces/api/get-companies-export";
import { GetCompanyResponse } from "../interfaces/api/get-company";
import { GetContactConfigResponse } from "../interfaces/api/get-config";
import { GetContactResponse } from "../interfaces/api/get-contact";
import { GetContactStatesResponse } from "../interfaces/api/get-contact-states";
import { GetContactTagsResponse } from "../interfaces/api/get-contact-tags";
import {
  GetContactsParams,
  GetContactsResponse,
} from "../interfaces/api/get-contacts";
import { GetContactsExportResponse } from "../interfaces/api/get-contacts-export";
import { GetCustomFieldsResponse } from "../interfaces/api/get-custom-fields";
import {
  PostCommentRequest,
  PostCommentResponse,
} from "../interfaces/api/post-comment";
import {
  PostCompanyRequest,
  PostCompanyResponse,
} from "../interfaces/api/post-company";
import {
  PostContactConfigRequest,
  PostContactConfigResponse,
} from "../interfaces/api/post-config";
import {
  PostContactRequest,
  PostContactResponse,
} from "../interfaces/api/post-contact";
import {
  PostContactCompanyRequest,
  PostContactCompanyResponse,
} from "../interfaces/api/post-contact-company";
import {
  PostContactStateRequest,
  PostContactStateResponse,
} from "../interfaces/api/post-contact-state";
import {
  PostContactTagRequest,
  PostContactTagResponse,
} from "../interfaces/api/post-contact-tag";
import {
  CsvContact,
  PostContactsRequest,
} from "../interfaces/api/post-contacts";
import {
  PostCustomFieldRequest,
  PostCustomFieldResponse,
} from "../interfaces/api/post-custom-field";
import { PostMassAssignmentRequest } from "../interfaces/api/post-mass-assignment";
import {
  PutCommentRequest,
  PutCommentResponse,
} from "../interfaces/api/put-comment";
import {
  PutCompanyRequest,
  PutCompanyResponse,
} from "../interfaces/api/put-company";
import { PutContactRequest } from "../interfaces/api/put-contact";
import {
  PutContactCompanyRequest,
  PutContactCompanyResponse,
} from "../interfaces/api/put-contact-company";
import { PutContactStateRequest } from "../interfaces/api/put-contact-state";
import { PutContactTagRequest } from "../interfaces/api/put-contact-tag";
import { PutCustomFieldRequest } from "../interfaces/api/put-custom-field";
import { contactsMapper } from "./mapper";

export const contactApiService = {
  postContact(companyId: string, body: PostContactRequest) {
    return mainApiClient()
      .post<PostContactResponse>(`contacts`, body, { params: { companyId } })
      .then(({ data }) => data.payload.contact);
  },
  postContacts(companyId: string, contacts: CsvContact[], tag: string) {
    const body: PostContactsRequest = {
      contacts,
      tag,
    };
    return mainApiClient()
      .post(`contacts/import`, body, { params: { companyId } })
      .then(({ data }) => data.payload);
  },
  putContact(companyId: string, contactId: string, body: PutContactRequest) {
    return mainApiClient()
      .put(`contacts/${contactId}`, body, { params: { companyId } })
      .then(({ data }) => contactsMapper.mapResponseToSimpleContact(data));
  },
  getContacts(companyId: string, params: GetContactsParams) {
    return mainApiClient()
      .get<GetContactsResponse>(`contacts`, {
        params: { companyId, ...params },
      })
      .then(({ data }) => contactsMapper.mapResponseToContacts(data));
  },
  getNewContactsCount(companyId: string) {
    return mainApiClient()
      .get<GetContactsResponse>(`contacts/new-contacts-count`, {
        params: { companyId },
      })
      .then(({ data }) => contactsMapper.mapResponseToContactsCount(data));
  },
  getContact(companyId: string, contactId: string) {
    return mainApiClient()
      .get<GetContactResponse>(`contacts/${contactId}`, {
        params: { companyId },
      })
      .then(({ data }) => contactsMapper.mapResponseToContact(data));
  },
  getExportContacts(companyId: string, params: GetContactsParams) {
    return mainApiClient()
      .get<GetContactsExportResponse>(`contacts/export`, {
        params: { companyId, ...params },
      })
      .then(({ data }) => contactsMapper.mapResponseToContactsExport(data));
  },
  deleteContacts(contacts: string[], companyId: string) {
    return mainApiClient()
      .delete(`contacts`, { params: { companyId, contacts } })
      .then(({ data }) => data);
  },
  postContactTag(
    companyId: string,
    name: string,
    textColor: string,
    backgroundColor: string
  ) {
    const body: PostContactTagRequest = {
      name,
      textColor,
      backgroundColor,
    };
    return mainApiClient()
      .post<PostContactTagResponse>(`contacts/tags`, body, {
        params: { companyId },
      })
      .then(({ data }) => data.payload.tag);
  },
  putContactTag(
    companyId: string,
    id: string,
    name: string,
    textColor: string,
    backgroundColor: string
  ) {
    const body: PutContactTagRequest = {
      name,
      textColor,
      backgroundColor,
    };
    return mainApiClient()
      .put(`contacts/tags/${id}`, body, { params: { companyId } })
      .then(({ data }) => data.payload.tag);
  },
  getContactTags(companyId: string) {
    return mainApiClient()
      .get<GetContactTagsResponse>(`contacts/tags`, {
        params: { companyId },
      })
      .then(({ data }) => contactsMapper.mapResponseToContactTags(data));
  },
  deleteContactTag(id: string, companyId: string) {
    return mainApiClient()
      .delete(`contacts/tags/${id}`, { params: { companyId } })
      .then(({ data }) => data);
  },
  postContactState(companyId: string, name: string) {
    const body: PostContactStateRequest = {
      name,
    };
    return mainApiClient()
      .post<PostContactStateResponse>(`contacts/states`, body, {
        params: { companyId },
      })
      .then(({ data }) => data.payload.state);
  },
  putContactState(companyId: string, id: string, name: string) {
    const body: PutContactStateRequest = {
      name,
    };
    return mainApiClient()
      .put(`contacts/states/${id}`, body, { params: { companyId } })
      .then(({ data }) => data.payload.state);
  },
  getContactStates(companyId: string) {
    return mainApiClient()
      .get<GetContactStatesResponse>(`contacts/states`, {
        params: { companyId },
      })
      .then(({ data }) => contactsMapper.mapResponseToContactState(data));
  },
  deleteContactState(id: string, companyId: string) {
    return mainApiClient()
      .delete(`contacts/states/${id}`, { params: { companyId } })
      .then(({ data }) => data);
  },
  postCustomField(companyId: string, name: string) {
    const body: PostCustomFieldRequest = {
      name,
    };
    return mainApiClient()
      .post<PostCustomFieldResponse>(`contacts/customfields`, body, {
        params: { companyId },
      })
      .then(({ data }) => contactsMapper.mapResponseToCustomField(data));
  },
  putCustomField(companyId: string, id: string, name: string) {
    const body: PutCustomFieldRequest = {
      name,
    };
    return mainApiClient()
      .put(`contacts/customfields/${id}`, body, { params: { companyId } })
      .then(({ data }) => contactsMapper.mapResponseToCustomField(data));
  },
  getCustomFields(companyId: string) {
    return mainApiClient()
      .get<GetCustomFieldsResponse>(`contacts/customfields`, {
        params: { companyId },
      })
      .then(({ data }) => contactsMapper.mapResponseToCustomFields(data));
  },
  deleteCustomField(id: string, companyId: string) {
    return mainApiClient()
      .delete(`contacts/customfields/${id}`, { params: { companyId } })
      .then(({ data }) => contactsMapper.mapResponseToId(data));
  },
  postCompanyCustomField(companyId: string, name: string) {
    const body: PostCustomFieldRequest = {
      name,
    };
    return mainApiClient()
      .post<PostCustomFieldResponse>(`contacts/company/customfields`, body, {
        params: { companyId },
      })
      .then(({ data }) => contactsMapper.mapResponseToCustomField(data));
  },
  putCompanyCustomField(companyId: string, id: string, name: string) {
    const body: PutCustomFieldRequest = {
      name,
    };
    return mainApiClient()
      .put(`contacts/company/customfields/${id}`, body, {
        params: { companyId },
      })
      .then(({ data }) => contactsMapper.mapResponseToCustomField(data));
  },
  getCompanyCustomFields(companyId: string) {
    return mainApiClient()
      .get<GetCustomFieldsResponse>(`contacts/company/customfields`, {
        params: { companyId },
      })
      .then(({ data }) => contactsMapper.mapResponseToCustomFields(data));
  },
  deleteCompanyCustomField(id: string, companyId: string) {
    return mainApiClient()
      .delete(`contacts/company/customfields/${id}`, { params: { companyId } })
      .then(({ data }) => contactsMapper.mapResponseToId(data));
  },
  getCompanies(companyId: string, filters: GetCompaniesParams) {
    return mainApiClient()
      .get<GetCompaniesResponse>(`contacts/company`, {
        params: { companyId, ...filters },
      })
      .then(({ data }) => contactsMapper.mapResponseToCompanies(data));
  },
  getCompaniesCount(companyId: string) {
    return mainApiClient()
      .get<GetCompaniesResponse>(`contacts/company/count`, {
        params: { companyId },
      })
      .then(({ data }) => contactsMapper.mapResponseToCompaniesCount(data));
  },
  getExportCompanies(companyId: string, params: GetCompaniesParams) {
    return mainApiClient()
      .get<GetCompaniesExportResponse>(`contacts/company/export`, {
        params: { companyId, ...params },
      })
      .then(({ data }) => contactsMapper.mapResponseToExportCompanies(data));
  },
  getCompany(companyId: string, contactCompanyId: string) {
    return mainApiClient()
      .get<GetCompanyResponse>(`contacts/company/${contactCompanyId}`, {
        params: { companyId },
      })
      .then(({ data }) => contactsMapper.mapResponseToContactCompany(data));
  },
  postCompany(companyId: string, body: PostCompanyRequest) {
    return mainApiClient()
      .post<PostCompanyResponse>(`contacts/company`, body, {
        params: { companyId },
      })
      .then(({ data }) => contactsMapper.mapResponseToCompany(data));
  },
  putCompany(
    companyId: string,
    contactCompanyId: string,
    body: PutCompanyRequest
  ) {
    return mainApiClient()
      .put<PutCompanyResponse>(`contacts/company/${contactCompanyId}`, body, {
        params: { companyId },
      })
      .then(({ data }) => contactsMapper.mapResponseToCompany(data));
  },
  deleteCompany(companyId: string, contactCompanyId: string) {
    return mainApiClient()
      .delete<DeleteCompanyResponse>(`contacts/company/${contactCompanyId}`, {
        params: { companyId },
      })
      .then(({ data }) => contactsMapper.mapResponseToId(data));
  },
  postContactCompany(
    companyId: string,
    contactCompanyId: string,
    contactId: string,
    role: string
  ) {
    const body: PostContactCompanyRequest = {
      role,
    };
    return mainApiClient()
      .post<PostContactCompanyResponse>(
        `contacts/company/${contactCompanyId}/contact/${contactId}`,
        body,
        { params: { companyId } }
      )
      .then(({ data }) => data.payload);
  },
  putContactCompany(
    companyId: string,
    contactCompanyId: string,
    contactId: string,
    role: string
  ) {
    const body: PutContactCompanyRequest = {
      role,
    };
    return mainApiClient()
      .put<PutContactCompanyResponse>(
        `contacts/company/${contactCompanyId}/contact/${contactId}`,
        body,
        { params: { companyId } }
      )
      .then(({ data }) => data.payload);
  },
  deleteContactCompany(
    companyId: string,
    contactCompanyId: string,
    contactId: string
  ) {
    return mainApiClient()
      .delete<DeleteCompanyResponse>(
        `contacts/company/${contactCompanyId}/contact/${contactId}`,
        { params: { companyId } }
      )
      .then(({ data }) => data.payload);
  },
  getContactConfig(companyId: string) {
    return mainApiClient()
      .get<GetContactConfigResponse>(`contacts/config`, {
        params: { companyId },
      })
      .then(({ data }) => contactsMapper.mapResponseToContactConfigs(data));
  },
  postContactConfig(companyId: string, config: PostContactConfigRequest) {
    return mainApiClient()
      .post<PostContactConfigResponse>(`contacts/config`, config, {
        params: { companyId },
      })
      .then(({ data }) => contactsMapper.mapResponseToContactConfig(data));
  },
  deleteContactConfig(companyId: string, configId: string) {
    return mainApiClient()
      .delete<DeleteContactInputResponse>(`contacts/config/${configId}`, {
        params: { companyId },
      })
      .then(({ data }) => contactsMapper.mapResponseToId(data));
  },
  postComment(companyId: string, contactId: string, body: PostCommentRequest) {
    return mainApiClient()
      .post<PostCommentResponse>(`contacts/${contactId}/comment`, body, {
        params: { companyId },
      })
      .then(({ data }) => contactsMapper.mapResponseToComment(data));
  },
  postMassAssignment(companyId: string, body: PostMassAssignmentRequest) {
    return mainApiClient()
      .post<PostCommentResponse>(`contacts/mass/assignment`, body, {
        params: { companyId },
      })
      .then(({ data }) => data.payload);
  },
  putComment(
    companyId: string,
    contactId: string,
    commentId: string,
    body: PutCommentRequest
  ) {
    return mainApiClient()
      .put<PutCommentResponse>(
        `contacts/${contactId}/comment/${commentId}`,
        body,
        { params: { companyId } }
      )
      .then(({ data }) => contactsMapper.mapResponseToComment(data));
  },
  deleteComment(companyId: string, contactId: string, commentId: string) {
    return mainApiClient()
      .delete<DeleteCommentResponse>(
        `contacts/${contactId}/comment/${commentId}`,
        { params: { companyId } }
      )
      .then(({ data }) => contactsMapper.mapResponseToId(data));
  },
};
