import React, { useCallback } from "react";
import { OPENAI_SOCIAL_FORM } from "../../../../constants/modal.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { OpenAiIcon } from "./OpenAiIcon";

interface AiGenerationButtonProps {
  /**
   * Size of the button icon
   * @default "1x"
   */
  size?: "1x" | "2x" | "3x" | "4x" | "5x";

  /**
   * Additional CSS classes
   */
  className?: string;

  /**
   * Custom modal form ID to open
   * @default OPENAI_SOCIAL_FORM
   */
  modalFormId?: string;

  /**
   * Whether the button is disabled
   * @default false
   */
  disabled?: boolean;

  /**
   * Custom click handler
   * If provided, will override default modal behavior
   */
  onClick?: () => void;
}

export const AiGenerationButton: React.FC<AiGenerationButtonProps> = ({
  size = "1x",
  className = "mx-1 pointer textEditor-icon",
  modalFormId = OPENAI_SOCIAL_FORM,
  disabled = false,
  onClick,
}) => {
  const { isModal, turnOnModal } = useGlobalCore();

  const openOpenAiModal = useCallback(() => {
    turnOnModal(modalFormId, true);
  }, [turnOnModal, modalFormId]);

  const handleClick = useCallback(() => {
    if (disabled) return;

    if (onClick) {
      onClick();
    } else {
      openOpenAiModal();
    }
  }, [disabled, onClick, openOpenAiModal]);

  return (
    <OpenAiIcon
      size={size}
      active={isModal(modalFormId)}
      className={className}
      onClick={handleClick}
    />
  );
};
