import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const socketRoot = (state: State) => state.socket;

export const onlineClientsSelector = createSelector(socketRoot, (me) => me.onlineClients);

export const notificationSelector = createSelector(socketRoot, (me) => me.notification);
export const disconnectedSelector = createSelector(
  socketRoot,
  (me) => me.disconnected
);
