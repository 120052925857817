import React, { ReactNode, useMemo } from "react";
import { useIntl } from "react-intl";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { BoardType } from "../../../../cores/boards/constants/board-type.constant";
import { Board } from "../../../../cores/boards/interfaces/api/get-board";
import { useMeCore } from "../../../../cores/me";
import { CheckboxWembii } from "../../CheckboxWembii";
import { GmailIcon } from "../../social/Gmail/GmailIcon";

interface DropdownOptionsProps {
  addToProbability: boolean;
  canModify: boolean | undefined;
  cardAmount: number;
  onDelete: () => void;
  onChangeProbability: (arg0: boolean) => void;
  onSendingEmail: () => void;
  onOpeningMoveCards: () => void;
  onOpenAutomation?: () => void;
  onOpenRestrictions?: () => void;
  children: ReactNode;
  board: Board | null;
  hasRestrictions?: boolean;
}

export const DropdownOptions = (props: DropdownOptionsProps) => {
  const intl = useIntl();
  const {
    children,
    onChangeProbability,
    onSendingEmail,
    onOpenRestrictions,
    onDelete,
    onOpeningMoveCards,
    addToProbability,
    onOpenAutomation,
    canModify,
    board,
    cardAmount,
  } = props;
  const { socialSettings, userSettings, isAdmin, isReseller, companyRole } =
    useMeCore();
  const showGmailButton = useMemo(
    () =>
      socialSettings?.gmail ||
      userSettings?.gmail ||
      socialSettings?.smtp ||
      userSettings?.smtp,
    [socialSettings, userSettings]
  );
  const canAutomate =
    isAdmin || isReseller || companyRole?.owner || companyRole?.admin;
  return (
    <UncontrolledDropdown nav inNavbar className="">
      <DropdownToggle nav className="p-0">
        {children}
      </DropdownToggle>
      <DropdownMenu right>
        {board?.type === BoardType.BUSINESS && (
          <>
            {showGmailButton && (
              <DropdownItem onClick={onSendingEmail}>
                <GmailIcon active className="mr-2" />
                {intl.formatMessage({ id: "app.sendEmail.sendEmail" })}
              </DropdownItem>
            )}
            {canModify && (
              <>
                <DropdownItem>
                  <CheckboxWembii
                    onChange={() => onChangeProbability(!addToProbability)}
                    mb={false}
                    label={intl.formatMessage({
                      id: "app.crmBusiness.addToProbability",
                    })}
                    checked={addToProbability}
                  />
                </DropdownItem>
                <DropdownItem
                  onClick={onOpeningMoveCards}
                  disabled={cardAmount === 0}
                >
                  <div className="d-flex flex-wrap">
                    {intl.formatMessage({ id: "app.crmBusiness.moveCards" })}
                  </div>
                </DropdownItem>
              </>
            )}
          </>
        )}
        {canAutomate && (
          <>
            <DropdownItem onClick={onOpenAutomation}>
              {intl.formatMessage({
                id: "app.crmBusiness.notifyInactivity",
              })}
            </DropdownItem>
            <DropdownItem onClick={onOpenRestrictions}>
              {intl.formatMessage({
                id: "app.crmBusiness.listRestrictions",
              })}
            </DropdownItem>
            <DropdownItem onClick={onDelete}>
              {intl.formatMessage({ id: "app.crmBusiness.delete" })}
            </DropdownItem>
          </>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
