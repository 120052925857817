import { create } from "../../helpers/services/main-socket-client";
import {
  addInstagramMessageAction,
  addFacebookMessageAction,
  addWhatsappMessageAction,
  addWhatsappConversationAction,
  changeSocialConversationStatusAction,
} from "../../socialChat/store/actions";
import {
  addConversationAction,
  addWebMessageAction,
  changeConversationStatusAction,
} from "../../conversations/store/actions";
import { MessageType } from "../constant/messageType.constant";
import { SocketAction } from "../constant/socketAction.constant";
import {
  addNewNotificationAction,
  addOnlineClientAction,
  deleteOnlineClientAction,
  socketDisconnectedAction,
} from "../store/actions";
import {
  leaveFromChannelAction,
  sendMessageToChannelAction,
  subscribeToChannelAction,
} from "../store/socket-actions";
import { setIsOnlineAction } from "../../globals/store/actions";
import { Socket } from "socket.io-client";
export const socketMiddleware = () => {
  let socket: Socket | null;

  return (store: any) => {
    socket = create();
    const initializeSocket = () => {
      const handleSocketEvents = () => {
        
        socket?.on(SocketAction.MESSAGE, (data: any) => {
          switch (data.type) {
            case MessageType.FACEBOOK:
              store.dispatch(
                addFacebookMessageAction({
                  message: data.message,
                  id: data.id,
                })
              );
              break;
            case MessageType.INSTAGRAM:
              store.dispatch(
                addInstagramMessageAction({
                  message: data.message,
                  id: data.id,
                })
              );
              break;
            case MessageType.WEB:
              store.dispatch(
                addWebMessageAction({
                  message: data.message,
                  id: data.id,
                })
              );
              break;
            case MessageType.WHATSAPP:
              store.dispatch(
                addWhatsappMessageAction({
                  message: data.message,
                  id: data.id,
                })
              );
              break;
          }
        });

        socket?.on(SocketAction.CONVERSATION, (data: any) => {
          switch (data.type) {
            case MessageType.WHATSAPP:
              store.dispatch(
                addWhatsappConversationAction({
                  conversation: data.conversation,
                })
              );
              break;
            case MessageType.WEB:
              store.dispatch(
                addConversationAction({
                  conversation: data.conversation,
                })
              );
              break;
          }
        });

        socket?.on(SocketAction.NOTIFICATION, (data: any) => {
          store.dispatch(addNewNotificationAction({ id: data.id }));
        });

        socket?.on(SocketAction.ONLINE, (data: any) => {
          store.dispatch(addOnlineClientAction({ id: data.contactId }));
        });

        socket?.on(SocketAction.OFFLINE, (id: string) => {
          store.dispatch(deleteOnlineClientAction({ id }));
        });

        socket?.on(SocketAction.CONVERSATION_STATUS, (data: any) => {
          if (data.type === "web") {
            store.dispatch(
              changeConversationStatusAction({
                conversation: data,
                conversationStatus:
                  store.getState().socialChat.conversationStatus,
              })
            );
          } else {
            store.dispatch(
              changeSocialConversationStatusAction({
                conversation: data,
              })
            );
          }
        });

        socket?.on("connect", () => {
          store.dispatch(socketDisconnectedAction({ disconnected: false }));
          store.dispatch(setIsOnlineAction({ isOnline: true }));
        });

        socket?.on("disconnect", () => {
          store.dispatch(socketDisconnectedAction({ disconnected: true }));
          store.dispatch(setIsOnlineAction({ isOnline: false }));
        });
      };

      handleSocketEvents();

      return () => {
        socket?.disconnect();
        socket = null;
      };
    };
    initializeSocket();
    return (next: any) => (action: any) => {
      switch (action.type) {
        case subscribeToChannelAction.type:
          socket?.emit(SocketAction.SUBSCRIBE, {
            userId: action.userId,
            conversationId: action.conversationId,
            channel: action.channel,
            companyId: action.companyId,
          });
          break;
        case leaveFromChannelAction.type:
          socket?.emit(SocketAction.LEAVE, { channel: action.channel });
          break;
        case sendMessageToChannelAction.type:
          socket?.emit(SocketAction.WEB_MESSAGE, {
            ...action.message,
            channel: action.channel,
          });
          break;
        default:
          return next(action);
      }
    };
  };
};
