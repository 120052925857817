import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { Company } from "../interfaces/api/get-company";
import { Config } from "../interfaces/api/get-config";
import { Card, Comment } from "../interfaces/api/get-contact";
import { ContactState } from "../interfaces/api/get-contact-states";
import { ContactTag } from "../interfaces/api/get-contact-tags";
import { Contact } from "../interfaces/api/get-contacts";
import { CustomField } from "../interfaces/api/get-custom-fields";

export const postContactInitAction = createAction(
  "[Contact] Post Contact Init"
);
export const postContactSuccessAction = createAction(
  "[Contact] Post Contact Success"
);
export const postContactFailureAction = createAction(
  "[Contact] Post Contact Failure",
  props<{ error: string }>()
);

export const putContactInitAction = createAction("[Contact] Put Contact Init");
export const putContactSuccessAction = createAction(
  "[Contact] Put Contact Success",
  props<{ contact: Contact }>()
);
export const putContactFailureAction = createAction(
  "[Contact] Put Contact Failure",
  props<{ error: string }>()
);

export const postImportContactsInitAction = createAction(
  "[Contact] Post Import Contacts Init"
);
export const postImportContactsSuccessAction = createAction(
  "[Contact] Post Import Contacts Success"
);
export const postImportContactsFailureAction = createAction(
  "[Contact] Post Import Contacts Failure",
  props<{ error: string }>()
);

export const getContactsInitAction = createAction(
  "[Contact] Get Contacts Init"
);
export const getContactsSuccessAction = createAction(
  "[Contact] Get Contacts Success",
  props<{ contacts: Contact[]; total: number }>()
);
export const getNewContactsCountSuccessAction = createAction(
  "[Contact] Get New Contacts Count Success",
  props<{ total: number }>()
);
export const getContactsFailureAction = createAction(
  "[Contact] Get Contacts Failure",
  props<{ error: string }>()
);

export const getContactInitAction = createAction("[Contact] Get Contact Init");
export const getContactSuccessAction = createAction(
  "[Contact] Get Contact Success",
  props<{ contact: Contact; cards: Card[] }>()
);
export const getContactFailureAction = createAction(
  "[Contact] Get Contact Failure",
  props<{ error: string }>()
);

export const getExportContactsInitAction = createAction(
  "[Contact] Get Export Contacts Init"
);
export const getExportContactsSuccessAction = createAction(
  "[Contact] Get Export Contacts Success"
);
export const getExportContactsFailureAction = createAction(
  "[Contact] Get Export Contacts Failure",
  props<{ error: string }>()
);

export const postContactTagInitAction = createAction(
  "[Contact] Post Contact Tag Init"
);
export const postContactTagSuccessAction = createAction(
  "[Contact] Post Contact Tag Success",
  props<{ tag: ContactTag }>()
);
export const postContactTagFailureAction = createAction(
  "[Contact] Post Contact Tag Failure",
  props<{ error: string }>()
);

export const putContactTagInitAction = createAction(
  "[Contact] Put Contact Tag Init"
);
export const putContactTagSuccessAction = createAction(
  "[Contact] Put Contact Tag Success",
  props<{ tag: ContactTag }>()
);
export const putContactTagFailureAction = createAction(
  "[Contact] Put Contact Tag Failure",
  props<{ error: string }>()
);

export const deleteContactTagInitAction = createAction(
  "[Contact] Delete Contact Tag Init"
);
export const deleteContactTagSuccessAction = createAction(
  "[Contact] Delete Contact Tag Success",
  props<{ id: string }>()
);
export const deleteContactTagFailureAction = createAction(
  "[Contact] Delete Contact Tag Failure",
  props<{ error: string }>()
);

export const getContactTagsInitAction = createAction(
  "[Contact] Get Contact Tags Init"
);
export const getContactTagsSuccessAction = createAction(
  "[Contact] Get Contact Tags Success",
  props<{ tags: ContactTag[] }>()
);
export const getContactTagsFailureAction = createAction(
  "[Contact] Get Contact Tags Failure",
  props<{ error: string }>()
);

export const postContactStateInitAction = createAction(
  "[Contact] Post Contact State Init"
);
export const postContactStateSuccessAction = createAction(
  "[Contact] Post Contact State Success",
  props<{ state: ContactState }>()
);
export const postContactStateFailureAction = createAction(
  "[Contact] Post Contact State Failure",
  props<{ error: string }>()
);

export const putContactStateInitAction = createAction(
  "[Contact] Put Contact State Init"
);
export const putContactStateSuccessAction = createAction(
  "[Contact] Put Contact State Success",
  props<{ state: ContactState }>()
);
export const putContactStateFailureAction = createAction(
  "[Contact] Put Contact State Failure",
  props<{ error: string }>()
);

export const deleteContactStateInitAction = createAction(
  "[Contact] Delete Contact State Init"
);
export const deleteContactStateSuccessAction = createAction(
  "[Contact] Delete Contact State Success",
  props<{ id: string }>()
);
export const deleteContactStateFailureAction = createAction(
  "[Contact] Delete Contact State Failure",
  props<{ error: string }>()
);

export const getContactStatesInitAction = createAction(
  "[Contact] Get Contact States Init"
);
export const getContactStatesSuccessAction = createAction(
  "[Contact] Get Contact State Success",
  props<{ states: ContactState[] }>()
);
export const getContactStatesFailureAction = createAction(
  "[Contact] Get Contact State Failure",
  props<{ error: string }>()
);

export const getCustomFieldsInitAction = createAction(
  "[Contact] Get Contact Custom Fields Init"
);
export const getCustomFieldsSuccessAction = createAction(
  "[Contact] Get Contact Custom Fields Success",
  props<{ fields: CustomField[] }>()
);
export const getCustomFieldsFailureAction = createAction(
  "[Contact] Get Contact Custom Fields Failure",
  props<{ error: string }>()
);

export const getCompaniesInitAction = createAction(
  "[Contact] Get Companies Init"
);
export const getCompaniesSuccessAction = createAction(
  "[Contact] Get Companies Success",
  props<{ companies: Company[]; total: number }>()
);
export const getNewCompaniesCountSuccessAction = createAction(
  "[Contact] Get  new Companies Count Success",
  props<{ total: number }>()
);
export const getCompaniesFailureAction = createAction(
  "[Contact] Get Companies Failure",
  props<{ error: string }>()
);

export const getCompanyInitAction = createAction("[Contact] Get Company Init");
export const getCompanySuccessAction = createAction(
  "[Contact] Get Company Success",
  props<{ company: Company }>()
);
export const getCompanyFailureAction = createAction(
  "[Contact] Get Company Failure",
  props<{ error: string }>()
);

export const postCompanyInitAction = createAction(
  "[Contact] Post Company Init"
);
export const postCompanySuccessAction = createAction(
  "[Contact] Post Company Success",
  props<{ company: Company }>()
);
export const postCompanyFailureAction = createAction(
  "[Contact] Post Company Failure",
  props<{ error: string }>()
);

export const putCompanyInitAction = createAction("[Contact] Put Company Init");
export const putCompanySuccessAction = createAction(
  "[Contact] Put Company Success",
  props<{ company: Company }>()
);
export const putCompanyFailureAction = createAction(
  "[Contact] Put Company Failure",
  props<{ error: string }>()
);

export const deleteCompanyInitAction = createAction(
  "[Contact] Delete Company Init"
);
export const deleteCompanySuccessAction = createAction(
  "[Contact] Delete Company Success",
  props<{ id: string }>()
);
export const deleteCompanyFailureAction = createAction(
  "[Contact] Delete Company Failure",
  props<{ error: string }>()
);

export const postCustomFieldInitAction = createAction(
  "[Contact] Post Contact Custom Field Init"
);
export const postCustomFieldSuccessAction = createAction(
  "[Contact] Post Contact Custom Field Success",
  props<{ field: CustomField }>()
);
export const postCustomFieldFailureAction = createAction(
  "[Contact] Post Contact Custom Field Failure",
  props<{ error: string }>()
);

export const putCustomFieldInitAction = createAction(
  "[Contact] Put Contact Custom Field Init"
);
export const putCustomFieldSuccessAction = createAction(
  "[Contact] Put Contact Custom Field Success",
  props<{ field: CustomField }>()
);
export const putCustomFieldFailureAction = createAction(
  "[Contact] Put Contact Custom Field Failure",
  props<{ error: string }>()
);

export const deleteCustomFieldInitAction = createAction(
  "[Contact] Delete Contact Custom Field Init"
);
export const deleteCustomFieldSuccessAction = createAction(
  "[Contact] Delete Contact Custom Field Success",
  props<{ id: string }>()
);
export const deleteCustomFieldFailureAction = createAction(
  "[Contact] Delete Contact Custom Field Failure",
  props<{ error: string }>()
);

export const postContactCompanyInitAction = createAction(
  "[Contact] Post Contact Company Init"
);
export const postContactCompanySuccessAction = createAction(
  "[Contact] Post Contact Company Success",
  props<{ company: Contact }>()
);
export const postContactCompanyFailureAction = createAction(
  "[Contact] Post Contact Company Failure",
  props<{ error: string }>()
);

export const putContactCompanyInitAction = createAction(
  "[Contact] Put Contact Company Init"
);
export const putContactCompanySuccessAction = createAction(
  "[Contact] Put Contact Company Success",
  props<{ company: Contact }>()
);
export const putContactCompanyFailureAction = createAction(
  "[Contact] Put Contact Company Failure",
  props<{ error: string }>()
);

export const deleteContactCompanyInitAction = createAction(
  "[Contact] Delete Contact Company Init"
);
export const deleteContactCompanySuccessAction = createAction(
  "[Contact] Delete Contact Company Success",
  props<{ id: string }>()
);
export const deleteContactCompanyFailureAction = createAction(
  "[Contact] Delete Contact Company Failure",
  props<{ error: string }>()
);

export const getContactConfigInitAction = createAction(
  "[Contact] Get Contact Config Init"
);
export const getContactConfigSuccessAction = createAction(
  "[Contact] Get Contact Config Success",
  props<{ config: Config[] }>()
);
export const getContactConfigFailureAction = createAction(
  "[Contact] Get Contact Config Failure",
  props<{ error: string }>()
);

export const postContactConfigInitAction = createAction(
  "[Contact] Post Contact Config Init"
);
export const postContactConfigSuccessAction = createAction(
  "[Contact] Post Contact Config Success",
  props<{ config: Config }>()
);
export const postContactConfigFailureAction = createAction(
  "[Contact] Post Contact Config Failure",
  props<{ error: string }>()
);

export const deleteContactConfigInitAction = createAction(
  "[Contact] Delete Contact Config Init"
);
export const deleteContactConfigSuccessAction = createAction(
  "[Contact] Delete Contact Config Success",
  props<{ id: string }>()
);
export const deleteContactConfigFailureAction = createAction(
  "[Contact] Delete Contact Config Failure",
  props<{ error: string }>()
);

export const postCommentInitAction = createAction(
  "[Contact] Post Comment Init"
);
export const postCommentSuccessAction = createAction(
  "[Contact] Post Comment Success",
  props<{ comment: Comment }>()
);
export const postCommentFailureAction = createAction(
  "[Contact] Post Comment Failure",
  props<{ error: string }>()
);

export const putCommentInitAction = createAction("[Contact] Put Comment Init");
export const putCommentSuccessAction = createAction(
  "[Contact] Put Comment Success",
  props<{ comment: Comment }>()
);
export const putCommentFailureAction = createAction(
  "[Contact] Put Comment Failure",
  props<{ error: string }>()
);

export const deleteCommentInitAction = createAction(
  "[Contact] Delete Comment Init"
);
export const deleteCommentSuccessAction = createAction(
  "[Contact] Delete Comment Success",
  props<{ id: string }>()
);
export const deleteCommentFailureAction = createAction(
  "[Contact] Delete Comment Failure",
  props<{ error: string }>()
);

export const getExportCompaniesInitAction = createAction(
  "[Contact] Get Export Companies Init"
);
export const getExportCompaniesSuccessAction = createAction(
  "[Contact] Get Export Companies Success"
);
export const getExportCompaniesFailureAction = createAction(
  "[Contact] Get Export Companies Failure",
  props<{ error: string }>()
);

export const postMassAssignmentInitAction = createAction(
  "[Contact] Post Mass Assignment Init"
);
export const postMassAssignmentSuccessAction = createAction(
  "[Contact] Post Mass Assignment Success"
);
export const postMassAssignmentFailureAction = createAction(
  "[Contact] Post Mass Assignment Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  postContactInitAction,
  postContactSuccessAction,
  postContactFailureAction,
  putContactInitAction,
  putContactSuccessAction,
  putContactFailureAction,
  getContactsInitAction,
  getContactsSuccessAction,
  getContactsFailureAction,
  getExportContactsInitAction,
  getExportContactsSuccessAction,
  getExportContactsFailureAction,
  postImportContactsInitAction,
  postImportContactsSuccessAction,
  postImportContactsFailureAction,
  postContactTagInitAction,
  postContactTagSuccessAction,
  postContactTagFailureAction,
  putContactTagInitAction,
  putContactTagSuccessAction,
  putContactTagFailureAction,
  getContactTagsInitAction,
  getContactTagsSuccessAction,
  getContactTagsFailureAction,
  deleteContactTagInitAction,
  deleteContactTagSuccessAction,
  deleteContactTagFailureAction,
  postContactStateInitAction,
  postContactStateSuccessAction,
  postContactStateFailureAction,
  putContactStateInitAction,
  putContactStateSuccessAction,
  putContactStateFailureAction,
  getContactStatesInitAction,
  getContactStatesSuccessAction,
  getContactStatesFailureAction,
  deleteContactStateInitAction,
  deleteContactStateSuccessAction,
  deleteContactStateFailureAction,
  getCompaniesInitAction,
  getCompaniesSuccessAction,
  getCompaniesFailureAction,
  getCompanyInitAction,
  getCompanySuccessAction,
  getCompanyFailureAction,
  postCompanyInitAction,
  postCompanySuccessAction,
  postCompanyFailureAction,
  putCompanyInitAction,
  putCompanySuccessAction,
  putCompanyFailureAction,
  deleteCompanyInitAction,
  deleteCompanySuccessAction,
  deleteCompanyFailureAction,
  postContactCompanyInitAction,
  postContactCompanySuccessAction,
  postContactCompanyFailureAction,
  putContactCompanyInitAction,
  putContactCompanySuccessAction,
  putContactCompanyFailureAction,
  deleteContactCompanyInitAction,
  deleteContactCompanySuccessAction,
  deleteContactCompanyFailureAction,
  getContactInitAction,
  getContactSuccessAction,
  getContactFailureAction,
  getCustomFieldsInitAction,
  getCustomFieldsSuccessAction,
  getCustomFieldsFailureAction,
  postCustomFieldInitAction,
  postCustomFieldSuccessAction,
  postCustomFieldFailureAction,
  putCustomFieldInitAction,
  putCustomFieldSuccessAction,
  putCustomFieldFailureAction,
  deleteCustomFieldInitAction,
  deleteCustomFieldSuccessAction,
  deleteCustomFieldFailureAction,
  getContactConfigInitAction,
  getContactConfigSuccessAction,
  getContactConfigFailureAction,
  postContactConfigInitAction,
  postContactConfigSuccessAction,
  postContactConfigFailureAction,
  deleteContactConfigInitAction,
  deleteContactConfigSuccessAction,
  deleteContactConfigFailureAction,
  postCommentInitAction,
  postCommentSuccessAction,
  postCommentFailureAction,
  deleteCommentInitAction,
  deleteCommentSuccessAction,
  deleteCommentFailureAction,
  putCommentInitAction,
  putCommentSuccessAction,
  putCommentFailureAction,
  getExportCompaniesInitAction,
  getExportCompaniesSuccessAction,
  getExportCompaniesFailureAction,
  postMassAssignmentInitAction,
  postMassAssignmentSuccessAction,
  postMassAssignmentFailureAction,
  getNewContactsCountSuccessAction,
  getNewCompaniesCountSuccessAction,
});

export type ContactApiAction = typeof actions;
