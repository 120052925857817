import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useMemo } from "react";
import { BoardListCard } from "../../../cores/boards/interfaces/api/get-board";
import { useBoardCore } from "../../../cores/boards";
import Avatar from "../Avatar";
import { TooltipWembii } from "../TooltipWembii";
import { UserStatus } from "../../../cores/user/constants/user-status";
import { Badge } from "reactstrap";
import { COLOR_DANGER } from "../../../constants/theme";
import { getFirstLetters } from "../../utils/stringUtils";
import { Link } from "react-router-dom";
import { useGlobalCore } from "../../../cores/globals";
import { CONTACT_COMPANY_MODAL } from "../../../constants/modal.constant";

interface Props {
  card: BoardListCard;
}

export default function OpportunityCard(props: Props) {
  const { card } = props;
  const { turnOffModal } = useGlobalCore();

  const contactsAssigned = useMemo(() => {
    if (card.companies.length > 0) {
      return card.contacts.slice(0, 1);
    } else {
      return card.contacts.slice(0, 2);
    }
  }, [card.companies, card.contacts]);

  const contactsRemainingQuantity = useMemo(() => {
    if (card.companies.length > 0) {
      return card.contacts.length - 1;
    } else {
      return card.contacts.length - 2;
    }
  }, [card.companies, card.contacts]);

  const companiesAssigned = useMemo(() => {
    if (card.contacts.length > 0) {
      return card.companies.slice(0, 1);
    } else {
      return card.companies.slice(0, 2);
    }
  }, [card.companies, card.contacts]);

  const companiesRemainingQuantity = useMemo(() => {
    if (card.contacts.length > 0) {
      return card.companies.length - 1;
    } else {
      return card.companies.length - 2;
    }
  }, [card.companies, card.contacts]);

  const totalRemainingQuantity = useMemo(() => {
    let quantity =
      companiesRemainingQuantity > 0 ? companiesRemainingQuantity : 0;
    quantity =
      contactsRemainingQuantity > 0
        ? contactsRemainingQuantity + quantity
        : quantity;
    return quantity;
  }, [contactsRemainingQuantity, companiesRemainingQuantity]);

  const assigned = useMemo(() => {
    return card.assignments.length === 1 ? card.assignments[0] : null;
  }, [card.assignments]);

  const assigmentNames = useMemo(() => {
    return card.assignments
      .map((assignment) => `${assignment.firstName} ${assignment.lastName}`)
      .join(" - ");
  }, [card.assignments]);

  return (
    <Link
      onClick={() => turnOffModal(CONTACT_COMPANY_MODAL)}
      to="/boards/business"
      className={cn(
        "card card-panel px-3 py-2 pointer mb-2 border rounded my-2"
      )}
    >
      <div className="d-flex flex-row justify-content-between">
        <div className="d-flex flex-column justify-content-between">
          <div className="d-flex flex-row flex-wrap align-items-center">
            {card.tags.map((tag) => (
              <Badge
                pill
                style={{ backgroundColor: tag.backgroundColor }}
                className={cn("text-left px-2 my-1 mr-1 pointer", {
                  labelsExpanded: true,
                })}
                key={tag.id}
              >
                <span
                  className="h6 mb-0 font-weight-bold"
                  style={{ color: tag.textColor, fontSize: 11.2 }}
                >
                  {tag.name}
                </span>
              </Badge>
            ))}
          </div>
          <div
            className="overflow-hidden font-weight-bold"
            style={{ fontSize: "1.1rem" }}
          >
            {card.title}
          </div>

          {card.contacts.length > 0 || card.companies.length > 0 ? (
            <div>
              <div className="d-flex flex-wrap">
                {contactsAssigned.map((contact) => (
                  <div
                    key={contact.id}
                    style={{ maxHeight: 24, maxWidth: 190 }}
                    className="truncate-label my-1 text-dark-50 font-weight-bold pointer px-2 border border-warning rounded-pill position-relative"
                  >
                    {contact.status !== UserStatus.ACTIVE.id && (
                      <FontAwesomeIcon
                        icon={["fas", "ban"]}
                        size="1x"
                        style={{
                          top: "-5px",
                          right: "-5px",
                        }}
                        className="position-absolute "
                        color={COLOR_DANGER}
                      />
                    )}
                    {contact.name}
                  </div>
                ))}
                {companiesAssigned.map((company) => (
                  <div
                    key={company.id}
                    style={{ maxHeight: 24, maxWidth: 190 }}
                    className="truncate-label my-1 text-dark-50 font-weight-bold pointer px-2 border border-primary rounded-pill"
                  >
                    {company.name}
                  </div>
                ))}
                {totalRemainingQuantity > 0 && (
                  <div className="text-dark-50 font-weight-bold pointer m-1 px-2">
                    {`+${totalRemainingQuantity}`}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="d-flex flex-column justify-content-between">
          {assigned && (
            <>
              <Avatar
                id={`user-${assigned.id}-${card.id}`}
                active={assigned.status === UserStatus.ACTIVE.id}
                className="text-primary font-weight-bold m-1"
                label={getFirstLetters(
                  `${assigned.lastName} ${assigned.firstName}`
                )}
                image={assigned.avatarUrl}
              />
              <TooltipWembii
                id={`user-${assigned.id}-${card.id}`}
                text={assigmentNames}
              />
            </>
          )}

          {card.assignments.length > 1 && (
            <>
              <Avatar
                id="multiple-assignment"
                className="text-primary font-weight-bold m-1"
                label={`${card.assignments.length}`}
              />
              <TooltipWembii id="multiple-assignment" text={assigmentNames} />
            </>
          )}
        </div>
      </div>
    </Link>
  );
}
