import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { IconProps } from "../Facebook/FacebookIcon";

export const TiktokIcon = (props: IconProps) => {
  const {  className, size = "1x", color } = props;

  return (
    <FontAwesomeIcon
      style={{
        color: color || "gray",
      }}
      icon={["fab", "tiktok"]}
      className={className}
      size={size}
    />
  );
};
