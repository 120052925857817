import { Dictionary } from "lodash";
import { SOCIAL_POST_RULES as facebookRules } from "../facebook/config";
import { SOCIAL_POST_RULES as instagramRules } from "../instagram/config";
import { SOCIAL_POST_RULES as linkedInRules } from "../linkedIn/config";
import { SOCIAL_POST_RULES as linkedInBusinessRules } from "../linkedInBusiness/config";
import { SOCIAL_POST_RULES as twitterRules } from "../twitter/config";
import { SOCIAL_POST_RULES as tiktokRules } from "../tiktok/config";
import { SocialPostRules } from "./interfaces/postError";

export enum PickerMode {
  Off = 0,
  Emoji = 1,
  HashTag = 2,
  Image = 3,
  Video = 4,
  Mention = 5,
}

export enum SocialProviderName {
  Facebook = "Facebook",
  TikTok = "TikTok",
  FacebookAds = "Facebook Ads",
  Instagram = "Instagram",
  Twitter = "Twitter",
  LinkedIn = "LinkedIn",
  LinkedInBusiness = "LinkedIn Business",
  Google = "Google Analytics",
  Gmail = "Gmail",
  Office365 = "Office 365",
  GoogleCalendar = "Google Calendar",
  Smtp = "Smtp",
  WooCommerce = "WooCommerce",
  Mercadolibre = "Mercadolibre",
  Jumpseller = "Jumpseller",
  Mailchimp = "Mailchimp",
  Whatsapp = "Whatsapp",
  InstagramMessenger = "InstagramMessenger",
  OpenAi = "OpenAi",
}

export const SocialProviderNameMapper: Dictionary<string> = {
  facebook: "Facebook",
  facebookAds: "Facebook Ads",
  instagram: "Instagram",
  instagramMensseger: "Instagram Messenger",
  twitter: "Twitter",
  linkedIn: "LinkedIn",
  linkedInBusiness: "LinkedIn Business",
  google: "Google Analytics",
  googleCalendar: "Google Calendar",
  gmail: "Gmail",
  smtp: "Smtp",
  wooCommerce: "WooCommerce",
  mercadolibre: "Mercadolibre",
  jumpseller: "Jumpseller",
  mailchimp: "Mailchimp",
  whatsapp: "Whatsapp",
  openai: "OpenAi",
  tiktok: "TikTok",
};

export enum SocialProviderId {
  Facebook = "Facebook",
  FacebookAds = "FacebookAds",
  Instagram = "Instagram",
  InstagramMessenger = "InstagramMessenger",
  Twitter = "Twitter",
  LinkedIn = "LinkedIn",
  LinkedInBusiness = "LinkedInBusiness",
  Google = "Google",
  GoogleAds = "GoogleAds",
  GoogleCalendar = "GoogleCalendar",
  Gmail = "Gmail",
  Office365 = "Office365",
  Smtp = "Smtp",
  WooCommerce = "WooCommerce",
  Mercadolibre = "Mercadolibre",
  Jumpseller = "Jumpseller",
  Mailchimp = "Mailchimp",
  Whatsapp = "Whatsapp",
  OpenAI = "OpenAI",
  OpenAi = "OpenAi",
  TikTok = "TikTok",
}

export const SocialProviderRules: Dictionary<SocialPostRules> = {
  facebook: facebookRules,
  twitter: twitterRules,
  linkedIn: linkedInRules,
  linkedInBusiness: linkedInBusinessRules,
  instagram: instagramRules,
  tiktok: tiktokRules,
};

export const SocialProviders = [
  "Facebook",
  "Facebook Ads",
  "Instagram",
  "Instagram Messenger",
  "Twitter",
  "Linked In",
  "Linked In Business",
  "Google Analytics",
  "Google Calendar",
  "Gmail",
  "Smtp",
  "WooCommerce",
  "Jumpseller",
  "TikTok"
];

export enum SocialPostStatus {
  Waiting = 0,
  Publish = 1,
  Failed = 2,
  Reported = 3,
}

export const SocialPostStatusOptions = [
  { label: "En Espera", value: "0" },
  { label: "Publicado", value: "1" },
  { label: "Fallado", value: "2" },
  { label: "Reportado", value: "3" },
  { label: "Repost", value: "4" },
];

export const SocialStoryStatusOptions = [
  { label: "En Espera", value: "0" },
  { label: "Enviado", value: "1" },
  { label: "Fallado", value: "2" },
];

export const SocialTargetOptions = [
  { label: "Post", value: "post" },
  { label: "Story", value: "story" },
];
