import socialApiClient from "../../helpers/services/social-api-client";
import { Media } from "../../socialPost/interfaces/api/get-social-posts";
import { Mentions } from "../../socialPost/interfaces/Mention";
import { PostImage } from "../../socialPost/interfaces/postImage";
import { PostVideo } from "../../socialPost/interfaces/postVideo";
import { blobFromDataURI } from "../../utils";
import { GetTiktokBindResponse } from "../interface/api/get-tiktok-bind";
import { GetTiktokLoginResponse } from "../interface/api/get-tiktok-login";
import { GetTiktokProfilesResponse } from "../interface/api/get-tiktok-profiles";
import { PostTiktokBindRequest, PostTiktokBindResponse } from "../interface/api/post-tiktok-bind";
import { PostSocialPostResponse } from "../interface/api/post-tiktok-socialPost";
import { PutSocialPostResponse } from "../interface/api/put-tiktok-socialPost";
import { tiktokSettingMapper } from "./mapper";


export const tiktokApiService = {
  unbind(companyId: string) {
    return socialApiClient()
      .post(`tiktok/unbind`, {}, { params: { companyId } })
      .then(({ data }) => data);
  },
  bind(companyId: string, body: PostTiktokBindRequest) {
    return socialApiClient()
      .post<PostTiktokBindResponse>(`tiktok/bind`, body, {
        params: { companyId },
      })
      .then(({ data }) =>
        tiktokSettingMapper.mapResponseToTiktokSetting(data)
      );
  },
  get(companyId: string) {
    return socialApiClient()
      .get<GetTiktokBindResponse>(`tiktok`, {
        params: { companyId },
      })
      .then(({ data }) => tiktokSettingMapper.mapResponseToTiktokSetting(data));
  },
  login(companyId: string) {
    return socialApiClient()
      .get<GetTiktokLoginResponse>(`tiktok/login`, {
        params: { companyId },
      })
      .then(({ data }) =>
        tiktokSettingMapper.mapResponseToTiktokLoginUrl(data)
      );
  },
  getProfiles(companyId: string, name: string) {
    return socialApiClient()
      .get<GetTiktokProfilesResponse>(`tiktok/profiles/${name}`, {
        params: { companyId },
      })
      .then(({ data }) =>
        tiktokSettingMapper.mapResponseToTiktokProfiles(data)
      );
  },
  socialPost(
    text: string,
    mentions: Mentions[],
    images: PostImage[],
    scheduleDate: string,
    repostId: string,
    companyId: string
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append("text", text);
    bodyFormData.append("repostId", repostId);
    bodyFormData.append("mentions", JSON.stringify(mentions));
    images.map((image) =>
      bodyFormData.append("images", blobFromDataURI(image.data))
    );
    scheduleDate && bodyFormData.append("scheduleDate", scheduleDate);
    return socialApiClient()
      .post<PostSocialPostResponse>(`tiktok/socialpost`, bodyFormData, {
        params: { companyId },
      })
      .then(({ data }) => data);
  },
  socialPostVideo(
    text: string,
    mentions: Mentions[],
    video: PostVideo,
    scheduleDate: string,
    repostId: string,
    companyId: string
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append("text", text);
    bodyFormData.append("repostId", repostId);
    bodyFormData.append("mentions", JSON.stringify(mentions));
    bodyFormData.append("video", blobFromDataURI(video.data));
    scheduleDate && bodyFormData.append("scheduleDate", scheduleDate);
    return socialApiClient()
      .post<PostSocialPostResponse>(`tiktok/socialpost/video`, bodyFormData, {
        params: { companyId },
      })
      .then(({ data }) => data);
  },
  putSocialPost(
    id: string,
    text: string,
    mentions: Mentions[],
    images: PostImage[],
    scheduleDate: string,
    companyId: string,
    media: Media[]
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append("text", text);
    bodyFormData.append("mentions", JSON.stringify(mentions));
    bodyFormData.append("media", JSON.stringify(media));
    images.map((image) =>
      bodyFormData.append("images", blobFromDataURI(image.data))
    );
    scheduleDate && bodyFormData.append("scheduleDate", scheduleDate);
    return socialApiClient()
      .put<PutSocialPostResponse>(`tiktok/socialpost/${id}`, bodyFormData, {
        params: { companyId },
      })
      .then(({ data }) => data);
  },
  putSocialPostVideo(
    id: string,
    text: string,
    mentions: Mentions[],
    video: PostVideo | null,
    scheduleDate: string,
    companyId: string,
    media: Media[]
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append("text", text);
    bodyFormData.append("mentions", JSON.stringify(mentions));
    bodyFormData.append("media", JSON.stringify(media));
    video && bodyFormData.append("video", blobFromDataURI(video.data));
    scheduleDate && bodyFormData.append("scheduleDate", scheduleDate);
    return socialApiClient()
      .put<PutSocialPostResponse>(
        `tiktok/socialpost/video/${id}`,
        bodyFormData,
        {
          params: { companyId },
        }
      )
      .then(({ data }) => data);
  },
};
