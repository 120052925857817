import "bootstrap/dist/css/bootstrap.min.css";
import { ConnectedRouter } from "connected-react-router";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Route } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./index.scss";
import { history, persistor, store } from "./redux/store";
import LocaleWrapper from "./LocaleWrapper";
import ServerStatusComponent from "./hooks/useServerStatus/useServerStatus.hook";
import { ConnectionIssuePage } from "./ui/screen/ConnectionIssue";
import { CheckingConnection } from "./ui/screen/CheckingConnection";

ReactDOM.render(
  <>
    <Provider store={store}>
      <ServerStatusComponent
        url={`${process.env.REACT_APP_MAIN_URL}/status`}
        renderLoading={<CheckingConnection />}
        renderOffline={<ConnectionIssuePage />}
        renderOnline={
          <ConnectedRouter history={history}>
            <PersistGate loading={null} persistor={persistor}>
              <LocaleWrapper>
                <Route path="/" component={App} />
              </LocaleWrapper>
            </PersistGate>
          </ConnectedRouter>
        }
      />
    </Provider>
  </>,
  document.getElementById("root")
);
