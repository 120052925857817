import React, { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useCompanyCore } from "../../../../cores/company";
import { useGlobalCore } from "../../../../cores/globals";
import { useMeCore } from "../../../../cores/me";
import { useTiktokCore } from "../../../../cores/tiktok";
import { Button } from "reactstrap";
import _ from "lodash";

import { daysRemainingFromToday } from "../../../utils/dateUtils";

const TiktokAuthButton = () => {
  const intl = useIntl();
  const { companyRole, companyPlan, getMeSocialSettings } = useMeCore();
  const { get, settings, unbind, login } = useTiktokCore();
  const { company } = useMeCore();
  const { getCompanyIntegration } = useCompanyCore();
  const { isLoading } = useGlobalCore();
  useEffect(() => {
    get(company!.id);
  }, [company, get, login]);

  const remainingBindDays = useMemo(
    () =>
      settings?.expiresIn ? daysRemainingFromToday(settings?.expiresIn) : null,
    [settings]
  );

  const isActive = useMemo((): boolean => !_.isEmpty(settings), [settings]);

  const handleBind = useCallback(async () => {
    const loginUrl = await login(company!.id);
    if (loginUrl) window.open(loginUrl, "_self");
  }, [company, login]);

  const handleUnbind = useCallback(() => {
    unbind(company!.id, () => {
      getMeSocialSettings(company?.id!);
    });
  }, [company, unbind, getMeSocialSettings]);

  return (
    <Button
      className="w-100 mx-auto"
      color="primary"
      outline={isActive}
      onClick={!isActive ? handleBind : handleUnbind}
    >
      {!isActive
        ? intl.formatMessage({ id: "app.home.syncUp" })
        : intl.formatMessage({ id: "app.home.disconnect" })}
    </Button>
  );
};

export default TiktokAuthButton;
