import cn from "classnames";
import { writeFileXLSX, utils } from "xlsx";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Badge,
  Button,
  ButtonGroup,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { useBoardCore } from "../../cores/boards";
import Content from "../layout/Content";
import { Header2 } from "../layout/Header2";
import Layout from "../layout/Layout";
import { useMeCore } from "../../cores/me";
import BoardPanel from "../component/boards/Board";
import SideModal from "../layout/SideModal";
import {
  BOARD_CREATE_CONFIG,
  BOARD_FILTERS_MODAL,
  BOARD_EDIT_CONFIG,
  BOARD_CUSTOM_FIELDS_FORM,
  MOVE_CARDS_MODAL,
  MORE_INFO_CRM,
  BOARD_CARD_MODAL,
  BOARD_IMPORT,
  BOARD_ROBOT_INACTIVITY,
  CREATE_AUTOMATION_INACTIVITY,
  OPEN_USER_ASIGNAMENT_MODAL,
  BOARD_LIST_RESTRICTIONS,
} from "../../constants/modal.constant";
import { useGlobalCore } from "../../cores/globals";
import { useRouterCore } from "../../cores/router";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import {
  BOARD_CARD_LOADING,
  BOARD_EXPORT_LOADING,
  BOARD_LOADING,
} from "../../constants/loading.constant";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCurrencySymbol } from "../../cores/boards/constants/currencies.constant";
import { CL$Format } from "../../cores/utils";
import BoardSelector from "../component/boards/BoardSelector";
import {
  CARD_PRODUCT,
  CUSTOM_FIELD,
} from "../../constants/internalModal.constant";
import FiltersForm from "../component/boards/modal/FiltersForm";
import { CSVLink } from "react-csv";
import moment from "moment";
import { CardStatusLabels } from "../../cores/boards/constants/card-type";
import { InputWembii } from "../component/InputWembii";
import _ from "lodash";
import { NoData } from "../component/NoData";
import { BoardType } from "../../cores/boards/constants/board-type.constant";
import UserBoardContainer from "../component/boards/creator/UserBoardContainer";
import CreateBoard from "../component/boards/modal/CreateBoard";
import CustomFields from "../component/boards/modal/CustomFields";
import { useEventCore } from "../../cores/event";
import TaskSideModals from "../component/tasks/TaskSideModals";
import ContactSideModals from "../component/contacts/ContactSideModals";
import CompanySideModals from "../component/contactCompanies/CompanySideModals";
import BoardSideModals from "../component/boards/BoardSideModals";
import EventSideModals from "../component/event/EventSideModals";
import EmailSideModals from "../component/gmail/EmailSideModals";
import { ProductBoard } from "../../cores/boards/interfaces/api/get-card";
import { TooltipWembii } from "../component/TooltipWembii";
import { Position } from "../../cores/globals/interfaces/globalEntity";
import { YoutubeModal } from "../component/YoutubeModal";
import { Errors } from "../../constants/errors";
import CardDetails from "../component/boards/modal/CardDetails";
import Import from "../component/boards/modal/Import";
import { LoadingSpinner } from "../component/LoadingSpinner";
import CreateInactivity from "../component/boards/modal/CreateInactivity";
import { AUTOMATION_CONTACTS_MODAL } from "../../constants/modal.constant";
import Inactivities from "../component/boards/modal/Inactivities";
import Restrictions from "../component/boards/modal/Restrictions";

export const BusinessBoardScreen = () => {
  const intl = useIntl();
  const { company, companyPlan, companyRole, isAdmin, isReseller, user } =
    useMeCore();
  const { getCalendar } = useEventCore();
  const {
    getBusinessBoard,
    getBusinessBoards,
    getBoardExport,
    getFilters,
    setFilters,
    putBoard,
    getCustomFields,
    cleanCard,
    errorCode,
    board: updateBoard,
    boardBusiness: board,
    boards,
    filters,
    lists,
    customFields,
  } = useBoardCore();
  const { goToSocial } = useRouterCore();
  const {
    isModal,
    isLoading,
    turnOnModal,
    turnOffModal,
    getModalContent,
    getModalVisibility,
    turnOffInternalModal,
  } = useGlobalCore();

  const [search, setSearch] = useState("");
  const [exportData, setExportData] = useState<any[]>([]);
  const [headers, setHeaders] = useState<{ label: string; key: string }[]>([]);

  const ref = useRef<any>(null);

  const filter = useMemo(() => {
    const filter = getFilters(board?.id!);
    return filter;
  }, [board, getFilters]);

  const isFiltered = useMemo(() => {
    return filters[`${board?.id!}`];
  }, [filters, board]);

  useEffect(() => {
    if (
      !companyPlan?.rules.allowBusinessBoard ||
      (!companyRole?.crm &&
        !companyRole?.owner &&
        !companyRole?.admin &&
        !companyRole?.agent &&
        !isAdmin &&
        !isReseller)
    )
      goToSocial();
  }, [companyPlan, companyRole, isAdmin, isReseller, goToSocial]);

  const canModify = useMemo(
    () =>
      isAdmin ||
      isReseller ||
      companyRole?.owner ||
      companyRole?.admin ||
      board?.userRoles.find((role) => role.user.id === user?.id)?.owner,
    [isAdmin, isReseller, companyRole, board, user]
  );

  useEffect(() => {
    getBusinessBoards(company?.id!);
    getCalendar(company?.id!);
    if (board) {
      getCustomFields(company?.id!, board?.id!);
    }
  }, [getBusinessBoards, getCustomFields, getCalendar, company, board]);

  const anyAutomations = lists.reduce((currentArray, mainElement) => {
    let newAcc = 0;
    if (mainElement.inactivity?.notify) newAcc++;

    return currentArray + newAcc;
  }, 0);
  const toggleFilterModal = useCallback(() => {
    turnOnModal(
      BOARD_FILTERS_MODAL,
      <FiltersForm
        board={board}
        onFilter={(filters, onSuccess) => {
          getBusinessBoard(company?.id!, board?.id!, filters, true, onSuccess);
        }}
        onClearFilter={() => {
          getBusinessBoard(company?.id!, board?.id!, {});
        }}
      />
    );
  }, [turnOnModal, getBusinessBoard, company, board]);

  const totalProbabilities = useMemo(() => {
    const probabilities = lists
      .filter(
        (list) =>
          list.addToProbability &&
          list.totalProbability &&
          Object.keys(list.totalProbability).length > 0
      )
      .map((list) => list.totalProbability);

    const currencies = probabilities.reduce(
      (prev: Record<string, number>, curr) => {
        Object.keys(curr).forEach((currency) => {
          if (prev[currency]) {
            prev[currency] += curr[currency];
          } else {
            prev[currency] = curr[currency];
          }
        });
        return prev;
      },
      {}
    );

    return Object.keys(currencies)
      .filter((currency) => currencies[currency] > 0)
      .map((currency) =>
        CL$Format(currencies[currency], "", getCurrencySymbol(currency))
      )
      .join(" + ");
  }, [lists]);

  const onSearching = useCallback(
    (companyId: string, boardId: string) => {
      const filter = getFilters(boardId);
      getBusinessBoard(companyId, boardId, filter);
    },
    [getBusinessBoard, getFilters]
  );

  const totalCards = useMemo(
    () => lists.reduce((prev, curr) => prev + curr.total, 0),
    [lists]
  );

  const canExport = useMemo(
    () => companyRole?.owner || companyRole?.admin || isAdmin,
    [companyRole, isAdmin]
  );

  const onClickExport = useCallback(
    async (extension: string) => {
      if (!isLoading(BOARD_EXPORT_LOADING)) {
        const opportunities = await getBoardExport(
          company?.id!,
          board?.id!,
          filter
        );
        if (opportunities) {
          const products = opportunities.cards
            .reduce((prev: ProductBoard[], curr) => {
              const products: ProductBoard[] = curr.products.filter(
                (product) =>
                  !prev.find((pdt) => product.product === pdt.product)
              );

              return [...prev, ...products];
            }, [])
            .reduce((prev: { label: string; key: string }[], curr) => {
              return [
                ...prev,
                { label: curr.name, key: curr.name },
                {
                  label: `${curr.name} (${intl.formatMessage({
                    id: "app.opportunity.currency",
                  })})`,
                  key: `${curr.name} (${intl.formatMessage({
                    id: "app.opportunity.currency",
                  })})`,
                },
              ];
            }, []);

          const fields = customFields
            .sort((fieldA, fieldB) => (fieldA.id > fieldB.id ? 0 : -1))
            .reduce((prev: { label: string; key: string }[], curr) => {
              const fields: { label: string; key: string }[] =
                curr.type === "money"
                  ? [
                    { label: curr.name, key: curr.name },
                    {
                      label: `${curr.name} (${intl.formatMessage({
                        id: "app.opportunity.currency",
                      })})`,
                      key: `${curr.name} (${intl.formatMessage({
                        id: "app.opportunity.currency",
                      })})`,
                    },
                  ]
                  : [{ label: curr.name, key: curr.name }];

              return [...prev, ...fields];
            }, []);

          setHeaders([
            {
              label: intl.formatMessage({ id: "app.opportunity.title" }),
              key: intl.formatMessage({ id: "app.opportunity.title" }),
            },
            {
              label: intl.formatMessage({ id: "app.opportunity.description" }),
              key: intl.formatMessage({ id: "app.opportunity.description" }),
            },
            {
              label: intl.formatMessage({ id: "app.opportunity.stage" }),
              key: intl.formatMessage({ id: "app.opportunity.stage" }),
            },
            {
              label: intl.formatMessage({ id: "app.opportunity.value" }),
              key: intl.formatMessage({ id: "app.opportunity.value" }),
            },
            {
              label: intl.formatMessage({ id: "app.opportunity.currency" }),
              key: intl.formatMessage({ id: "app.opportunity.currency" }),
            },
            {
              label: intl.formatMessage({ id: "app.opportunity.status" }),
              key: intl.formatMessage({ id: "app.opportunity.status" }),
            },
            {
              label: intl.formatMessage({ id: "app.opportunity.endDate" }),
              key: intl.formatMessage({ id: "app.opportunity.endDate" }),
            },
            {
              label: intl.formatMessage({ id: "app.opportunity.internalId" }),
              key: intl.formatMessage({ id: "app.opportunity.internalId" }),
            },
            {
              label: intl.formatMessage({ id: "app.opportunity.contacts" }),
              key: intl.formatMessage({ id: "app.opportunity.contacts" }),
            },
            {
              label: intl.formatMessage({
                id: "app.opportunity.organizations",
              }),
              key: intl.formatMessage({ id: "app.opportunity.organizations" }),
            },
            {
              label: intl.formatMessage({ id: "app.opportunity.tags" }),
              key: intl.formatMessage({ id: "app.opportunity.tags" }),
            },
            {
              label: intl.formatMessage({ id: "app.opportunity.assignments" }),
              key: intl.formatMessage({ id: "app.opportunity.assignments" }),
            },
            {
              label: intl.formatMessage({ id: "app.contacts.createdAt" }),
              key: intl.formatMessage({ id: "app.contacts.createdAt" }),
            },
            ...fields,
            ...products,
          ]);
          const cards = opportunities.cards.map((card) => ({
            [intl.formatMessage({ id: "app.opportunity.title" })]: card.title,
            [intl.formatMessage({ id: "app.opportunity.description" })]:
              card.description,
            [intl.formatMessage({
              id: "app.opportunity.stage",
            })]: `${card.list.name} (${card.list.percentage}%)`,
            [intl.formatMessage({ id: "app.opportunity.value" })]: card.budget,
            [intl.formatMessage({ id: "app.opportunity.currency" })]:
              card.currency,
            [intl.formatMessage({ id: "app.opportunity.status" })]:
              intl.formatMessage({
                id:
                  CardStatusLabels[card.status] ||
                  "app.opportunity.cardStatus.oppened",
              }),
            [intl.formatMessage({ id: "app.opportunity.endDate" })]:
              card.endDate,
            [intl.formatMessage({ id: "app.opportunity.internalId" })]:
              card.internalId,
            [intl.formatMessage({ id: "app.opportunity.contacts" })]:
              card.contacts.join(", "),
            [intl.formatMessage({ id: "app.opportunity.organizations" })]:
              card.companies.join(", "),
            [intl.formatMessage({ id: "app.opportunity.tags" })]:
              card.tags.join(", "),
            [intl.formatMessage({ id: "app.opportunity.assignments" })]:
              card.assignments.join(", "),
            [intl.formatMessage({ id: "app.contacts.createdAt" })]:
              card.createdAt,
            ...card.customFields.reduce((prev, curr) => {
              if (curr.type === "money") {
                return {
                  ...prev,
                  [curr.name]: +curr.value,
                  [`${curr.name} (${intl.formatMessage({
                    id: "app.opportunity.currency",
                  })})`]: curr.unit,
                };
              } else {
                return {
                  ...prev,
                  [curr.name]:
                    curr.type === "date"
                      ? moment(curr.value).format("DD/MM/YYYY")
                      : curr.value,
                };
              }
            }, {}),
            ...card.products.reduce(
              (prev, curr) => ({
                ...prev,
                [curr.name]:
                  curr.price * curr.quantity -
                  (curr.price * curr.quantity * curr.discount) / 100,
                [`${curr.name} (${intl.formatMessage({
                  id: "app.opportunity.currency",
                })})`]: card.currency,
              }),
              {}
            ),
          }));

          if (extension === "csv") {
            setExportData(cards);
            ref.current.link.click();
          } else {
            const wb = utils.book_new();
            const ws = utils.json_to_sheet(cards);

            utils.book_append_sheet(wb, ws);
            writeFileXLSX(
              wb,
              `${board?.name}_${moment().format("DD-MM-YYYY")}.xlsx`
            );
          }
        }
      }
    },
    [getBoardExport, isLoading, customFields, board, filter, company, intl]
  );

  const onClickCSVExport = useCallback(() => {
    onClickExport("csv");
  }, [onClickExport]);

  const onClickExcelExport = useCallback(() => {
    onClickExport("xlsx");
  }, [onClickExport]);

  const debouncedSearch = useRef(
    _.debounce((search) => setSearch(search), 1000)
  ).current;

  const setDebounceSearchText = useCallback(
    (value) => {
      const filter = getFilters(board?.id!);
      setFilters({
        id: board?.id!,
        ...filter,
        text: value,
      });
      debouncedSearch(value);
    },
    [board, debouncedSearch, getFilters, setFilters]
  );

  useEffect(() => {
    if (board) {
      const filter = getFilters(board?.id!);
      getBusinessBoard(company?.id!, board?.id!, filter, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, getBusinessBoard, search]);

  const onCreating = useCallback(() => {
    turnOnModal(BOARD_CREATE_CONFIG, <CreateBoard type={BoardType.BUSINESS} />);
  }, [turnOnModal]);

  const onClickCustomFields = useCallback(() => {
    getCustomFields(company?.id!, board?.id!);
    turnOnModal(BOARD_CUSTOM_FIELDS_FORM, true);
  }, [turnOnModal, getCustomFields, company, board]);

  const openMoreInfo = useCallback(() => {
    turnOnModal(MORE_INFO_CRM, true, Position.LEFT);
  }, [turnOnModal]);

  const onClickImport = useCallback(
    () => turnOnModal(BOARD_IMPORT, true),
    [turnOnModal]
  );
  const onClickRobot = useCallback(
    () => turnOnModal(BOARD_ROBOT_INACTIVITY, true, Position.RIGHT),
    [turnOnModal]
  );
  const canAutomate =
    isAdmin || isReseller || companyRole?.owner || companyRole?.admin;

  return (
    <Layout>
      <Header2
        className="d-flex"
        topRow={
          board && (
            <div className="d-none d-md-flex my-auto mx-4">
              <UserBoardContainer board={board} />
            </div>
          )
        }
        title={`${intl.formatMessage({ id: "app.sidebar.crm.business" })} ${board ? `(${totalCards})` : ""
          }`}
        mb={false}
        rightChildren={
          boards.length !== 0 && (
            <div className="d-flex-col  d-md-flex flex-wrap align-items-center justify-content-center">
              <div className="d-flex d-md-none justify-content-between">
                <div>{board && <UserBoardContainer board={board} />}</div>
              </div>
              {canAutomate && (
                <FontAwesomeIcon
                  icon={["fas", "robot"]}
                  size="2x"
                  className={cn("d-none d-md-flex pointer m-2 ml-2", {
                    "text-primary": anyAutomations > 0,
                  })}
                  color="gray"
                  onClick={onClickRobot}
                />
              )}
              <FontAwesomeIcon
                icon={["fas", "info-circle"]}
                size="2x"
                className="pointer m-2 ml-2 d-none d-md-flex"
                color="gray"
                id="info-SocialPost"
              />
              <FontAwesomeIcon
                icon={["fas", "video"]}
                size="2x"
                className="pointer m-2 ml-2 d-none d-md-flex"
                onClick={openMoreInfo}
                color="gray"
              />
              <TooltipWembii
                id="info-SocialPost"
                text={intl.formatMessage({ id: "app.tooltipInfo.crm" })}
              />

              {board && totalProbabilities && (
                <Badge
                  className=" d-none d-md-flex  mr-2 py-2 px-3 text-white text-wrap"
                  color="success"
                >
                  {intl.formatMessage(
                    { id: "app.crmBusiness.totalProbability" },
                    { probability: totalProbabilities }
                  )}
                </Badge>
              )}
              {canExport && (
                <div className="d-none d-md-flex">
                  <CSVLink
                    className="d-none"
                    headers={headers}
                    filename={`${board?.name}_${moment().format(
                      "DD-MM-YYYY"
                    )}.csv`}
                    data={exportData}
                    ref={ref}
                  />
                  <ButtonGroup className="ml-2">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        caret
                        className="excelButton d-flex align-items-center"
                      >
                        <LoadingSpinner id={BOARD_EXPORT_LOADING} />
                        {intl.formatMessage({ id: "app.contacts.export" })}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={onClickCSVExport}>
                          <FontAwesomeIcon
                            icon={["fas", "file-csv"]}
                            size="1x"
                            className="mr-2"
                          />
                          CSV
                        </DropdownItem>
                        <DropdownItem onClick={onClickExcelExport}>
                          <FontAwesomeIcon
                            icon={["fas", "file-excel"]}
                            size="1x"
                            className="mr-2"
                          />
                          Excel
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </ButtonGroup>
                </div>
              )}
              <Button
                className="d-none d-md-flex excelButton ml-2"
                onClick={onClickImport}
              >
                <FontAwesomeIcon
                  icon={["fas", "file-csv"]}
                  size="1x"
                  className="mr-1"
                />
                {intl.formatMessage({ id: "app.contacts.import" })}
              </Button>
              <button
                className={cn("d-none d-md-flex ml-2 btn", {
                  whiteButton: !isFiltered,
                  "btn-primary": isFiltered,
                })}
                onClick={toggleFilterModal}
              >
                <FontAwesomeIcon
                  icon={["fas", "sliders-h"]}
                  className="d-none d-md-flex mr-2"
                />
                {intl.formatMessage({ id: "app.crmBusiness.filters" })}
              </button>
              {canModify && (
                <Button
                  className="d-none d-md-flex whiteButton ml-2"
                  onClick={onClickCustomFields}
                >
                  <FontAwesomeIcon icon={["fas", "gear"]} />
                </Button>
              )}
              <div className="d-flex d-none my-2" />
              <BoardSelector onSearching={onSearching} showOptions={true} />
              <div className="d-flex d-none my-2" />
            </div>
          )
        }
      />
      {isLoading(BOARD_LOADING) ? (
        <UpdateLoadingCard
          loadingId={BOARD_LOADING}
          text={intl.formatMessage({ id: "app.activity.updatingBoard2" })}
        />
      ) : (
        <Content>
          <div className="d-flex  px-4 mb-4 mx-auto my-auto w-100 ">
            <InputWembii
              type="text"
              className="w-100 mx-auto"
              placeholder={intl.formatMessage({
                id: "app.crmBusiness.filters.searchTxt",
              })}
              rounded
              mb={false}
              value={filter.text}
              onChange={setDebounceSearchText}
              icon={
                <FontAwesomeIcon
                  icon={["fas", "search"]}
                  size="1x"
                  color="grey"
                  className="pointer"
                />
              }
            />
          </div>
          <Container style={{ height: "100vh" }} fluid>
            {!boards.length ? (
              <NoData
                image="generic4"
                text={intl.formatMessage({
                  id: "app.crmBusiness.notHaveBoard",
                })}
                actionLabel={intl.formatMessage({
                  id: "app.activity.createBoard",
                })}
                action={onCreating}
                horizontal
              />
            ) : (
              <Row>
                <Col xs="12">
                  <BoardPanel board={board} />
                </Col>
              </Row>
            )}
          </Container>
        </Content>
      )}
      <SideModal
        active={isModal(BOARD_FILTERS_MODAL)}
        visible={getModalVisibility(BOARD_FILTERS_MODAL)}
        onClose={() => turnOffModal(BOARD_FILTERS_MODAL)}
      >
        {getModalContent(BOARD_FILTERS_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(BOARD_CREATE_CONFIG)}
        visible={getModalVisibility(BOARD_CREATE_CONFIG)}
        onClose={() => turnOffModal(BOARD_CREATE_CONFIG)}
      >
        {getModalContent(BOARD_CREATE_CONFIG)}
      </SideModal>
      <SideModal
        active={isModal(MOVE_CARDS_MODAL)}
        visible={getModalVisibility(MOVE_CARDS_MODAL)}
        onClose={() => turnOffModal(MOVE_CARDS_MODAL)}
      >
        {getModalContent(MOVE_CARDS_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(BOARD_EDIT_CONFIG)}
        visible={getModalVisibility(BOARD_EDIT_CONFIG)}
        onClose={() => {
          turnOffModal(BOARD_EDIT_CONFIG);
          if (updateBoard.isChanged) {
            putBoard(
              company?.id!,
              board?.id!,
              updateBoard.name,
              updateBoard.color,
              updateBoard.currency,
              updateBoard.roles
            );
          }
        }}
      >
        {getModalContent(BOARD_EDIT_CONFIG)}
      </SideModal>
      <SideModal
        active={isModal(BOARD_CUSTOM_FIELDS_FORM)}
        visible={getModalVisibility(BOARD_CUSTOM_FIELDS_FORM)}
        onClose={() => {
          turnOffModal(BOARD_CUSTOM_FIELDS_FORM);
          turnOffInternalModal(CUSTOM_FIELD);
        }}
      >
        <CustomFields board={board?.id!} />
      </SideModal>

      <TaskSideModals />
      <ContactSideModals />
      <CompanySideModals />
      <BoardSideModals />
      <EventSideModals />
      <EmailSideModals />

      <SideModal
        active={isModal(BOARD_CARD_MODAL)}
        visible={getModalVisibility(BOARD_CARD_MODAL)}
        onClose={() => {
          turnOffModal(BOARD_CARD_MODAL);
          turnOffInternalModal(CARD_PRODUCT);
          cleanCard();
        }}
        showSppiner={isLoading(BOARD_CARD_LOADING)}
        showError={errorCode === Errors.CardLoad}
      >
        <CardDetails
          board={board}
          onDeletion={() => {
            getBusinessBoard(company?.id!, board?.id!, filter, false);
          }}
        />
      </SideModal>
      <SideModal
        active={isModal(MORE_INFO_CRM)}
        visible={getModalVisibility(MORE_INFO_CRM)}
        onClose={() => turnOffModal(MORE_INFO_CRM)}
        left
      >
        <YoutubeModal
          title={intl.formatMessage({ id: "app.crmTask.video" })}
          videoId="sP9MtWL081E"
        />
      </SideModal>
      <SideModal
        active={isModal(BOARD_IMPORT)}
        visible={getModalVisibility(BOARD_IMPORT)}
        onClose={() => turnOffModal(BOARD_IMPORT)}
      >
        <Import />
      </SideModal>
      <SideModal
        active={isModal(BOARD_ROBOT_INACTIVITY)}
        visible={getModalVisibility(BOARD_ROBOT_INACTIVITY)}
        onClose={() => turnOffModal(BOARD_ROBOT_INACTIVITY)}
      >
        <Inactivities board={board!} />
      </SideModal>
      <SideModal
        active={isModal(BOARD_LIST_RESTRICTIONS)}
        visible={getModalVisibility(BOARD_LIST_RESTRICTIONS)}
        onClose={() => turnOffModal(BOARD_LIST_RESTRICTIONS)}
      >
        {getModalContent(BOARD_LIST_RESTRICTIONS)}
      </SideModal>

      <SideModal
        active={isModal(CREATE_AUTOMATION_INACTIVITY)}
        visible={getModalVisibility(CREATE_AUTOMATION_INACTIVITY)}
        onClose={() => turnOffModal(CREATE_AUTOMATION_INACTIVITY)}
      >
        <CreateInactivity boardId={board?.id!} />
      </SideModal>
      <SideModal
        active={isModal(OPEN_USER_ASIGNAMENT_MODAL)}
        visible={getModalVisibility(OPEN_USER_ASIGNAMENT_MODAL)}
        onClose={() => turnOffModal(OPEN_USER_ASIGNAMENT_MODAL)}
      >
        {getModalContent(OPEN_USER_ASIGNAMENT_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(AUTOMATION_CONTACTS_MODAL)}
        visible={getModalVisibility(AUTOMATION_CONTACTS_MODAL)}
        onClose={() => turnOffModal(AUTOMATION_CONTACTS_MODAL)}
        left
      >
        {getModalContent(AUTOMATION_CONTACTS_MODAL)}
      </SideModal>
    </Layout>
  );
};
