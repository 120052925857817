import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faTiktok,
  faLinkedin,
  faGoogle,
  faMailchimp,
  faWhatsapp,
  faGoogleDrive,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faBuilding,
  faBoxOpen,
  faSyncAlt,
  faShoppingCart,
  faInfoCircle,
  faUsers,
  faBullhorn,
  faRobot,
  faBriefcase,
} from "@fortawesome/free-solid-svg-icons";
import { PanelWembii } from "../../PanelWembii";
import SyncAccount from "../../SyncAccount/SyncAccount";
import { useFacebookCore } from "../../../../cores/facebook";
import { useInstagramCore } from "../../../../cores/instagram";
import { useLinkedInCore } from "../../../../cores/linkedIn";
import { useLinkedInBusinessCore } from "../../../../cores/linkedInBusiness";
import { useGoogleCore } from "../../../../cores/google";
import { useFacebookAdsCore } from "../../../../cores/facebookAds";
import { useGmailCore } from "../../../../cores/gmail";
import { useOffice365Core } from "../../../../cores/office365";
import { useSmtpCore } from "../../../../cores/smtp";
import { useWooCommerceCore } from "../../../../cores/wooCommerce";
import { useMercadolibreCore } from "../../../../cores/mercadolibre";
import { useJumpsellerCore } from "../../../../cores/jumpseller";
import { useMailchimpCore } from "../../../../cores/mailchimp";
import { useOpenAiCore } from "../../../../cores/openAi";
import { useWhatsappCore } from "../../../../cores/whatsapp";
import { useInstagramMenssegerCore } from "../../../../cores/instagramMensseger";
import { SocialProvider } from "../../../../cores/socialPost/interfaces/socialProvider";
import { SocialProviderId } from "../../../../cores/socialPost/config";
import { FacebookIcon } from "../Facebook/FacebookIcon";
import _ from "lodash";
import { FacebookAdsIcon } from "../FacebookAds/FacebookAdsIcon";
import { GmailIcon } from "../Gmail/GmailIcon";
import { InstagramIcon } from "../Instagram/InstagramIcon";
import { JumpsellerIcon } from "../Jumpseller/JumpsellerIcon";
import { LinkedInIcon } from "../LinkedIn/LinkedInIcon";
import { MailchimpIcon } from "../Mailchimp/MailchimpIcon";
import { MercadolibreIcon } from "../Mercadolibre/MercadolibreIcon";
import { Office365Icon } from "../Office365/Office365Icon";
import { OpenAiIcon } from "../OpenAi/OpenAiIcon";
import { SmtpIcon } from "../Smtp/SmtpIcon";
import { WhatsappIcon } from "../Whatsapp/WhatsappIcon";
import { WooCommerceIcon } from "../WooCommerce/WooCommerceIcon";
import { GooglenAnalyticsIcon } from "../GoogleAnalitycs/GoogleAnalitycsIcon";
import { InstagramMenssegerIcon } from "../InstagramMensseger/InstagramMenssegerIcon";
import FacebookAuthButton from "../Facebook/FacebookAuthButton";
import InstagramAuthButton from "../Instagram/InstagramBindCard";
import LinkedInAuthButton from "../LinkedIn/LinkedInBindCard";
import FacebookAdsAuthButton from "../FacebookAds/FacebookAdsBindCard";
import GmailAuthButton from "../Gmail/GmailBindCard";
import GoogleAnalyticsAuthButton from "../GoogleAnalitycs/GoogleBindCard";
import JumpSellerAuthButton from "../Jumpseller/JumpsellerBindCard";
import MailchimpAuthButton from "../Mailchimp/MailchimpBindCard";
import MercadolibreAuthButton from "../Mercadolibre/MercadolibreBindCard";
import Office365AuthButton from "../Office365/Office365BindCard";
import OpenAiAuthButton from "../OpenAi/OpenAiBindCard";
import SmtpAuthButton from "../Smtp/SmtpBindCard";
import WhatsappAuthButton from "../Whatsapp/WhatsappBindCard";
import WooCommerceAuthButton from "../WooCommerce/WooCommerceBindCard";
import { useGlobalCore } from "../../../../cores/globals";
import {
  FACEBOOK_ADS_SYNC_LOADING,
  FACEBOOK_SYNC_LOADING,
  GMAIL_SYNC_LOADING,
  GOOGLE_SYNC_LOADING,
  INSTAGRAM_MENSSEGER_SYNC_LOADING,
  INSTAGRAM_SYNC_LOADING,
  JUMPSELLER_SYNC_LOADING,
  LINKED_IN_BUSINESS_SYNC_LOADING,
  LINKED_IN_SYNC_LOADING,
  MAILCHIMP_SYNC_LOADING,
  MERCADOLIBRE_SYNC_LOADING,
  OFFICE365_ME_SYNC_LOADING,
  OFFICE365_SYNC_LOADING,
  OPENAI_SYNC_LOADING,
  SMTP_SYNC_LOADING,
  TIKTOK_SYNC_LOADING,
  WHATSAPP_SYNC_LOADING,
  WOO_COMMERCE_SYNC_LOADING,
} from "../../../../constants/loading.constant";
import { LoadingSpinnerWembii } from "../../LoadingSpinnerWembii";
import { daysRemainingFromToday } from "../../../utils/dateUtils";
import { LinkedInBusinessIcon } from "../LinkedIn/LinkedInBusinessIcon";
import TiktokAuthButton from "../Tiktok/TiktokBindCard";
import { useTiktokCore } from "../../../../cores/tiktok";
import { TiktokIcon } from "../Tiktok/TiktokIcon";
interface CombinedSettings {
  [key: string]: { expiresIn: string | null } | null;
}
const SocialTable = () => {
  const { anyLoading } = useGlobalCore();
  const { settings: facebookSettings } = useFacebookCore();
  const { settings: facebookAdsSettings } = useFacebookAdsCore();
  const { settings: instagramSettings } = useInstagramCore();
  const { settings: linkedinSettings } = useLinkedInCore();
  const { settings: tikTokSettings } = useTiktokCore();
  const { settings: linkedinBusinessSettings } = useLinkedInBusinessCore();
  const { settings: googleSettings } = useGoogleCore();
  const { settings: gmailSettings } = useGmailCore();
  const { settings: officeSettings } = useOffice365Core();
  const { settings: smtpSettings } = useSmtpCore();
  const { settings: wooSettings } = useWooCommerceCore();
  const { settings: meliSettings } = useMercadolibreCore();
  const { settings: jumpsellerSettings } = useJumpsellerCore();
  const { settings: mailchimpSettings } = useMailchimpCore();
  const { settings: whatsappSettings } = useWhatsappCore();
  const { settings: openAiSettings } = useOpenAiCore();
  const { settings: instagramMessengerSettings } = useInstagramMenssegerCore();

  const combinedSettings: CombinedSettings = {
    Facebook: facebookSettings,
    [SocialProviderId.FacebookAds]: facebookAdsSettings,
    [SocialProviderId.Instagram]: instagramSettings,
    [SocialProviderId.LinkedIn]: linkedinSettings,
    [SocialProviderId.LinkedInBusiness]: linkedinBusinessSettings,
    [SocialProviderId.Google]: googleSettings,
    [SocialProviderId.Gmail]: gmailSettings,
    [SocialProviderId.Office365]: officeSettings,
    [SocialProviderId.Jumpseller]: jumpsellerSettings,
    [SocialProviderId.InstagramMessenger]: instagramMessengerSettings,
    [SocialProviderId.TikTok]: tikTokSettings,
  };
  console.log({ combinedSettings });
  const [selectedNetwork, setSelectedNetwork] = useState<string>("");

  const [accounts, setAccounts] = useState<{
    [key: string]: SocialProvider[] | undefined | null;
  }>({});

  const isValidSettings = (settings: any) => {
    if (settings === undefined || settings === null) {
      return false;
    }

    if (
      _.isObject(settings) &&
      _.keys(settings).length === 1 &&
      (settings as any).type
    ) {
      return false;
    }

    return !_.isEmpty(settings);
  };

  const updateAccounts = (key: string, settings: any, icon: JSX.Element) => {
    return isValidSettings(settings)
      ? [
          {
            id: key,
            name: key,
            icon: icon,
            displayName: settings.name ?? "Cuenta no conectada",
            avatar: settings.avatarUrl,
          },
        ]
      : [];
  };
  const anyIsloading = anyLoading([
    FACEBOOK_ADS_SYNC_LOADING,
    LINKED_IN_BUSINESS_SYNC_LOADING,
    INSTAGRAM_MENSSEGER_SYNC_LOADING,
    INSTAGRAM_SYNC_LOADING,
    LINKED_IN_SYNC_LOADING,
    FACEBOOK_SYNC_LOADING,
    FACEBOOK_ADS_SYNC_LOADING,
    GOOGLE_SYNC_LOADING,
    GMAIL_SYNC_LOADING,
    OFFICE365_SYNC_LOADING,
    OFFICE365_ME_SYNC_LOADING,
    SMTP_SYNC_LOADING,
    WHATSAPP_SYNC_LOADING,
    GMAIL_SYNC_LOADING,
    JUMPSELLER_SYNC_LOADING,
    MAILCHIMP_SYNC_LOADING,
    OPENAI_SYNC_LOADING,
    WOO_COMMERCE_SYNC_LOADING,
    MERCADOLIBRE_SYNC_LOADING,
    TIKTOK_SYNC_LOADING,
  ]);
  useEffect(() => {
    setAccounts((prevAccounts) => ({
      ...prevAccounts,
      Facebook: updateAccounts(
        SocialProviderId.Facebook,
        facebookSettings,
        <FacebookIcon size="5x" active={!_.isEmpty(facebookSettings)} />
      ),
      Instagram: updateAccounts(
        SocialProviderId.Instagram,
        instagramSettings,
        <InstagramIcon size="5x" active={!_.isEmpty(instagramSettings)} />
      ),
      LinkedIn: updateAccounts(
        SocialProviderId.LinkedIn,
        linkedinSettings,
        <LinkedInIcon size="5x" active={!_.isEmpty(linkedinSettings)} />
      ),
      LinkedInBusiness: updateAccounts(
        SocialProviderId.LinkedInBusiness,
        linkedinBusinessSettings,
        <LinkedInIcon size="5x" active={!_.isEmpty(linkedinBusinessSettings)} />
      ),
      GoogleAds: updateAccounts(
        SocialProviderId.Google,
        googleSettings,
        <GooglenAnalyticsIcon size="5x" active={!_.isEmpty(googleSettings)} />
      ),
      InstagramMessenger: updateAccounts(
        SocialProviderId.InstagramMessenger,
        instagramMessengerSettings,
        <InstagramMenssegerIcon
          size="5x"
          active={!_.isEmpty(instagramMessengerSettings)}
        />
      ),
      FacebookAds: updateAccounts(
        SocialProviderId.FacebookAds,
        facebookAdsSettings,
        <FacebookAdsIcon size="5x" active={!_.isEmpty(facebookAdsSettings)} />
      ),
      Gmail: updateAccounts(
        SocialProviderId.Gmail,
        gmailSettings,
        <GmailIcon size="5x" active={!_.isEmpty(gmailSettings)} />
      ),
      Office365: updateAccounts(
        SocialProviderId.Office365,
        officeSettings,
        <Office365Icon size="5x" active={!_.isEmpty(officeSettings)} />
      ),
      Smtp: updateAccounts(
        SocialProviderId.Smtp,
        smtpSettings,
        <SmtpIcon size="5x" active={!_.isEmpty(smtpSettings)} />
      ),
      WooCommerce: updateAccounts(
        SocialProviderId.WooCommerce,
        wooSettings,
        <WooCommerceIcon size="5x" active={!_.isEmpty(wooSettings)} />
      ),
      Mercadolibre: updateAccounts(
        SocialProviderId.Mercadolibre,
        meliSettings,
        <MercadolibreIcon size="5x" active={!_.isEmpty(meliSettings)} />
      ),
      Jumpseller: updateAccounts(
        SocialProviderId.Jumpseller,
        jumpsellerSettings,
        <JumpsellerIcon size="5x" active={!_.isEmpty(jumpsellerSettings)} />
      ),
      Mailchimp: updateAccounts(
        SocialProviderId.Mailchimp,
        mailchimpSettings,
        <MailchimpIcon size="5x" active={!_.isEmpty(mailchimpSettings)} />
      ),
      Whatsapp: updateAccounts(
        SocialProviderId.Whatsapp,
        whatsappSettings,
        <WhatsappIcon size="5x" active={!_.isEmpty(whatsappSettings)} />
      ),
      OpenAi: updateAccounts(
        SocialProviderId.OpenAi,
        openAiSettings,
        <OpenAiIcon size="5x" active={!_.isEmpty(openAiSettings)} />
      ),
      TikTok: updateAccounts(
        SocialProviderId.TikTok,
        tikTokSettings,
        <TiktokIcon size="5x" active={!_.isEmpty(tikTokSettings)} />
      ),
    }));
  }, [
    facebookSettings,
    facebookAdsSettings,
    instagramSettings,
    linkedinSettings,
    linkedinBusinessSettings,
    googleSettings,
    gmailSettings,
    officeSettings,
    smtpSettings,
    wooSettings,
    meliSettings,
    jumpsellerSettings,
    mailchimpSettings,
    whatsappSettings,
    openAiSettings,
    instagramMessengerSettings,
    tikTokSettings,
  ]);

  const categories = {
    "Redes Sociales": {
      icon: faUsers, // Ícono relacionado con redes sociales y conexiones
      items: ["Facebook", "Instagram", "LinkedIn", "TikTok", "Whatsapp"],
    },
    Marketing: {
      icon: faBullhorn, // Ícono de marketing y anuncios
      items: [
        "FacebookAds",
        "GoogleAds",
        "Mailchimp",
        "WooCommerce",
        "Mercadolibre",
      ],
    },
    "Inteligencia Artificial": {
      icon: faRobot,
      items: ["OpenAi"],
    },
    Comunicaciones: {
      icon: faEnvelope,
      items: ["InstagramMessenger", "Gmail", "Office365", "Smtp"],
    },
    Negocios: {
      icon: faBriefcase,
      items: ["LinkedInBusiness", "Jumpseller"],
    },
  };

  const handleNetworkSelect = (networkName: string) => {
    setSelectedNetwork(networkName);
  };

  const getAccountsForNetwork = () => {
    return accounts[selectedNetwork] || [];
  };

  const getNetworkIcon = (network: string) => {
    switch (network) {
      case "Facebook":
        return faFacebook;
      case "Instagram":
        return faInstagram;
      case "Twitter":
        return faTwitter;
      case "TikTok":
        return faTiktok;
      case "LinkedIn":
        return faLinkedin;
      case "InstagramMessenger":
        return faFacebook;
      case "FacebookAds":
        return faFacebook;
      case "LinkedInBusiness":
        return faBuilding;
      case "Gmail":
        return faEnvelope;
      case "Office365":
        return faGoogleDrive;
      case "Smtp":
        return faBoxOpen;
      case "GoogleAds":
        return faGoogle;
      case "WooCommerce":
        return faShoppingCart;
      case "Mercadolibre":
        return faShoppingCart;
      case "Jumpseller":
        return faBoxOpen;
      case "Mailchimp":
        return faMailchimp;
      case "Whatsapp":
        return faWhatsapp;
      case "OpenAi":
        return faSyncAlt;
      default:
        return faLinkedin;
    }
  };
  const remainingBindDays = useCallback(
    (providerId: SocialProviderId) => {
      const settings = combinedSettings[providerId];

      if (settings && settings.expiresIn) {
        return daysRemainingFromToday(settings.expiresIn);
      }

      return null;
    },
    [combinedSettings]
  );

  return (
    <PanelWembii className="container-fluid bg-light rounded shadow-sm py-4">
      <div className="row">
        <div className="col-md-4">
          <h4 className="mb-4">Conexiones ({Object.keys(accounts).length})</h4>
          <div className="list-group border-right px-2">
            {Object.entries(categories).map(([category, metadata]) => (
              <div key={category}>
                <div className="d-flex my-2">
                  <FontAwesomeIcon
                    icon={metadata.icon}
                    className="me-2 my-auto"
                    style={{ fontSize: "1rem" }}
                  />
                  <h5 className="mt-3">{category}</h5>
                </div>
                {metadata.items.map((network) => (
                  <button
                    key={network}
                    onClick={() => handleNetworkSelect(network)}
                    className={`list-group-item list-group-item-action d-flex align-items-center py-3 px-4 mb-2 shadow-sm ${
                      selectedNetwork === network ? "active" : ""
                    }`}
                    style={{ borderRadius: "10px" }}
                  >
                    {network === "Office365" ? (
                      <Office365Icon
                        size="2x"
                        active={selectedNetwork === network}
                        className="me-3"
                      />
                    ) : network === "Smtp" ? (
                      <SmtpIcon
                        size="2x"
                        active={selectedNetwork === network}
                        className="me-3"
                      />
                    ) : network === "Mercadolibre" ? (
                      <MercadolibreIcon
                        size="2x"
                        active={selectedNetwork === network}
                        className="me-3"
                      />
                    ) : network === "InstagramMessenger" ? (
                      <InstagramMenssegerIcon
                        size="2x"
                        active={selectedNetwork === network}
                        className="me-3"
                      />
                    ) : network === "WooCommerce" ? (
                      <WooCommerceIcon
                        size="2x"
                        active={selectedNetwork === network}
                        className="me-3"
                      />
                    ) : network === "LinkedInBusiness" ? (
                      <LinkedInBusinessIcon
                        size="2x"
                        active={selectedNetwork === network}
                        className="me-3"
                      />
                    ) : network === "OpenAi" ? (
                      <OpenAiIcon
                        size="2x"
                        active={selectedNetwork === network}
                        className="me-3"
                      />
                    ) : network === "Jumpseller" ? (
                      <JumpsellerIcon
                        size="2x"
                        active={selectedNetwork === network}
                        className="me-3"
                      />
                    ) : network === "TikTok" ? (
                      <TiktokIcon
                        size="2x"
                        active={selectedNetwork === network}
                        className="me-3"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={getNetworkIcon(network)}
                        className="me-3"
                        style={{ fontSize: "1.5rem" }}
                      />
                    )}

                    <div className="d-flex justify-content-between my-auto w-100">
                      <span>{network.replace(/([a-z])([A-Z])/g, "$1 $2")}</span>
                      {accounts[network]?.length ? (
                        <>
                          {" "}
                          <span className="text-sm d-md-flex d-none">
                            {accounts[network]!.length > 1
                              ? `+ ${accounts[network]?.length} Cuentas conectadas`
                              : "Una cuenta sincronizada"}
                          </span>
                          <span className="text-sm d-md-none d-sm-flex">
                            +{accounts[network]?.length}
                          </span>
                        </>
                      ) : (
                        <>
                          {" "}
                          <span className="text-sm d-md-flex d-none">
                            Sin cuentas añadidas
                          </span>
                          <span className="text-sm d-md-none d-sm-flex">
                            {accounts[network]?.length}
                          </span>
                        </>
                      )}
                    </div>
                  </button>
                ))}
              </div>
            ))}
          </div>
        </div>

        <div className="col-md-8">
          <div className="d-flex justify-content-between">
            <h4 className="mb-4 my-auto">Cuentas Conectadas</h4>
            {
              //!Get ready to be released in the future
              /* <div className="my-auto  ">
              <FacebookAuthButton
                genericSync
                customText="Sincronizar otra cuenta"
              />
            </div> */
            }
          </div>
          {selectedNetwork ? (
            <div className="my-4">
              {getAccountsForNetwork().length > 0 ? (
                <ul className="list-unstyled">
                  {getAccountsForNetwork().map((account, index) => (
                    <li
                      key={index}
                      className="mb-3 d-md-flex justify-content-between align-items-center border-bottom p-1"
                    >
                      <div className="d-md-flex align-items-center justify-content-between w-100">
                        <div className="d-md-flex my-auto ">
                          {account.avatar !== undefined && (
                            <img
                              className="socialCard-avatar  mx-2 rounded-full"
                              src={account.avatar}
                              loading="lazy"
                              alt="avatar"
                              style={{
                                width: "45px",
                                height: "45px ",
                                top: 40,
                                right: 90,
                              }}
                              width={45}
                              height={45}
                            />
                          )}{" "}
                          <span className="my-auto">{account.displayName}</span>
                        </div>
                        {remainingBindDays(account.id as SocialProviderId) &&
                        remainingBindDays(account.id as SocialProviderId)! >
                          0 &&
                        remainingBindDays(account.id as SocialProviderId)! <=
                          365 ? (
                          <span className="mx-4 my-auto text-sm text-muted">
                            Quedan{" "}
                            {remainingBindDays(account.id as SocialProviderId)}{" "}
                            dias para volver a conectar
                          </span>
                        ) : (
                          <span className="mx-4 my-auto text-sm text-muted">
                            Cuenta sin expiración
                          </span>
                        )}
                      </div>

                      <div className="w-fit">
                        {selectedNetwork === SocialProviderId.Facebook ? (
                          <FacebookAuthButton />
                        ) : selectedNetwork === SocialProviderId.Instagram ? (
                          <InstagramAuthButton />
                        ) : selectedNetwork === SocialProviderId.LinkedIn ? (
                          <LinkedInAuthButton />
                        ) : selectedNetwork === SocialProviderId.FacebookAds ? (
                          <FacebookAdsAuthButton />
                        ) : selectedNetwork === SocialProviderId.Google ? (
                          <GoogleAnalyticsAuthButton />
                        ) : selectedNetwork === SocialProviderId.Gmail ? (
                          <GmailAuthButton />
                        ) : selectedNetwork === SocialProviderId.Office365 ? (
                          <Office365AuthButton />
                        ) : selectedNetwork === SocialProviderId.Smtp ? (
                          <SmtpAuthButton />
                        ) : selectedNetwork === SocialProviderId.WooCommerce ? (
                          <WooCommerceAuthButton />
                        ) : selectedNetwork ===
                          SocialProviderId.Mercadolibre ? (
                          <MercadolibreAuthButton />
                        ) : selectedNetwork === SocialProviderId.Jumpseller ? (
                          <JumpSellerAuthButton />
                        ) : selectedNetwork === SocialProviderId.Mailchimp ? (
                          <MailchimpAuthButton />
                        ) : selectedNetwork === SocialProviderId.Whatsapp ? (
                          <WhatsappAuthButton />
                        ) : selectedNetwork === SocialProviderId.OpenAi ||
                          selectedNetwork === SocialProviderId.OpenAI ? (
                          <OpenAiAuthButton />
                        ) : selectedNetwork === SocialProviderId.TikTok ? (
                          <TiktokAuthButton />
                        ) : (
                          <button
                            className="btn btn-outline- disabled"
                            disabled
                          >
                            <FontAwesomeIcon
                              icon={faSyncAlt}
                              className="me-2"
                            />
                            Integración no disponible
                          </button>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <SyncAccount
                  network={selectedNetwork}
                  existingAccounts={[]}
                  onSync={(newAccount: string): void =>
                    window.location.reload()
                  }
                  onDisconnect={function (account: string): void {
                    window.location.reload();
                  }}
                />
              )}
            </div>
          ) : (
            <div
              className="alert alert-info d-flex align-items-center p-4 rounded shadow-sm"
              role="alert"
              style={{
                backgroundColor: "#e9f7fa",
                borderLeft: "5px solid #007bff",
                borderRadius: "8px",
              }}
            >
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="me-4"
                style={{
                  fontSize: "2.5rem",
                  color: "#007bff",
                  borderRight: "2px solid #007bff",
                  paddingRight: "20px",
                }}
              />
              <div>
                <h5
                  className="mb-2"
                  style={{ fontWeight: "bold", fontSize: "1.2rem" }}
                >
                  ¡Bienvenido a tu panel de redes sociales!
                </h5>
                <p className="mb-2" style={{ fontSize: "1rem" }}>
                  Para comenzar a gestionar tus cuentas conectadas, primero
                  selecciona una red social de la lista en la barra lateral
                  izquierda.
                </p>
              </div>
            </div>
          )}
          {anyIsloading ? <LoadingSpinnerWembii active /> : <></>}
        </div>
      </div>
    </PanelWembii>
  );
};

export default SocialTable;
