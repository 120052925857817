import React, { useCallback } from "react";
import { Button } from "reactstrap";
import Disconneted from "../../assets/disconneted.jpeg";
import { LoadingSpinnerWembii } from "../component/LoadingSpinnerWembii";

export const CheckingConnection = () => {
  return (
    <div className="container d-flex flex-column " style={{ height: "100vh" }}>
      <div className="d-flex flex-column justify-content-center items-center my-auto container ">
        <LoadingSpinnerWembii active size="lg" />
        <h1 className="text-center">Comprobando conexión</h1>
        <p className="text-center mx-auto my-4" style={{ width: "60%" }}>
          Esto podría tardar un momento, por favor aguarda...
        </p>
      </div>
    </div>
  );
};
