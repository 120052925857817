import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useSocialPostCore } from "../../../../cores/socialPost";
import { SocialProviderId } from "../../../../cores/socialPost/config";
import { IconMapper } from "../selector/SocialPostSelector";
import { FacebookPostPreview } from "./FacebookPostPreview";
import { InstagramPostPreview } from "./InstagramPostPreview";
import { LinkedInPostPreview } from "./LinkedInPostPreview";
import { TwitterPostPreview } from "./TwitterPostPreview";
import { TiktokPostPreview } from "./TiktokPostPreview";

export const SocialPostPreview = () => {
  const { postErrors, socialProviders } = useSocialPostCore();
  const [previewId, setPreviewId] = useState<string>("");
  useEffect(() => {
    if (socialProviders.length === 0) setPreviewId("");
    if (socialProviders.length > 0)
      if (
        !previewId ||
        !socialProviders.filter((sp) => sp.id === previewId).length
      )
        setPreviewId(socialProviders[0].id);
  }, [previewId, socialProviders]);

  const showIcon = useCallback(
    (id: string) => {
      const error = postErrors.filter(
        ({ socialProviderId }) => socialProviderId === id
      );
      console.log({ error });
      return error.length > 0 ? (
        <FontAwesomeIcon
          icon={["fas", "exclamation-circle"]}
          size="xs"
          color="red"
          className="position-absolute"
          style={{ marginLeft: "22px", marginTop: "20px", zIndex: 100 }}
        />
      ) : null;
    },
    [postErrors]
  );

  const showPreview = useMemo(() => {
    switch (previewId) {
      case SocialProviderId.Facebook.toLowerCase():
        return <FacebookPostPreview />;
      case SocialProviderId.Twitter.toLowerCase():
        return <TwitterPostPreview />;
      case SocialProviderId.LinkedIn.toLowerCase():
        return <LinkedInPostPreview />;
      case SocialProviderId.LinkedInBusiness.toLowerCase():
        return <LinkedInPostPreview business />;
      case SocialProviderId.Instagram.toLowerCase():
        return <InstagramPostPreview />;
      case SocialProviderId.TikTok.toLowerCase():
        return <TiktokPostPreview />;
      default:
        return null;
    }
  }, [previewId]);

  return (
    <Container
      className="h-100 overflow-auto pb-3 mb-1"
      style={{
        maxHeight: "700px",
      }}
    >
      <Row className="justify-content-around my-4">
        {socialProviders.sort().map(({ id }) => (
          <Col xs="auto" key={id}>
            <div className="pointer" onClick={() => setPreviewId(id)}>
              {showIcon(id)}
              {_.isFunction(IconMapper[id]) &&
                IconMapper[id]({ active: id === previewId, size: "2x" })}
            </div>
          </Col>
        ))}
      </Row>
      {showPreview}
    </Container>
  );
};
