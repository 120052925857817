import React, { useCallback, useEffect, useMemo, useRef } from "react";
import QueryString from "query-string";
import { Button, Col, Container, Row } from "reactstrap";
import {
  BOARD_CARD_MODAL,
  EVENT_CARD_MODAL,
  MEETING_CONFIG_FORM,
  MEETING_EDIT_FORM,
  MORE_INFO_CALENDAR,
} from "../../constants/modal.constant";
import { useGlobalCore } from "../../cores/globals";
import { useMeCore } from "../../cores/me";
import Content from "../layout/Content";
import { Header2 } from "../layout/Header2";
import Layout from "../layout/Layout";
import SideModal from "../layout/SideModal";
import { useIntl } from "react-intl";
import { useRouterCore } from "../../cores/router";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { PanelWembii } from "../component/PanelWembii";
import esLocale from "@fullcalendar/core/locales/es";
import ptLocale from "@fullcalendar/core/locales/pt";
import { LOCALE } from "../../constants/locale.constant";
import { useEventCore } from "../../cores/event";
import moment from "moment";
import EditEventForm from "../component/event/modals/EditEventForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CalendarConfig from "../component/event/modals/CalendarConfig";
import { toast } from "react-toastify";
import { EVENT_CONFIG } from "../../cores/event/config";
import {
  CARD_PRODUCT,
  TIME_AVAILABILITY,
} from "../../constants/internalModal.constant";
import { GOOGLE_COLOR } from "../../constants/socialColors.constant";
import { COLOR_PRIMARY } from "../../constants/theme";
import { EVENT_ORIGIN } from "../../cores/event/constants/eventOrigin.constant";
import { LoadingSpinnerWembii } from "../component/LoadingSpinnerWembii";
import {
  BOARD_CARD_LOADING,
  EVENTS_GET_LOADING,
  EVENT_GET_LOADING,
} from "../../constants/loading.constant";
import { useLocation } from "react-router-dom";
import { useContactCore } from "../../cores/contacts";
import BoardSideModals from "../component/boards/BoardSideModals";
import TaskSideModals from "../component/tasks/TaskSideModals";
import EmailSideModals from "../component/gmail/EmailSideModals";
import EventSideModals from "../component/event/EventSideModals";
import ContactSideModals from "../component/contacts/ContactSideModals";
import CompanySideModals from "../component/contactCompanies/CompanySideModals";
import { YoutubeModal } from "../component/YoutubeModal";
import { Position } from "../../cores/globals/interfaces/globalEntity";
import { TooltipWembii } from "../component/TooltipWembii";
import { Errors } from "../../constants/errors";
import { useBoardCore } from "../../cores/boards";
import CreateEventForm from "../component/event/modals/CreateEventForm";
import { useOffice365Core } from "../../cores/office365";

export const CalendarScreen = () => {
  const intl = useIntl();
  const { search } = useLocation();
  const {
    isModal,
    isLoading,
    turnOffModal,
    turnOffInternalModal,
    turnOnModal,
    getModalVisibility,
    getModalContent,
  } = useGlobalCore();
  const { user, company, companyRole, companyPlan, isAdmin, isReseller } =
    useMeCore();
  const {
    getEvents,
    getEvent,
    getGoogleEvent,
    getCalendar,
    putCalendar,
    events,
    calendar,
    title,
    description,
    timezone,
  } = useEventCore();
  const { getCalendarEvents, calendarEvents, getCalendarEvent, settings } =
    useOffice365Core();
  const { cleanCard, errorCode } = useBoardCore();
  const { getCustomFields } = useContactCore();
  const { goToSocial } = useRouterCore();

  const calendarRef = useRef<any>(null);

  useEffect(() => {
    if (settings?.token) {
      getCalendarEvents(company?.id!, false);
    }
  }, [getCalendarEvents, company?.id]);
  useEffect(() => {
    const { eventId } = QueryString.parse(search);
    if (eventId) {
      getEvent(company?.id!, eventId as string);
      turnOnModal(MEETING_EDIT_FORM, true);
    }
  }, [search, company, getEvent, turnOnModal]);

  useEffect(() => {
    if (
      !companyPlan?.rules.allowBusinessBoard ||
      (!companyRole?.owner &&
        !companyRole?.admin &&
        !companyRole?.agent &&
        !companyRole?.crm &&
        !isAdmin &&
        !isReseller)
    )
      goToSocial();
  }, [companyPlan, companyRole, isAdmin, isReseller, goToSocial]);

  useEffect(() => {
    getCalendar(company?.id!);
  }, [company, getCalendar]);

  const onClickCreateMeeting = useCallback(() => {
    getCalendar(company?.id!);
    turnOnModal(EVENT_CARD_MODAL, <CreateEventForm contacts={[]} />);
  }, [turnOnModal, getCalendar, company]);

  const onClickEditMeeting = useCallback(
    (info) => {
      const event = meCalendarEvents.find(
        (event) => event.id === info.event.id
      );
      if (
        event &&
        event.from === "office" &&
        event?.type === EVENT_ORIGIN.OFFICE_365_CALENDAR
      ) {
        const selectedEvent = calendarEvents.find(
          (officeEvent) => officeEvent.id === event.id
        );
        getCalendarEvent(company?.id!, false, selectedEvent?.id!);
        turnOnModal(MEETING_EDIT_FORM, true);
      } else if (event?.type === EVENT_ORIGIN.WEMBII_CALENDAR) {
        getEvent(company?.id!, info.event.id);
        turnOnModal(MEETING_EDIT_FORM, true);
      } else {
        getGoogleEvent(company?.id!, info.event.id);
        turnOnModal(MEETING_EDIT_FORM, true);
      }
    },
    [turnOnModal, getEvent, getGoogleEvent, events, company]
  );

  const calendarLocale = useMemo(() => {
    switch (user?.locale) {
      case LOCALE.SPANISH:
        return esLocale;
      case LOCALE.PORTUGUESE:
        return ptLocale;
      default:
        return "";
    }
  }, [user?.locale]);

  const onChangeDates = useCallback(
    (value) => {
      getEvents(
        company?.id!,
        moment(value.startStr).format(),
        moment(value.endStr).format()
      );
    },
    [getEvents, company]
  );

  const openMoreInfo = useCallback(() => {
    turnOnModal(MORE_INFO_CALENDAR, true, Position.LEFT);
  }, [turnOnModal]);

  const meEvents = useMemo(
    () =>
      events.map((event) => ({
        id: event.id,
        title: event.title,
        start: event.startDate,
        end: event.endDate,
        from: "standard",
        type: event.type,
        color:
          event.type === EVENT_ORIGIN.GOOGLE_CALENDAR
            ? GOOGLE_COLOR
            : event.link !== null
            ? "#0098CD"
            : COLOR_PRIMARY,
        classNames: `no-border ${
          calendarRef &&
          calendarRef.current &&
          calendarRef.current.getApi().view.type !== "dayGridMonth"
            ? event.type === EVENT_ORIGIN.GOOGLE_CALENDAR
              ? "google-event"
              : event.link !== null
              ? "gradient-event"
              : "wembii-event"
            : ""
        }`,
      })),
    [events]
  );
  const officeEvents = useMemo(
    () =>
      calendarEvents.map((event) => ({
        id: event.id,
        from: "office",
        type: EVENT_ORIGIN.OFFICE_365_CALENDAR,
        title: event.subject,
        start: event.start.dateTime,
        end: event.end.dateTime,
        color: COLOR_PRIMARY,
        classNames: `no-border `,
      })),
    [calendarEvents]
  );

  const meCalendarEvents = [...officeEvents, ...meEvents];
  const onClickConfig = useCallback(() => {
    getCalendar(company?.id!);
    getCustomFields(company?.id!);
    turnOnModal(MEETING_CONFIG_FORM, true);
  }, [turnOnModal, getCalendar, getCustomFields, company]);

  const onSharing = useCallback(() => {
    navigator.clipboard.writeText(
      `${EVENT_CONFIG.webUrl}/meeting/${calendar?.calendarId}/`
    );
    toast.success(intl.formatMessage({ id: "app.calendar.share.copy" }));
  }, [intl, calendar]);

  return (
    <Layout>
      <Header2
        title={intl.formatMessage({ id: "app.meetings.title" })}
        rightChildren={
          <>
            <FontAwesomeIcon
              icon={["fas", "info-circle"]}
              size="2x"
              className="pointer m-2 ml-2"
              color="gray"
              id="info-SocialPost"
            />
            <FontAwesomeIcon
              icon={["fas", "video"]}
              size="2x"
              className="pointer m-2 ml-2"
              onClick={openMoreInfo}
              color="gray"
            />
            <TooltipWembii
              id="info-SocialPost"
              text={intl.formatMessage({ id: "app.tooltipInfo.calendar" })}
            />
            <Button className="whiteButton" onClick={onSharing}>
              <FontAwesomeIcon
                className="mr-1"
                icon={["fas", "share"]}
                size="1x"
              />
              {intl.formatMessage({ id: "app.crmBusiness.share" })}
            </Button>
            <Button className="whiteButton ml-2" onClick={onClickCreateMeeting}>
              {intl.formatMessage({ id: "app.meetings.create" })}
            </Button>
            <Button className="whiteButton ml-2" onClick={onClickConfig}>
              <FontAwesomeIcon icon={["fas", "gear"]} />
            </Button>
          </>
        }
      />
      <Content>
        <Container fluid>
          <Row>
            <Col xs="12">
              <Container>
                <PanelWembii
                  className="w-100 px-5 py-4 mb-2 position-relative"
                  shadow
                >
                  {isLoading(EVENTS_GET_LOADING) && (
                    <div className="h-100 w-100 top-0 left-0 d-flex justify-content-center align-items-center position-absolute bg-white-transparent z-index-50">
                      <LoadingSpinnerWembii active />
                    </div>
                  )}
                  <FullCalendar
                    ref={calendarRef}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    headerToolbar={{
                      left: "prev,next today",
                      center: "title",
                      right: "dayGridMonth,timeGridWeek,timeGridDay",
                    }}
                    events={meCalendarEvents}
                    timeZone={calendar?.timezone}
                    dayMaxEvents={5}
                    eventClick={(info) => onClickEditMeeting(info)}
                    locale={calendarLocale}
                    datesSet={onChangeDates}
                    initialView="dayGridMonth"
                  />
                </PanelWembii>
              </Container>
            </Col>
          </Row>
        </Container>
      </Content>

      <SideModal
        active={isModal(MEETING_CONFIG_FORM)}
        visible={getModalVisibility(MEETING_CONFIG_FORM)}
        onClose={() => {
          if (
            calendar?.timezone !== timezone ||
            calendar.description !== description ||
            calendar.title !== title
          ) {
            putCalendar(company?.id!, calendar?.id!, {
              title,
              description,
              timezone,
            });
          }
          turnOffModal(MEETING_CONFIG_FORM);
          turnOffInternalModal(TIME_AVAILABILITY);
        }}
      >
        <CalendarConfig />
      </SideModal>
      <SideModal
        active={isModal(MEETING_EDIT_FORM)}
        visible={getModalVisibility(MEETING_EDIT_FORM)}
        onClose={() => turnOffModal(MEETING_EDIT_FORM)}
        showSppiner={isLoading(EVENT_GET_LOADING)}
      >
        <EditEventForm />
      </SideModal>

      <BoardSideModals />
      <TaskSideModals />
      <EmailSideModals />
      <EventSideModals />
      <ContactSideModals />
      <CompanySideModals />

      <SideModal
        active={isModal(BOARD_CARD_MODAL)}
        visible={getModalVisibility(BOARD_CARD_MODAL)}
        onClose={() => {
          turnOffModal(BOARD_CARD_MODAL);
          turnOffInternalModal(CARD_PRODUCT);
          cleanCard();
        }}
        showSppiner={isLoading(BOARD_CARD_LOADING)}
        showError={errorCode === Errors.CardLoad}
      >
        {getModalContent(BOARD_CARD_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(MORE_INFO_CALENDAR)}
        visible={getModalVisibility(MORE_INFO_CALENDAR)}
        onClose={() => turnOffModal(MORE_INFO_CALENDAR)}
        left
      >
        <YoutubeModal
          title={intl.formatMessage({ id: "app.calendar.video" })}
          videoId="vLQVTAdzfpo"
        />
      </SideModal>
    </Layout>
  );
};
