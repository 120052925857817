import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import advertisements from "../cores/advertisements/store/reducer";
import analytics from "../cores/analytics/store/reducer";
import company from "../cores/company/store/reducer";
import contact from "../cores/contacts/store/reducer";
import facebook from "../cores/facebook/store/reducer";
import facebookAds from "../cores/facebookAds/store/reducer";
import event from "../cores/event/store/reducer";
import socialChat from "../cores/socialChat/store/reducer";
import facebookForm from "../cores/facebookForm/store/reducer";
import globals from "../cores/globals/store/reducer";
import gmail from "../cores/gmail/store/reducer";
import google from "../cores/google/store/reducer";
import googleCalendar from "../cores/googleCalendar/store/reducer";
import instagram from "../cores/instagram/store/reducer";
import linkedIn from "../cores/linkedIn/store/reducer";
import linkedInBusiness from "../cores/linkedInBusiness/store/reducer";
import market from "../cores/market/store/reducer";
import me from "../cores/me/store/reducer";
import mercadolibre from "../cores/mercadolibre/store/reducer";
import plan from "../cores/plan/store/reducer";
import reseller from "../cores/reseller/store/reducer";
import session from "../cores/session/store/reducer";
import socialPost from "../cores/socialPost/store/reducer";
import socket from "../cores/socket/store/reducer";
import twitter from "../cores/twitter/store/reducer";
import user from "../cores/user/store/reducer";
import webChat from "../cores/conversations/store/reducer";
import webForm from "../cores/webForm/store/reducer";
import whatsappChat from "../cores/whatsappChat/store/reducer";
import wooCommerce from "../cores/wooCommerce/store/reducer";
import jumpseller from "../cores/jumpseller/store/reducer";
import mailchimp from "../cores/mailchimp/store/reducer";
import board from "../cores/boards/store/reducer";
import notification from "../cores/notifications/store/reducer";
import whatsapp from "../cores/whatsapp/store/reducer";
import payment from "../cores/payment/store/reducer";
import product from "../cores/products/store/reducer";
import openAi from "../cores/openAi/store/reducer";
import office365 from "../cores/office365/store/reducer";
import config from "../cores/config/store/reducer";
import boardAnalytics from "../cores/boardAnalytics/store/reducer";
import tasks from "../cores/tasks/store/reducer";
import templates from "../cores/templates/store/reducer";
import instagramMensseger from "../cores/instagramMensseger/store/reducer";
import smtp from "../cores/smtp/store/reducer";
import email from "../cores/email/store/reducer";
import automation from "../cores/automations/store/reducer";
import tiktok from "../cores/tiktok/store/reducer";
import { State } from "./state";

const reducers = (history: any) =>
  combineReducers<State>({
    advertisements,
    analytics,
    tiktok,
    automation,
    board,
    boardAnalytics,
    company,
    config,
    contact,
    email,
    event,
    facebook,
    facebookAds,
    facebookForm,
    globals,
    gmail,
    google,
    googleCalendar,
    instagram,
    whatsapp,
    instagramMensseger,
    openAi,
    jumpseller,
    linkedIn,
    linkedInBusiness,
    mailchimp,
    market,
    me,
    mercadolibre,
    notification,
    office365,
    payment,
    product,
    plan,
    reseller,
    session,
    smtp,
    socialChat,
    socialPost,
    socket,
    tasks,
    templates,
    twitter,
    user,
    webChat,
    webForm,
    whatsappChat,
    wooCommerce,
    router: connectRouter(history),
  });

export default reducers;
