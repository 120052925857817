import { EmailFile } from "../../email/interface/emailFile";
import socialApiClient from "../../helpers/services/social-api-client";
import { GetOffice365BindResponse } from "../interface/api/get-office365-bind";
import {
  GetCalendarEventResponse,
  GetCalendarEventsResponse,
} from "../interface/api/get-office365-calendar";
import { GetOfficeEvent } from "../interface/api/get-office365-event";
import { GetOffice365LoginResponse } from "../interface/api/get-office365-login";
import { PostOffice365BindResponse } from "../interface/api/post-office365-bind";
import {
  Email,
  PostOffice365EmailResponse,
} from "../interface/api/post-office365-email";
import { office365SettingMapper } from "./mapper";

export const office365ApiService = {
  unbind(companyId: string, asUser: boolean) {
    return socialApiClient()
      .post(`office365/unbind`, {}, { params: { companyId, asUser } })
      .then(({ data }) => data);
  },
  bind(companyId: string, code: string, asUser: boolean) {
    return socialApiClient()
      .post<PostOffice365BindResponse>(
        `office365/bind`,
        { code },
        {
          params: { companyId, asUser },
        }
      )
      .then(({ data }) =>
        office365SettingMapper.mapResponseToOffice365Setting(data)
      );
  },
  get(companyId: string, asUser: boolean) {
    return socialApiClient()
      .get<GetOffice365BindResponse>(`office365`, {
        params: { companyId, asUser },
      })
      .then(({ data }) =>
        office365SettingMapper.mapResponseToOffice365Setting(data)
      );
  },
  getCalendarEvents(companyId: string, asUser: boolean) {
    return socialApiClient()
      .get<GetCalendarEventsResponse>(`office365/get-calendar-events`, {
        params: { companyId, asUser },
      })
      .then(({ data }) =>
        office365SettingMapper.mapResponseToOffice365Events(data)
      );
  },
  getCalendarEvent(companyId: string, asUser: boolean, eventId: string) {
    return socialApiClient()
      .get<GetOfficeEvent>(`office365/get-calendar-event?eventId=${eventId}`, {
        params: { companyId, asUser },
      })
      .then(({ data }) =>
        office365SettingMapper.mapResponseToOffice365Event(data)
      );
  },
  deleteCalendarEvent(companyId: string, asUser: boolean, eventId: string) {
    return socialApiClient().delete<{ id: string }>(
      `office365/delete-calendar-event?eventId=${eventId}`,
      {
        params: { companyId, asUser },
      }
    );
  },

  login(companyId: string, asUser: boolean) {
    return socialApiClient()
      .get<GetOffice365LoginResponse>(`office365/login`, {
        params: { companyId, asUser },
      })
      .then(({ data }) =>
        office365SettingMapper.mapResponseToOffice365LoginUrl(data)
      );
  },
  postEmail(
    companyId: string,
    body: Email,
    files: EmailFile[],
    asUser: boolean,
    cardId: string | null
  ) {
    const bodyFormData = new FormData();
    files.map((file) => bodyFormData.append("files", file.data));
    bodyFormData.append("subject", body.subject);
    bodyFormData.append("message", body.message);
    bodyFormData.append("to", JSON.stringify(body.to));
    if (cardId) {
      bodyFormData.append("cardId", cardId);
    }
    return socialApiClient()
      .post<PostOffice365EmailResponse>(`office365/email`, bodyFormData, {
        params: { companyId, asUser },
      })
      .then(({ data }) => office365SettingMapper.mapResponseToId(data));
  },
};
