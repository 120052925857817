import React, { useMemo } from "react";
import { useMeCore } from "../../../cores/me";
import _, { uniqueId } from "lodash";
import { useIntl } from "react-intl";
import { useFacebookCore } from "../../../cores/facebook";
import { useFacebookAdsCore } from "../../../cores/facebookAds";
import { useGoogleCore } from "../../../cores/google";
import { useInstagramCore } from "../../../cores/instagram";
import { useInstagramMenssegerCore } from "../../../cores/instagramMensseger";
import { useLinkedInCore } from "../../../cores/linkedIn";
import { useLinkedInBusinessCore } from "../../../cores/linkedInBusiness";
import { useMercadolibreCore } from "../../../cores/mercadolibre";
import { useRouterCore } from "../../../cores/router";
import { useTwitterCore } from "../../../cores/twitter";
import { useWooCommerceCore } from "../../../cores/wooCommerce";

import { NavItem2 } from "./NavItem2";
import { NavItemGroup } from "./NavItemGroup";
import { ProtectorCondition } from "../../../cores/globals/interfaces/ProtectorCondition";
import SidebarSubItems from "./SidebarSubItems";
import { useTiktokCore } from "../../../cores/tiktok";

export const RenderProtectorRoutes = () => {
  const {
    routes,
    goToSocial,
    goToReports,
    goToActivityBoards,
    goToAutomations,
    goToBinding,
  } = useRouterCore();
  const { company, companyPlan, companyRole, isAdmin, isReseller } =
    useMeCore();
  const {
    name: planName,
    rules: {
      allowAnalytics = false,
      allowFacebookAdvertisements = false,
      allowMarket = false,
      allowWebChat = false,
      allowWhatsappChat = false,
      allowActivityBoard = false,
      allowBusinessBoard = false,
      allowAutomation = false,
      allowDatabase = false,
      reportsAmount = 0,
    } = {},
  } = companyPlan! || { name: "." };

  const intl = useIntl();

  const { settings: facebookSettings } = useFacebookCore();
  const { settings: facebookAdsSettings } = useFacebookAdsCore();
  const { settings: googleSettings } = useGoogleCore();
  const { settings: instagramSettings } = useInstagramCore();
  const { settings: tiktokSettings } = useTiktokCore();
  const { settings: instagramMenssegerSettings } = useInstagramMenssegerCore();
  const { settings: wooSettings } = useWooCommerceCore();
  const { settings: mercadolibreSettings } = useMercadolibreCore();
  const { settings: twitterSettings } = useTwitterCore();
  const { settings: linkedInSetting } = useLinkedInCore();
  const { settings: linkedInBusinessSetting } = useLinkedInBusinessCore();
  const planNotAllowedMessage = `Plan ${
    planName.split(".")[2]
  } no permite esta funcionalidad`;

  const {
    advertisementsSubItems,
    chatSubItems,
    crmSubItems,
    databaseSubItems,
    formsSubItems,
    metricsSubItems,
    onlineSellsSubItems,
    socialSubItems,
  } = SidebarSubItems({ planNotAllowedMessage });

  const isFacebookSync = !_.isEmpty(facebookSettings);
  const isLinkedInSync = !_.isEmpty(linkedInSetting);
  const isLinkedInSBusinessSync = !_.isEmpty(linkedInBusinessSetting);
  const isTwitterSync = !_.isEmpty(twitterSettings);
  const isFacebookAdsSync = !_.isEmpty(facebookAdsSettings);
  const isInstagramSync = !_.isEmpty(instagramSettings);
  const isInstagramMenssegerSync = !_.isEmpty(instagramMenssegerSettings);
  const isGoogleSync = !_.isEmpty(googleSettings);
  const isWooSync = !_.isEmpty(wooSettings);
  const isMercadolibreSync = !_.isEmpty(mercadolibreSettings);
  const isTiktokSync = !_.isEmpty(tiktokSettings);
  const isReportAllow = reportsAmount > 0;

  const socialPostActive =
    company != null &&
    (isFacebookSync ||
      isInstagramSync ||
      isTwitterSync ||
      isLinkedInSync ||
      isTiktokSync ||
      isLinkedInSBusinessSync);

  const allowChat =
    allowWebChat ||
    allowWhatsappChat ||
    isInstagramMenssegerSync ||
    isFacebookSync;

  const metricsActive =
    company != null &&
    (isFacebookSync ||
      isInstagramSync ||
      isGoogleSync ||
      isMercadolibreSync ||
      isWooSync);

  const standardRoles = useMemo(() => {
    return (
      isReseller ||
      isAdmin ||
      companyRole?.owner === true ||
      companyRole?.admin === true ||
      companyRole?.agent === true
    );
  }, [
    isReseller,
    isAdmin,
    companyRole?.owner,
    companyRole?.admin,
    companyRole?.agent,
  ]);

  const protectorRoutes: ProtectorCondition[] = [
    {
      moduleIsActive: true,
      condition: company != null,
      render: (
        <NavItem2
          onClick={goToSocial}
          label={intl.formatMessage({ id: "app.sidebar.home" })}
          iconName="home"
          activeUrl={routes.social}
        />
      ),
    },
    {
      moduleIsActive: true,
      condition: company != null,
      render: (
        <NavItem2
          onClick={goToBinding}
          label={intl.formatMessage({ id: "app.navbar.integrations" })}
          iconName="circle-nodes"
          activeUrl={routes.binding}
        />
      ),
    },
    {
      moduleIsActive: true,
      condition:
        company != null && (standardRoles || companyRole?.social === true),
      render: (
        <NavItemGroup
          groupId={`social`}
          label={intl.formatMessage({ id: "app.sidebar.socialMedia" })}
          iconName="thumbs-up"
          disabled={!socialPostActive}
          activeUrls={[
            routes.socialPost,
            routes.socialStory,
            routes.socialHistory,
            routes.socialCalendar,
          ]}
        >
          {socialSubItems.map(({ activeUrl, iconName, label, onClick }) => (
            <NavItem2
              key={uniqueId()}
              activeUrl={activeUrl}
              onClick={onClick}
              label={label}
              iconName={iconName}
            />
          ))}
        </NavItemGroup>
      ),
    },
    {
      moduleIsActive: true,
      condition:
        company != null && (standardRoles || companyRole?.ads === true),
      render: (
        <NavItemGroup
          groupId={`advertisements`}
          label={intl.formatMessage({ id: "app.sidebar.advertisements" })}
          iconName="bullhorn"
          activeUrls={[routes.facebookCampaigns, routes.createCampaign]}
          disabled={!allowFacebookAdvertisements || !isFacebookAdsSync}
          disabledMessage={planNotAllowedMessage}
        >
          {advertisementsSubItems.map(
            ({ activeUrl, iconName, label, onClick, disabled }) => (
              <NavItem2
                key={uniqueId()}
                activeUrl={activeUrl}
                onClick={onClick}
                label={label}
                iconName={iconName}
                disabled={disabled}
              />
            )
          )}
        </NavItemGroup>
      ),
    },
    {
      moduleIsActive: true,
      condition:
        company != null && (standardRoles || companyRole?.forms === true),
      render: (
        <NavItemGroup
          groupId={`forms`}
          label={intl.formatMessage({ id: "app.sidebar.forms" })}
          iconName="wpforms"
          iconPrefix="fab"
          activeUrls={[routes.webForms, routes.facebookForm]}
        >
          {formsSubItems.map(
            ({ activeUrl, iconName, label, onClick, disabled, iconPrefix }) => (
              <NavItem2
                key={uniqueId()}
                activeUrl={activeUrl}
                onClick={onClick}
                label={label}
                iconName={iconName}
                iconPrefix={iconPrefix}
                disabled={disabled}
              />
            )
          )}
        </NavItemGroup>
      ),
    },
    {
      moduleIsActive: true,
      condition:
        company != null && (standardRoles || companyRole?.chat === true),
      render: (
        <NavItemGroup
          groupId={`comments`}
          label={intl.formatMessage({ id: "app.sidebar.chat" })}
          iconName="comments"
          activeUrls={[
            routes.webChat,
            routes.editWebChat,
            routes.editWhatsappChat,
          ]}
          disabled={!allowChat}
          disabledMessage={planNotAllowedMessage}
        >
          {chatSubItems.map(
            ({
              activeUrl,
              iconPrefix,
              iconName,
              label,
              onClick,
              disabled,
              disabledMessage,
            }) => (
              <NavItem2
                key={uniqueId()}
                activeUrl={activeUrl}
                onClick={onClick}
                label={label}
                iconPrefix={iconPrefix}
                iconName={iconName}
                disabled={disabled}
                disabledMessage={disabledMessage}
              />
            )
          )}
        </NavItemGroup>
      ),
    },
    {
      moduleIsActive: true,
      condition:
        company != null && (standardRoles || companyRole?.activities === true),
      render: (
        <NavItem2
          onClick={goToActivityBoards}
          label={intl.formatMessage({ id: "app.sidebar.activities" })}
          iconName="columns"
          activeUrl={routes.activityBoards}
          disabled={!allowActivityBoard}
          disabledMessage={planNotAllowedMessage}
        />
      ),
    },
    {
      moduleIsActive: true,
      condition:
        company != null && (standardRoles || companyRole?.market === true),
      render: (
        <NavItemGroup
          groupId={`onlineSellers`}
          label={intl.formatMessage({ id: "app.sidebar.OnlineSales" })}
          iconName="store"
          activeUrls={[routes.wooCommerceOrders, routes.mercadolibreOrders]}
          disabled={!allowMarket}
          disabledMessage={planNotAllowedMessage}
        >
          {onlineSellsSubItems.map(
            ({ activeUrl, label, onClick, disabled, icon }) => (
              <NavItem2
                key={uniqueId()}
                activeUrl={activeUrl}
                onClick={onClick}
                label={label}
                icon={icon}
                disabled={disabled}
              />
            )
          )}
        </NavItemGroup>
      ),
    },
    {
      moduleIsActive: true,
      condition:
        company != null && (standardRoles || companyRole?.crm === true),
      render: (
        <NavItemGroup
          groupId={`crm`}
          label={intl.formatMessage({ id: "app.sidebar.crm" })}
          iconName="funnel-dollar"
          disabled={!allowBusinessBoard}
          disabledMessage={planNotAllowedMessage}
          activeUrls={[
            routes.businessBoard,
            routes.tasks,
            routes.businessBoardAnalytics,
            routes.calendar,
          ]}
        >
          {crmSubItems.map(({ activeUrl, label, onClick, iconName }) => (
            <NavItem2
              key={uniqueId()}
              onClick={onClick}
              label={label}
              iconName={iconName}
              activeUrl={activeUrl}
            />
          ))}
        </NavItemGroup>
      ),
    },
    {
      moduleIsActive: true,
      condition:
        company != null && (standardRoles || companyRole?.contacts === true),
      render: (
        <NavItemGroup
          groupId={`database`}
          label={intl.formatMessage({ id: "app.sidebar.database" })}
          iconName="database"
          activeUrls={[routes.contacts, routes.contactCompanies]}
          disabled={!allowDatabase}
          disabledMessage={planNotAllowedMessage}
        >
          {databaseSubItems.map(({ activeUrl, label, onClick, iconName }) => (
            <NavItem2
              key={uniqueId()}
              activeUrl={activeUrl}
              onClick={onClick}
              label={label}
              iconName={iconName}
            />
          ))}
        </NavItemGroup>
      ),
    },
    {
      moduleIsActive: true,
      condition:
        metricsActive && (standardRoles || companyRole?.analitycs === true),
      render: (
        <NavItemGroup
          groupId={`metrics`}
          label={intl.formatMessage({ id: "app.sidebar.metrics" })}
          iconName="chart-area"
          activeUrls={[
            routes.googleAnalytics,
            routes.facebookAnalytics,
            routes.instagramAnalytics,
            routes.wooCommerceAnalytics,
            routes.mercadolibreAnalytics,
          ]}
          disabled={!allowAnalytics}
          disabledMessage={planNotAllowedMessage}
        >
          {metricsSubItems.map(
            ({ activeUrl, label, onClick, disabled, icon }) => (
              <NavItem2
                key={uniqueId()}
                activeUrl={activeUrl}
                onClick={onClick}
                label={label}
                icon={icon}
                disabled={disabled}
              />
            )
          )}
        </NavItemGroup>
      ),
    },
    {
      moduleIsActive: true,
      condition:
        company != null && (standardRoles || companyRole?.reports === true),
      render: (
        <NavItem2
          onClick={goToReports}
          label={intl.formatMessage({ id: "app.sidebar.reports" })}
          iconName="file"
          activeUrl={routes.reports}
          disabled={!isReportAllow}
        />
      ),
    },
    {
      moduleIsActive: true,
      condition:
        company != null && (standardRoles || companyRole?.automations === true),
      render: (
        <NavItem2
          onClick={goToAutomations}
          label={intl.formatMessage({ id: "app.sidebar.automations" })}
          iconName="robot"
          disabled={!allowAutomation}
          activeUrl={routes.automations}
        />
      ),
    },
  ];
  return { protectorRoutes };
};
