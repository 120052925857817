import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import {
  AssignmentBoard,
  Comment,
  ContactBoard,
  File,
  ListCard,
  ProductBoard,
  Quote,
} from "../interfaces/api/get-card";
import {
  Board,
  BoardList,
  BoardListCard,
  CompanyBoard,
  ListRestriction,
} from "../interfaces/api/get-board";
import { CardTag } from "../interfaces/api/get-tags";
import { CustomField } from "../interfaces/api/get-custom-fields";

export const getBoardsInitAction = createAction("[Board] Get Boards Init");
export const getBoardsSuccessAction = createAction(
  "[Board] Get Boards Success",
  props<{ boards: Board[] }>()
);
export const getBoardsFailureAction = createAction(
  "[Board] Get Boards Failure",
  props<{ error: string }>()
);

export const getBoardBusinessInitAction = createAction(
  "[Board] Get Board Business Init"
);
export const getBoardActivityInitAction = createAction(
  "[Board] Get Board Activity Init"
);
export const getBoardSuccessAction = createAction(
  "[Board] Get Board Success",
  props<{ board: Board }>()
);
export const getBoardFailureAction = createAction(
  "[Board] Get Board Failure",
  props<{ error: string }>()
);

export const getBoardExportInitAction = createAction(
  "[Board] Get Board Export Init"
);
export const getBoardExportSuccessAction = createAction(
  "[Board] Get Board Export Success"
);
export const getBoardExportFailureAction = createAction(
  "[Board] Get Board Export Failure",
  props<{ error: string }>()
);

export const postBoardInitAction = createAction("[Board] Post Board Init");
export const postBoardSuccessAction = createAction(
  "[Board] Post Board Success",
  props<{ board: Board }>()
);
export const postBoardFailureAction = createAction(
  "[Board] Post Board Failure",
  props<{ error: string }>()
);

export const putBoardInitAction = createAction("[Board] Put Board Init");
export const putBoardSuccessAction = createAction(
  "[Board] Put Board Success",
  props<{ board: Board }>()
);
export const putBoardFailureAction = createAction(
  "[Board] Put Board Failure",
  props<{ error: string }>()
);

export const deleteBoardInitAction = createAction("[Board] Delete Board Init");
export const deleteBoardSuccessAction = createAction(
  "[Board] Delete Board Success",
  props<{ id: string }>()
);
export const deleteBoardFailureAction = createAction(
  "[Board] Delete Board Failure",
  props<{ error: string }>()
);

export const getListsInitAction = createAction("[Board] Get Lists Init");
export const getListsSuccessAction = createAction(
  "[Board] Get Lists Success",
  props<{ lists: BoardList[] }>()
);
export const getListsFailureAction = createAction(
  "[Board] Get Lists Failure",
  props<{ error: string }>()
);

export const postListInitAction = createAction("[Board] Post List Init");
export const postListSuccessAction = createAction(
  "[Board] Post List Success",
  props<{ list: BoardList }>()
);
export const postListFailureAction = createAction(
  "[Board] Post List Failure",
  props<{ error: string }>()
);

export const putListInitAction = createAction("[Board] Put List Init");
export const putListSuccessAction = createAction(
  "[Board] Put List Success",
  props<{ list: BoardList }>()
);
export const putListFailureAction = createAction(
  "[Board] Put List Failure",
  props<{ error: string }>()
);

export const putListOrderInitAction = createAction(
  "[Board] Put List Order Init"
);
export const putListOrderSuccessAction = createAction(
  "[Board] Put List Order Success"
);
export const putListOrderFailureAction = createAction(
  "[Board] Put List Order Failure",
  props<{ error: string }>()
);

export const deleteListInitAction = createAction("[Board] Delete List Init");
export const deleteListSuccessAction = createAction(
  "[Board] Delete List Success",
  props<{ id: string }>()
);
export const deleteListFailureAction = createAction(
  "[Board] Delete List Failure",
  props<{ error: string }>()
);

export const postCardInitAction = createAction("[Board] Post Card Init");
export const postCardSuccessAction = createAction(
  "[Board] Post Card Success",
  props<{ card: ListCard }>()
);
export const postCardFailureAction = createAction(
  "[Board] Post Card Failure",
  props<{ error: string }>()
);

export const putCardOrderInitAction = createAction(
  "[Board] Put Card Order Init"
);
export const putCardOrderSuccessAction = createAction(
  "[Board] Put Card Order Success",
  props<{ id: string; listDestinationId: string; index: number }>()
);
export const putCardOrderFailureAction = createAction(
  "[Board] Put Card Order Failure",
  props<{ error: string }>()
);

export const postAssignmentInitAction = createAction(
  "[Board] Post Assignment Init"
);
export const postAssignmentSuccessAction = createAction(
  "[Board] Post Assignment Success",
  props<{ assignments: AssignmentBoard[] }>()
);
export const postAssignmentFailureAction = createAction(
  "[Board] Post Assignment Failure",
  props<{ error: string }>()
);

export const postTaggingInitAction = createAction("[Board] Post Tagging Init");
export const postTaggingSuccessAction = createAction(
  "[Board] Post Tagging Success"
);
export const postTaggingFailureAction = createAction(
  "[Board] Post Tagging Failure",
  props<{ error: string }>()
);

export const deleteAssignmentInitAction = createAction(
  "[Board] Delete Assignment Init"
);
export const deleteAssignmentSuccessAction = createAction(
  "[Board] Delete Assignment Success",
  props<{ assignments: AssignmentBoard[] }>()
);
export const deleteAssignmentFailureAction = createAction(
  "[Board] Delete Assignment Failure",
  props<{ error: string }>()
);

export const postContactInitAction = createAction("[Board] Post Contact Init");
export const postContactSuccessAction = createAction(
  "[Board] Post Contact Success",
  props<{ contacts: ContactBoard[] }>()
);
export const postContactFailureAction = createAction(
  "[Board] Post Contact Failure",
  props<{ error: string }>()
);

export const deleteContactInitAction = createAction(
  "[Board] Delete Contact Init"
);
export const deleteContactSuccessAction = createAction(
  "[Board] Delete Contact Success",
  props<{ contacts: ContactBoard[] }>()
);
export const deleteContactFailureAction = createAction(
  "[Board] Delete Contact Failure",
  props<{ error: string }>()
);

export const postCompanyInitAction = createAction("[Board] Post Company Init");
export const postCompanySuccessAction = createAction(
  "[Board] Post Company Success",
  props<{ companies: CompanyBoard[] }>()
);
export const postCompanyFailureAction = createAction(
  "[Board] Post Company Failure",
  props<{ error: string }>()
);

export const deleteCompanyInitAction = createAction(
  "[Board] Delete Company Init"
);
export const deleteCompanySuccessAction = createAction(
  "[Board] Delete Company Success",
  props<{ companies: CompanyBoard[] }>()
);
export const deleteCompanyFailureAction = createAction(
  "[Board] Delete Company Failure",
  props<{ error: string }>()
);

export const deleteCardInitAction = createAction("[Board] Delete Card Init");
export const deleteCardSuccessAction = createAction(
  "[Board] Delete Card Success",
  props<{ id: string }>()
);
export const deleteCardFailureAction = createAction(
  "[Board] Delete Card Failure",
  props<{ error: string }>()
);

export const postCommentInitAction = createAction("[Board] Post Comment Init");
export const postCommentSuccessAction = createAction(
  "[Board] Post Comment Success",
  props<{ comment: Comment }>()
);
export const postCommentFailureAction = createAction(
  "[Board] Post Comment Failure",
  props<{ error: string }>()
);

export const putCommentInitAction = createAction("[Board] Put Comment Init");
export const putCommentSuccessAction = createAction(
  "[Board] Put Comment Success",
  props<{ comment: Comment }>()
);
export const putCommentFailureAction = createAction(
  "[Board] Put Comment Failure",
  props<{ error: string }>()
);

export const deleteCommentInitAction = createAction(
  "[Board] Delete Comment Init"
);
export const deleteCommentSuccessAction = createAction(
  "[Board] Delete Comment Success",
  props<{ id: string }>()
);
export const deleteCommentFailureAction = createAction(
  "[Board] Delete Comment Failure",
  props<{ error: string }>()
);

export const getCardInitAction = createAction("[Board] Get Card Init");
export const getCardSuccessAction = createAction(
  "[Board] Get Card Success",
  props<{ card: ListCard }>()
);
export const getCardFailureAction = createAction(
  "[Board] Get Card Failure",
  props<{ error: string; errorCode: number }>()
);

export const getListBudgetInitAction = createAction(
  "[Board] Get List Budget Init"
);
export const getListBudgetSuccessAction = createAction(
  "[Board] Get List Budget Success",
  props<{
    totalBudget: Record<string, number>;
    totalProbability: Record<string, number>;
    id: string;
  }>()
);
export const getListBudgetFailureAction = createAction(
  "[Board] Get List Budget Failure",
  props<{ error: string }>()
);

export const putCardInitAction = createAction("[Board] Put Card Init");
export const putCardSuccessAction = createAction(
  "[Board] Put Card Success",
  props<{ card: ListCard }>()
);
export const putCardFailureAction = createAction(
  "[Board] Put Card Failure",
  props<{ errorCode: number; errorMessage: string }>()
);

export const postFilesInitAction = createAction("[Board] Post Files Init");
export const postFilesSuccessAction = createAction(
  "[Board] Post Files Success",
  props<{ files: File[] }>()
);
export const postFilesFailureAction = createAction(
  "[Board] Post Files Failure",
  props<{ error: string }>()
);

export const deleteFileInitAction = createAction("[Board] Delete File Init");
export const deleteFileSuccessAction = createAction(
  "[Board] Delete File Success",
  props<{ id: string }>()
);
export const deleteFileFailureAction = createAction(
  "[Board] Delete File Failure",
  props<{ error: string }>()
);

export const getCardTagsInitAction = createAction("[Board] Get Card Tag Init");
export const getCardTagsSuccessAction = createAction(
  "[Board] Get Card Tag Success",
  props<{ tags: CardTag[] }>()
);
export const getCardTagsFailureAction = createAction(
  "[Board] Get Card Tag Failure",
  props<{ error: string }>()
);

export const postCardTagInitAction = createAction("[Board] Post Card Tag Init");
export const postCardTagSuccessAction = createAction(
  "[Board] Post Card Tag Success",
  props<{ tag: CardTag }>()
);
export const postCardTagFailureAction = createAction(
  "[Board] Post Card Tag Failure",
  props<{ error: string }>()
);

export const putCardTagInitAction = createAction("[Board] Put Card Tag Init");
export const putCardTagSuccessAction = createAction(
  "[Board] Put Card Tag Success",
  props<{ tag: CardTag }>()
);
export const putCardTagFailureAction = createAction(
  "[Board] Put Card Tag Failure",
  props<{ error: string }>()
);

export const deleteCardTagInitAction = createAction(
  "[Board] Delete Card Tag Init"
);
export const deleteCardTagSuccessAction = createAction(
  "[Board] Delete Card Tag Success",
  props<{ id: string }>()
);
export const deleteCardTagFailureAction = createAction(
  "[Board] Delete Card Tag Failure",
  props<{ error: string }>()
);

export const postTagInitAction = createAction("[Board] Post Tag Init");
export const postTagSuccessAction = createAction(
  "[Board] Post Tag Success",
  props<{ tag: CardTag }>()
);
export const postTagFailureAction = createAction(
  "[Board] Post Tag Failure",
  props<{ error: string }>()
);

export const deleteTagInitAction = createAction("[Board] Delete Tag Init");
export const deleteTagSuccessAction = createAction(
  "[Board] Delete Tag Success",
  props<{ id: string }>()
);
export const deleteTagFailureAction = createAction(
  "[Board] Delete Tag Failure",
  props<{ error: string }>()
);

export const getListCardsInitAction = createAction(
  "[Board] Get List Cards Init"
);
export const getListCardsSuccessAction = createAction(
  "[Board] Get List Cards Success",
  props<{ cards: BoardListCard[]; list: string }>()
);
export const getListCardsFailureAction = createAction(
  "[Board] Get List Cards Failure",
  props<{ error: string }>()
);

export const postProductInitAction = createAction("[Board] Post Product Init");
export const postProductSuccessAction = createAction(
  "[Board] Post Product Success",
  props<{ product: ProductBoard }>()
);
export const postProductFailureAction = createAction(
  "[Board] Post Product Failure",
  props<{ error: string }>()
);

export const putProductInitAction = createAction("[Board] Put Product Init");
export const putProductSuccessAction = createAction(
  "[Board] Put Product Success",
  props<{ product: ProductBoard }>()
);
export const putProductFailureAction = createAction(
  "[Board] Put Product Failure",
  props<{ error: string }>()
);

export const deleteProductInitAction = createAction(
  "[Board] Delete Product Init"
);
export const deleteProductSuccessAction = createAction(
  "[Board] Delete Product Success",
  props<{ id: string }>()
);
export const deleteProductFailureAction = createAction(
  "[Board] Delete Product Failure",
  props<{ error: string }>()
);

export const postQuoteInitAction = createAction("[Board] Post Quote Init");
export const postQuoteSuccessAction = createAction(
  "[Board] Post Quote Success",
  props<{ quote: Quote }>()
);
export const postQuoteFailureAction = createAction(
  "[Board] Post Quote Failure",
  props<{ error: string }>()
);

export const postListRestrictionsInitAction = createAction(
  "[Board] Post List Restrictions"
);
export const getListRestrictionsInitAction = createAction(
  "[Board] Get List Restrictions"
);
export const getListRestrictionsSuccessAction = createAction(
  "[Board] Get List Restrictions Success",
  props<{ restrictions: ListRestriction[] }>()
);

export const getCustomFieldsInitAction = createAction(
  "[Board] Get Board Custom Fields Init"
);

export const getCustomFieldsSuccessAction = createAction(
  "[Board] Get Board Custom Fields Success",
  props<{ fields: CustomField[] }>()
);
export const getCustomFieldsFailureAction = createAction(
  "[Board] Get Board Custom Fields Failure",
  props<{ error: string }>()
);

export const postCustomFieldInitAction = createAction(
  "[Board] Post Board Custom Field Init"
);
export const postCustomFieldSuccessAction = createAction(
  "[Board] Post Board Custom Field Success",
  props<{ field: CustomField }>()
);
export const postCustomFieldFailureAction = createAction(
  "[Board] Post Board Custom Field Failure",
  props<{ error: string }>()
);

export const putCustomFieldInitAction = createAction(
  "[Board] Put Board Custom Field Init"
);
export const putCustomFieldSuccessAction = createAction(
  "[Board] Put Board Custom Field Success",
  props<{ field: CustomField }>()
);
export const putCustomFieldFailureAction = createAction(
  "[Board] Put Board Custom Field Failure",
  props<{ error: string }>()
);

export const deleteCustomFieldInitAction = createAction(
  "[Board] Delete Board Custom Field Init"
);
export const deleteCustomFieldSuccessAction = createAction(
  "[Board] Delete Board Custom Field Success",
  props<{ id: string }>()
);
export const deleteCustomFieldFailureAction = createAction(
  "[Board] Delete Board Custom Field Failure",
  props<{ error: string }>()
);

export const getListContactsInitAction = createAction(
  "[Board] Get List Contacts Init"
);
export const getListContactsSuccessAction = createAction(
  "[Board] Get List Contacts Success",
  props<{ contacts: ContactBoard[] }>()
);
export const getListContactsFailureAction = createAction(
  "[Board] Get List Contacts Failure",
  props<{ error: string }>()
);

export const putMoveCardsInitAction = createAction(
  "[Board] Put Move Cards Init"
);
export const putMoveCardsSuccessAction = createAction(
  "[Board] Put Move Cards Success"
);
export const putMoveCardsFailureAction = createAction(
  "[Board] Put Move Cards Failure",
  props<{ error: string }>()
);

export const postBoardImportInitAction = createAction(
  "[Board] Post Board import Init"
);
export const postBoardImportSuccessAction = createAction(
  "[Board] Post Board import Success",
  props<{ id: string }>()
);
export const postBoardImportFailureAction = createAction(
  "[Board] Post Board import Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  getBoardsInitAction,
  getListRestrictionsInitAction,
  getListRestrictionsSuccessAction,
  getBoardsSuccessAction,
  getBoardsFailureAction,
  getBoardBusinessInitAction,
  getBoardActivityInitAction,
  getBoardSuccessAction,
  getBoardFailureAction,
  getCardInitAction,
  getCardSuccessAction,
  getCardFailureAction,
  postBoardInitAction,
  postBoardSuccessAction,
  postBoardFailureAction,
  postListInitAction,
  postListSuccessAction,
  postListFailureAction,
  putListInitAction,
  putListSuccessAction,
  putListFailureAction,
  postCardInitAction,
  postCardSuccessAction,
  postCardFailureAction,
  putCardInitAction,
  putCardSuccessAction,
  putCardFailureAction,
  postCommentInitAction,
  postCommentSuccessAction,
  postCommentFailureAction,
  deleteCommentInitAction,
  deleteCommentSuccessAction,
  deleteCommentFailureAction,
  putCommentInitAction,
  putCommentSuccessAction,
  putCommentFailureAction,
  putBoardInitAction,
  putBoardSuccessAction,
  putBoardFailureAction,
  deleteCardInitAction,
  deleteCardSuccessAction,
  deleteCardFailureAction,
  deleteBoardInitAction,
  deleteBoardSuccessAction,
  deleteBoardFailureAction,
  deleteListInitAction,
  deleteListSuccessAction,
  deleteListFailureAction,
  putListOrderInitAction,
  putListOrderSuccessAction,
  putListOrderFailureAction,
  putCardOrderInitAction,
  putCardOrderSuccessAction,
  putCardOrderFailureAction,
  postAssignmentInitAction,
  postAssignmentSuccessAction,
  postAssignmentFailureAction,
  deleteAssignmentInitAction,
  deleteAssignmentSuccessAction,
  deleteAssignmentFailureAction,
  postContactInitAction,
  postContactSuccessAction,
  postContactFailureAction,
  deleteContactInitAction,
  deleteContactSuccessAction,
  deleteContactFailureAction,
  postTaggingInitAction,
  postTaggingSuccessAction,
  postTaggingFailureAction,
  postFilesInitAction,
  postFilesSuccessAction,
  postFilesFailureAction,
  deleteFileInitAction,
  deleteFileSuccessAction,
  deleteFileFailureAction,
  getListsInitAction,
  getListsSuccessAction,
  getListsFailureAction,
  getCardTagsInitAction,
  getCardTagsSuccessAction,
  getCardTagsFailureAction,
  postCardTagInitAction,
  postCardTagSuccessAction,
  postCardTagFailureAction,
  putCardTagInitAction,
  putCardTagSuccessAction,
  putCardTagFailureAction,
  deleteCardTagInitAction,
  deleteCardTagSuccessAction,
  deleteCardTagFailureAction,
  postTagInitAction,
  postTagSuccessAction,
  postTagFailureAction,
  deleteTagInitAction,
  deleteTagSuccessAction,
  deleteTagFailureAction,
  getListCardsInitAction,
  getListCardsSuccessAction,
  getListCardsFailureAction,
  getListBudgetInitAction,
  getListBudgetSuccessAction,
  getListBudgetFailureAction,
  postCompanyInitAction,
  postCompanySuccessAction,
  postCompanyFailureAction,
  deleteCompanyInitAction,
  deleteCompanySuccessAction,
  deleteCompanyFailureAction,
  postProductInitAction,
  postProductSuccessAction,
  postProductFailureAction,
  putProductInitAction,
  putProductSuccessAction,
  putProductFailureAction,
  deleteProductInitAction,
  deleteProductSuccessAction,
  deleteProductFailureAction,
  postQuoteInitAction,
  postQuoteSuccessAction,
  postQuoteFailureAction,
  getBoardExportInitAction,
  getBoardExportSuccessAction,
  getBoardExportFailureAction,
  getCustomFieldsInitAction,
  getCustomFieldsSuccessAction,
  getCustomFieldsFailureAction,
  postCustomFieldInitAction,
  postCustomFieldSuccessAction,
  postCustomFieldFailureAction,
  putCustomFieldInitAction,
  putCustomFieldSuccessAction,
  putCustomFieldFailureAction,
  deleteCustomFieldInitAction,
  deleteCustomFieldSuccessAction,
  deleteCustomFieldFailureAction,
  getListContactsInitAction,
  getListContactsSuccessAction,
  getListContactsFailureAction,
  putMoveCardsInitAction,
  putMoveCardsSuccessAction,
  putMoveCardsFailureAction,
  postBoardImportInitAction,
  postBoardImportSuccessAction,
  postBoardImportFailureAction,
  postListRestrictionsInitAction,
});

export type BoardApiAction = typeof actions;
