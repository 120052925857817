import socialApiClient from "../../helpers/services/social-api-client";
import { GetOpenAiBindResponse } from "../interface/api/get-openai-bind";
import { GetSocialTemplatesResponse } from "../interface/api/get-social-templates";
import { PostOpenAiBindRequest, PostOpenAiBindResponse } from "../interface/api/post-openai-bind";
import { openAiSettingMapper } from "./mapper";

export const openAiApiService = {
  unbind(companyId: string) {
    return socialApiClient()
      .post(`openai/unbind`, {}, { params: { companyId } })
      .then(({ data }) => data);
  },
  bind(companyId: string, body: PostOpenAiBindRequest) {
    return socialApiClient()
      .post<PostOpenAiBindResponse>(`openai/bind`, body, {
        params: { companyId },
      })
      .then(({ data }) =>
        openAiSettingMapper.mapResponseToOpenAiSetting(data)
      );
  },
  get(companyId: string) {
    return socialApiClient()
      .get<GetOpenAiBindResponse>(`openai`, {
        params: { companyId },
      })
      .then(({ data }) =>
        openAiSettingMapper.mapResponseToOpenAiSetting(data)
      );
  },
  getSocialPosts(companyId: string, body: any) {
    return socialApiClient()
      .post<GetSocialTemplatesResponse>(`openai/posts`, body, {
        params: { companyId },
      })
      .then(({ data }) => 
        openAiSettingMapper.mapResponseToSocialTemplates(data)
      );
  },
  getEmailPosts(companyId: string, body: any) {
    return socialApiClient()
      .post<GetSocialTemplatesResponse>(`openai/email/posts`, body, {
        params: { companyId },
      })
      .then(({ data }) => 
        openAiSettingMapper.mapResponseToSocialTemplates(data)
      );
  },
};
