import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";

import {
  getTiktokFailureAction,
  getTiktokInitAction,
  getTiktokLoginFailureAction,
  getTiktokLoginInitAction,
  getTiktokLoginSuccessAction,
  getTiktokProfilesFailureAction,
  getTiktokProfilesInitAction,
  getTiktokProfilesSuccessAction,
  getTiktokSuccessAction,
  postBindTiktokFailureAction,
  postBindTiktokInitAction,
  postBindTiktokSuccessAction,
  postUnbindTiktokFailureAction,
  postUnbindTiktokInitAction,
  postUnbindTiktokSuccessAction,
  putTiktokSocialPostFailureAction,
  putTiktokSocialPostInitAction,
  putTiktokSocialPostSuccessAction,
  TiktokApiAction,
} from "./api-actions";
import { TiktokSetting } from "../interface/api/post-tiktok-bind";
import { TiktokProfile } from "../interface/api/get-tiktok-profiles";

export interface TiktokState {
  settings: TiktokSetting | null;
  url: string;
  profiles: TiktokProfile[];
  error: string;
}

const initialState: TiktokState = {
  settings: null,
  profiles: [],
  url: "",
  error: "",
};

export default produce(
  (
    draft: TiktokState = initialState,
    action: TiktokApiAction | GlobalActions
  ) => {
    switch (action.type) {
      case postBindTiktokSuccessAction.type:
      case getTiktokSuccessAction.type:
        draft.settings = action.settings;
        return draft;
      case postUnbindTiktokFailureAction.type:
      case postBindTiktokFailureAction.type:
      case getTiktokFailureAction.type:
      case getTiktokLoginFailureAction.type:
        draft.error = action.error;
        return draft;
      case getTiktokProfilesSuccessAction.type:
        draft.profiles = action.profiles;
        return draft;
      case postUnbindTiktokSuccessAction.type:
        draft.settings = null;
        return draft;
      case getTiktokLoginSuccessAction.type:
        draft.url = action.url;
        return draft;
      case getTiktokLoginInitAction.type:
        draft.url = "";
        return draft;
      case resetAction.type:
        return initialState;
      case postBindTiktokInitAction.type:
      case postUnbindTiktokInitAction.type:
      case getTiktokInitAction.type:
      case putTiktokSocialPostInitAction.type:
      case putTiktokSocialPostSuccessAction.type:
      case putTiktokSocialPostFailureAction.type:
      case getTiktokProfilesInitAction.type:
      case getTiktokProfilesFailureAction.type:
      default:
        return draft;
    }
  }
);
