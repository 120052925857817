import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { TiktokProfile } from "../interface/api/get-tiktok-profiles";
import { TiktokSetting } from "../interface/api/post-tiktok-bind";

export const postUnbindTiktokInitAction = createAction(
  "[Tiktok] Unbind Tiktok Init"
);
export const postUnbindTiktokSuccessAction = createAction(
  "[Tiktok] Unbind Tiktok Success"
);
export const postUnbindTiktokFailureAction = createAction(
  "[Tiktok] Unbind Tiktok Failure",
  props<{ error: string }>()
);

export const postBindTiktokInitAction = createAction(
  "[Tiktok] Bind Tiktok Init"
);
export const postBindTiktokSuccessAction = createAction(
  "[Tiktok] Bind Tiktok Success",
  props<{ settings: TiktokSetting }>()
);

export const postBindTiktokFailureAction = createAction(
  "[Tiktok] Bind Tiktok Failure",
  props<{ error: string }>()
);

export const getTiktokInitAction = createAction(
  "[Tiktok] Get Tiktok Settings Init"
);
export const getTiktokSuccessAction = createAction(
  "[Tiktok] Get Tiktok Settings Success",
  props<{ settings: TiktokSetting }>()
);
export const getTiktokFailureAction = createAction(
  "[Tiktok] Get Tiktok Settings Failure",
  props<{ error: string }>()
);

export const getTiktokLoginInitAction = createAction(
  "[Tiktok] Get Tiktok Login Init"
);
export const getTiktokLoginSuccessAction = createAction(
  "[Tiktok] Get Tiktok Login Success",
  props<{ url: string }>()
);
export const getTiktokLoginFailureAction = createAction(
  "[Tiktok] Get Tiktok Login Failure",
  props<{ error: string }>()
);

export const postTiktokSocialPostInitAction = createAction(
  "[Tiktok] Social Post Init"
);
export const postTiktokSocialPostSuccessAction = createAction(
  "[Tiktok] Social Post Success"
);
export const postTiktokSocialPostFailureAction = createAction(
  "[Tiktok] Social Post Failure",
  props<{ error: string }>()
);

export const putTiktokSocialPostInitAction = createAction(
  "[Tiktok] Social Put Init"
);
export const putTiktokSocialPostSuccessAction = createAction(
  "[Tiktok] Social Put Success"
);
export const putTiktokSocialPostFailureAction = createAction(
  "[Tiktok] Social Put Failure",
  props<{ error: string }>()
);

export const getTiktokProfilesInitAction = createAction(
  "[Tiktok] Get Tiktok Profiles Init"
);
export const getTiktokProfilesSuccessAction = createAction(
  "[Tiktok] Get Tiktok Profiles Success",
  props<{ profiles: TiktokProfile[] }>()
);
export const getTiktokProfilesFailureAction = createAction(
  "[Tiktok] Get Tiktok Profiles Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  postUnbindTiktokInitAction,
  postUnbindTiktokSuccessAction,
  postUnbindTiktokFailureAction,
  postBindTiktokInitAction,
  postBindTiktokSuccessAction,
  postBindTiktokFailureAction,
  getTiktokInitAction,
  getTiktokSuccessAction,
  getTiktokFailureAction,
  getTiktokLoginInitAction,
  getTiktokLoginSuccessAction,
  getTiktokLoginFailureAction,
  postTiktokSocialPostInitAction,
  postTiktokSocialPostSuccessAction,
  postTiktokSocialPostFailureAction,
  putTiktokSocialPostInitAction,
  putTiktokSocialPostSuccessAction,
  putTiktokSocialPostFailureAction,
  getTiktokProfilesInitAction,
  getTiktokProfilesSuccessAction,
  getTiktokProfilesFailureAction,
});

export type TiktokApiAction = typeof actions;
