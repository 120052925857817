import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSyncAlt,
  faSignOutAlt,
  faUser,
  faBoxOpen,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faLinkedin,
  faTiktok,
  faWhatsapp,
  faGoogle,
  faMailchimp,
  faFacebookMessenger,
} from "@fortawesome/free-brands-svg-icons";
import FacebookAuthButton from "../social/Facebook/FacebookAuthButton";
import { SocialProvider } from "../../../cores/socialPost/interfaces/socialProvider";
import InstagramAuthButton from "../social/Instagram/InstagramBindCard";
import LinkedInAuthButton from "../social/LinkedIn/LinkedInBindCard";
import { SocialProviderId } from "../../../cores/socialPost/config";
import FacebookAdsAuthButton from "../social/FacebookAds/FacebookAdsBindCard";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import GmailAuthButton from "../social/Gmail/GmailBindCard";
import GoogleAnalyticsAuthButton from "../social/GoogleAnalitycs/GoogleBindCard";
import MailchimpAuthButton from "../social/Mailchimp/MailchimpBindCard";
import MercadolibreAuthButton from "../social/Mercadolibre/MercadolibreBindCard";
import Office365AuthButton from "../social/Office365/Office365BindCard";
import OpenAiAuthButton from "../social/OpenAi/OpenAiBindCard";
import SmtpAuthButton from "../social/Smtp/SmtpBindCard";
import WhatsappAuthButton from "../social/Whatsapp/WhatsappBindCard";
import WooCommerceAuthButton from "../social/WooCommerce/WooCommerceBindCard";
import JumpSellerAuthButton from "../social/Jumpseller/JumpsellerBindCard";
import { Office365Icon } from "../social/Office365/Office365Icon";
import TiktokAuthButton from "../social/Tiktok/TiktokBindCard";

// Tipo de Props
type SyncAccountProps = {
  network: string;
  existingAccounts: SocialProvider[];
  onSync: (newAccount: string) => void;
  onDisconnect: (account: string) => void;
};

const SyncAccount = ({
  network,
  existingAccounts,
  onSync,
  onDisconnect,
}: SyncAccountProps) => {
  const [newAccount, setNewAccount] = useState("");

  const handleSyncClick = () => {
    onSync(newAccount);
  };

  const handleDisconnect = (account: string) => {
    onDisconnect(account);
  };

  const getNetworkIcon = () => {
    switch (network) {
      case SocialProviderId.Facebook:
        return faFacebook;
      case SocialProviderId.Instagram:
        return faInstagram;
      case SocialProviderId.Twitter:
        return faTwitter;
      case SocialProviderId.LinkedIn:
        return faLinkedin;
      case SocialProviderId.TikTok:
        return faTiktok;
      case SocialProviderId.Whatsapp:
        return faWhatsapp;
      case SocialProviderId.Gmail:
        return faGoogle;
      case SocialProviderId.Google:
        return faGoogle;
      case SocialProviderId.GoogleAds:
        return faGoogle;
      case SocialProviderId.Mailchimp:
        return faMailchimp;
      case SocialProviderId.FacebookAds:
        return faFacebook; // Same as Facebook for Ads
      case SocialProviderId.OpenAi:
        return faBoxOpen; // OpenAI
      case SocialProviderId.WooCommerce:
        return faShoppingCart; // WooCommerce
      case SocialProviderId.Mercadolibre:
        return faShoppingCart; // MercadoLibre
      case SocialProviderId.Jumpseller:
        return faBoxOpen; // Jumpseller
      case SocialProviderId.Office365:
        return faBoxOpen; // Office365
      case SocialProviderId.InstagramMessenger:
        return faFacebookMessenger; // Instagram Messenger (same as Facebook)
      case SocialProviderId.Smtp:
        return faEnvelope; // SMTP
      case SocialProviderId.LinkedInBusiness:
        return faEnvelope; // SMTP
      case SocialProviderId.GoogleCalendar:
        return faEnvelope; // SMTP
      default:
        return faSyncAlt; // Default icon
    }
  };

  const getNetworkDescription = () => {
    switch (network) {
      case SocialProviderId.Facebook:
        return "Conecta tu cuenta de Facebook para gestionar publicaciones, anuncios y mucho más en la plataforma.";
      case SocialProviderId.Instagram:
        return "Conecta tu cuenta de Instagram para gestionar tus publicaciones, mensajes y estadísticas de tus seguidores.";
      case SocialProviderId.Twitter:
        return "Conecta tu cuenta de Twitter para gestionar tus tweets y seguir tus menciones.";
      case SocialProviderId.LinkedIn:
        return "Conecta tu cuenta de LinkedIn para gestionar tu red profesional y tus publicaciones.";
      case SocialProviderId.TikTok:
        return "Conecta tu cuenta de TikTok para administrar tus videos y gestionar tu contenido.";
      case SocialProviderId.Whatsapp:
        return "Conecta tu cuenta de WhatsApp para gestionar tus mensajes y comunicaciones con tus contactos.";
      case SocialProviderId.Gmail:
        return "Conecta tu cuenta de Gmail para gestionar tus correos electrónicos directamente desde Wembii.";
      case SocialProviderId.GoogleAds:
        return "Conecta tu cuenta de Google para acceder a tus estadísticas de Google Analytics";
      case SocialProviderId.Mailchimp:
        return "Conecta Mailchimp para gestionar tus campañas de email marketing y seguimiento.";
      case SocialProviderId.FacebookAds:
        return "Conecta Facebook Ads para gestionar tus campañas publicitarias y anuncios en la plataforma.";
      case SocialProviderId.OpenAi:
        return "Conecta tu cuenta de OpenAI para integrar inteligencia artificial y automatizar procesos.";
      case SocialProviderId.WooCommerce:
        return "Conecta WooCommerce para gestionar tus productos y ventas en línea.";
      case SocialProviderId.Mercadolibre:
        return "Conecta MercadoLibre para gestionar tus productos y ventas en la plataforma.";
      case SocialProviderId.Jumpseller:
        return "Conecta Jumpseller para gestionar tu tienda en línea.";
      case SocialProviderId.Office365:
        return "Conecta tu cuenta de Office 365 para sincronizar tus correos y archivos de trabajo.";
      case SocialProviderId.InstagramMessenger:
        return "Conecta Instagram Messenger para gestionar tus conversaciones directamente desde Wembii.";
      case SocialProviderId.Smtp:
        return "Conecta un servidor SMTP para enviar correos electrónicos personalizados y automatizados.";
      default:
        return "Conecta tu cuenta para empezar a gestionar tu presencia digital.";
    }
  };
  return (
    <>
      {existingAccounts.length > 0 ? (
        <div>
          <ul className="list-unstyled">
            {existingAccounts.map((account, index) => (
              <li
                key={index}
                className="mb-3 d-flex justify-content-between align-items-center"
              >
                <div className="d-flex align-items-center">
                  {account.avatar !== undefined && (
                    <img
                      className="socialCard-avatar rounded-full position-absolute"
                      src={account.avatar}
                      loading="lazy"
                      alt="avatar"
                      style={{
                        width: "45px",
                        height: "45px ",
                        top: 40,
                        right: 90,
                      }}
                      width={45}
                      height={45}
                    />
                  )}{" "}
                  <span>{account.name}</span>
                </div>
                <button
                  className="btn btn-outline-danger btn-sm"
                  onClick={() => handleDisconnect(account.id)}
                >
                  <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />
                  Desconectar
                </button>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div
          className="alert alert-info d-flex align-items-center p-4 rounded shadow-sm"
          role="alert"
          style={{
            backgroundColor: "#e9f7fa",
            borderLeft: "5px solid #007bff",
            borderRadius: "8px",
          }}
        >
          {network === "Office365" ? (
            <Office365Icon size="6x" active />
          ) : (
            <FontAwesomeIcon
              icon={getNetworkIcon()}
              className="me-4"
              style={{
                fontSize: "4rem",
                color: "#007bff",
              }}
            />
          )}
          <div>
            <h5
              className="mb-2"
              style={{ fontWeight: "bold", fontSize: "1.5rem" }}
            >
              {network}
            </h5>
            <p className="mb-2">{getNetworkDescription()}</p>
            <p className="mb-2">
              No tienes cuentas conectadas a esta red social.
            </p>
            <p className="mb-3" style={{ fontSize: "1rem" }}>
              Haz clic en "Sincronizar Cuenta Nueva" para agregar una cuenta.
            </p>
            {network === SocialProviderId.Facebook ? (
              <FacebookAuthButton />
            ) : network === SocialProviderId.Instagram ? (
              <InstagramAuthButton />
            ) : network === SocialProviderId.LinkedIn ? (
              <LinkedInAuthButton />
            ) : network === SocialProviderId.FacebookAds ? (
              <FacebookAdsAuthButton />
            ) : network === SocialProviderId.GoogleAds ? (
              <GoogleAnalyticsAuthButton />
            ) : network === SocialProviderId.Gmail ? (
              <GmailAuthButton />
            ) : network === SocialProviderId.Office365 ? (
              <Office365AuthButton />
            ) : network === SocialProviderId.Smtp ? (
              <SmtpAuthButton />
            ) : network === SocialProviderId.WooCommerce ? (
              <WooCommerceAuthButton />
            ) : network === SocialProviderId.Mercadolibre ? (
              <MercadolibreAuthButton />
            ) : network === SocialProviderId.Jumpseller ? (
              <JumpSellerAuthButton />
            ) : network === SocialProviderId.Mailchimp ? (
              <MailchimpAuthButton />
            ) : network === SocialProviderId.Whatsapp ? (
              <WhatsappAuthButton />
            ) : network === SocialProviderId.OpenAi ||
              network === SocialProviderId.OpenAI ? (
              <OpenAiAuthButton />
            ) : network === SocialProviderId.TikTok ? (
              <TiktokAuthButton />
            ) : (
              <button className="btn btn-outline- disabled" disabled>
                <FontAwesomeIcon icon={faSyncAlt} className="me-2" />
                Integración no disponible
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SyncAccount;
