import { GetTiktokBindResponse } from "../interface/api/get-tiktok-bind";
import { GetTiktokLoginResponse } from "../interface/api/get-tiktok-login";
import {
  GetTiktokProfilesPayload,
  GetTiktokProfilesResponse,
} from "../interface/api/get-tiktok-profiles";
import { TiktokSetting } from "../interface/api/post-tiktok-bind";
export const tiktokSettingMapper = {
  mapResponseToTiktokSetting(response: GetTiktokBindResponse): TiktokSetting {
    const { tiktokSetting } = response?.payload;
    return tiktokSetting;
  },

  mapResponseToTiktokLoginUrl(response: GetTiktokLoginResponse): {
    url: string;
  } {
    const { url } = response?.payload;
    return { url };
  },

  mapResponseToTiktokProfiles(
    response: GetTiktokProfilesResponse
  ): GetTiktokProfilesPayload {
    const { profiles } = response?.payload;
    return { profiles };
  },
};

