import React from "react";
import cn from "classnames";
import { Route, Switch } from "react-router-dom";
import {
  MAIN_LAYOUT_LOADING,
  SMTP_SYNC_LOADING,
} from "../../constants/loading.constant";
import {
  CHANGE_SUBSCRIPTION_MODAL,
  ME_USER_EDIT_FORM,
  SMTP_ME_BIND_MODAL,
} from "../../constants/modal.constant";
import { useGlobalCore } from "../../cores/globals";
import { useMeCore } from "../../cores/me";
import { useRouterCore } from "../../cores/router";
import ProtectedRoute from "../component/companies/ProtectedRoute";
import { LoadingSpinnerWembii } from "../component/LoadingSpinnerWembii";
import NavBar from "../component/navBar/NavBar";
import NoConfirmedModal from "../component/NoConfirmedModal";
import GooglePopUp from "../component/social/GoogleAnalitycs/GooglePopUp";
import JumpsellerPopUp from "../component/social/Jumpseller/JumpsellerPopUp";
import MailchimpPopUp from "../component/social/Mailchimp/MailchimpPopUp";
import LinkedInPopUp from "../component/social/LinkedIn/LinkedInPopUp";
import MercadolibrePopUp from "../component/social/Mercadolibre/MercadolibrePopUp";
import TwitterPopUp from "../component/social/Twitter/TwitterPopUp";
import WooCommercePopUp from "../component/social/WooCommerce/WooCommercePopUp";
import { ActivityBoardsScreen } from "../screen/ActivityBoards";
import { BusinessBoardScreen } from "../screen/BusinessBoard";
import { BusinessBoardAnalyticsScreen } from "../screen/BusinessBoardAnalytics";
import Companies from "../screen/Companies";
import { ContactsScreen } from "../screen/Contacts";
import CreateCampaign from "../screen/CreateCampaign";
import ActivityBoard from "../screen/ActivityBoard";
import EditCompany from "../screen/EditCompany";
import EditWebChat from "../screen/EditWebChat";
import EditWebForm from "../screen/EditWebForm";
import EditWhatsappChat from "../screen/EditWhatsappChat";
import { FacebookAnalytics } from "../screen/FacebookAnalytics";
import FacebookCampaigns from "../screen/FacebookCampaigns";
import FacebookForms from "../screen/FacebookForms";
import { GoogleAnalytics } from "../screen/GoogleAnalytics";
import { InstagramAnalytics } from "../screen/InstagramAnalytics";
import { JumpsellerMarket } from "../screen/JumpsellerMarket";
import MeCompanies from "../screen/MeCompanies";
import { MercadolibreAnalytics } from "../screen/MercadolibreAnalytics";
import { MercadolibreMarket } from "../screen/MercadolibreMarket";
import { ReportsScreen } from "../screen/Reports";
import Resellers from "../screen/Reseller";
import Social from "../screen/Social";
import SocialCalendar from "../screen/SocialCalendar";
import SocialHistory from "../screen/SocialHistory";
import SocialManager from "../screen/SocialManager";
import SocialPost from "../screen/SocialPost";
import Users from "../screen/Users";
import ViewFacebookForm from "../screen/ViewFacebookForm";
import WebChat from "../screen/WebChat";
import WebForms from "../screen/WebForms";
import { WooCommerceAnalytics } from "../screen/WooCommerceAnalytics";
import { WooCommerceMarket } from "../screen/WooCommerceMarket";
import SideModal from "./SideModal";
import { TasksScreen } from "../screen/Tasks";
import GmailPopUp from "../component/social/Gmail/GmailPopUp";
import GoogleCalendarPopUp from "../component/social/GoogleCalendar/GoogleCalendarPopUp";
import SocialStory from "../screen/SocialStory";
import { ContactCompaniesScreen } from "../screen/ContactCompanies";
import { AutomationsScreen } from "../screen/Automations";
import { CalendarScreen } from "../screen/Calendar";
import TemporalPasswordModal from "../component/TemporalPasswordModal";
import { Plans } from "../screen/Plans";
import Office365PopUp1 from "../component/social/Office365/Office365PopUp1";
import Office365PopUp2 from "../component/social/Office365/Office365PopUp2";
import Sidebar from "../component/sideBar/SideBar";
import Notifications from "../screen/Notifications";
import Home from "../screen/Home";
import TiktokPopUp from "../component/social/Tiktok/TiktokPopUp";
export default function MainLayout() {
  const { routes } = useRouterCore();
  const { company, user, isAdmin } = useMeCore();
  const {
    isModal,
    turnOffModal,
    getModalContent,
    getModalVisibility,
    turnOffLoading,
    openSideBar,
    collapseSideBar,
  } = useGlobalCore();

  return (
    <div className="d-flex h-100 w-100">
      <div
        className={cn("sidebar p-3 d-none d-lg-block overflow-hidden", {
          "d-flex flex-column position-relative d-lg-block": openSideBar,
        })}
      >
        <Sidebar />
      </div>
      <div
        className={cn("p-md-3 pt-3", {
          mainSection: !openSideBar,
          mainSectionCollaped: !collapseSideBar,
          mainSectionExpanded: collapseSideBar,
        })}
      >
        <div className="mb-1">
          <NavBar />
        </div>
        <LoadingSpinnerWembii
          size="lg"
          id={MAIN_LAYOUT_LOADING}
          style={{ top: "40%" }}
        >
          <div
            style={{
              height: "calc(100vh - 100px)",
              overflowY: "auto",
              overflowX: "hidden",
            }}
            className="pt-2 pb-5"
          >
            <Switch>
              <Route path={routes.users} component={Users} />
              <Route path={routes.notifications} component={Notifications} />
              <Route exact path={routes.company} component={Companies} />
              <Route exact path={routes.meCompany} component={MeCompanies} />
              <Route path={routes.editCompany} component={EditCompany} />
              <Route path={routes.plans} component={Plans} />
              <ProtectedRoute
                exact
                path={routes.webForms}
                component={WebForms}
                open={Boolean(company?.id)}
              />
              <ProtectedRoute
                exact
                path={routes.editWebForm}
                component={EditWebForm}
                open={Boolean(company?.id)}
              />
              <ProtectedRoute
                exact
                path={routes.facebookForm}
                component={FacebookForms}
                open={Boolean(company?.id)}
              />
              <ProtectedRoute
                exact
                path={routes.viewFacebookForm}
                component={ViewFacebookForm}
                open={Boolean(company?.id)}
              />
              <ProtectedRoute
                exact
                path={routes.webChat}
                component={WebChat}
                open={Boolean(company?.id)}
              />
              <ProtectedRoute
                exact
                path={routes.editWebChat}
                component={EditWebChat}
                open={Boolean(company?.id)}
              />
              <ProtectedRoute
                exact
                path={routes.editWhatsappChat}
                component={EditWhatsappChat}
                open={Boolean(company?.id)}
              />
              <ProtectedRoute
                exact
                path={routes.activityBoards}
                component={ActivityBoardsScreen}
                open={Boolean(company?.id)}
              />
              <ProtectedRoute
                exact
                path={routes.businessBoard}
                component={BusinessBoardScreen}
                open={Boolean(company?.id)}
              />
              <ProtectedRoute
                exact
                path={routes.tasks}
                component={TasksScreen}
                open={Boolean(company?.id)}
              />
              <ProtectedRoute
                exact
                path={routes.businessBoardAnalytics}
                component={BusinessBoardAnalyticsScreen}
                open={Boolean(company?.id)}
              />
              <ProtectedRoute
                exact
                path={routes.editBoards}
                component={ActivityBoard}
                open={Boolean(company?.id)}
              />
              <ProtectedRoute
                exact
                path={routes.calendar}
                component={CalendarScreen}
                open={Boolean(company?.id)}
              />
              <ProtectedRoute
                exact
                path={routes.facebookCampaigns}
                component={FacebookCampaigns}
                open={Boolean(company?.id)}
              />
              <ProtectedRoute
                exact
                path={routes.createCampaign}
                component={CreateCampaign}
                open={Boolean(company?.id)}
              />
              <ProtectedRoute
                exact
                path={routes.social}
                component={Home}
                open={Boolean(company?.id)}
              />
              <ProtectedRoute
                exact
                path={routes.binding}
                component={Social}
                open={Boolean(company?.id)}
              />
              <ProtectedRoute
                path={routes.socialStory}
                component={SocialStory}
                open={Boolean(company?.id)}
              />
              <ProtectedRoute
                path={routes.socialPost}
                component={SocialPost}
                open={Boolean(company?.id)}
              />
              <ProtectedRoute
                exact
                path={routes.socialHistory}
                component={SocialHistory}
                open={Boolean(company?.id)}
              />
              <ProtectedRoute
                exact
                path={routes.socialManager}
                component={SocialManager}
                open={isAdmin}
              />
              <ProtectedRoute
                exact
                path={routes.socialCalendar}
                component={SocialCalendar}
                open={Boolean(company?.id)}
              />
              <Route path={routes.linkedInPopUp} component={LinkedInPopUp} />
              <Route path={routes.twitterPopUp} component={TwitterPopUp} />
              <Route path={routes.tiktokPopUp} component={TiktokPopUp} />
              <Route
                path={routes.facebookAnalytics}
                component={FacebookAnalytics}
              />
              <Route
                path={routes.wooCommerceAnalytics}
                component={WooCommerceAnalytics}
              />
              <Route
                path={routes.instagramAnalytics}
                component={InstagramAnalytics}
              />
              <Route
                path={routes.googleAnalytics}
                component={GoogleAnalytics}
              />
              <Route
                path={routes.googleCalendarPopUp}
                component={GoogleCalendarPopUp}
              />
              <Route path={routes.googlePopUp} component={GooglePopUp} />
              <Route path={routes.gmailPopUp} component={GmailPopUp} />
              <Route
                exact
                path={routes.office365PopUp1}
                component={Office365PopUp1}
              />
              <Route
                exact
                path={routes.office365PopUp2}
                component={Office365PopUp2}
              />
              <Route
                path={routes.jumpsellerPopUp}
                component={JumpsellerPopUp}
              />
              <Route path={routes.mailchimpPopUp} component={MailchimpPopUp} />
              <Route path={routes.wooPopUp} component={WooCommercePopUp} />
              <Route
                path={routes.mercadolibrePopUp}
                component={MercadolibrePopUp}
              />
              <Route
                path={routes.wooCommerceOrders}
                component={WooCommerceMarket}
              />
              <Route
                path={routes.mercadolibreOrders}
                component={MercadolibreMarket}
              />
              <Route
                path={routes.mercadolibreAnalytics}
                component={MercadolibreAnalytics}
              />
              <Route
                path={routes.jumpsellerOrders}
                component={JumpsellerMarket}
              />
              <Route path={routes.contacts} component={ContactsScreen} />
              <Route
                exact
                path={routes.contactCompanies}
                component={ContactCompaniesScreen}
              />
              <Route exact path={routes.reports} component={ReportsScreen} />
              <Route path={routes.resellers} component={Resellers} />
              <Route path={routes.automations} component={AutomationsScreen} />
            </Switch>
          </div>
        </LoadingSpinnerWembii>
        <SideModal
          active={isModal(CHANGE_SUBSCRIPTION_MODAL)}
          visible={getModalVisibility(CHANGE_SUBSCRIPTION_MODAL)}
          onClose={() => turnOffModal(CHANGE_SUBSCRIPTION_MODAL)}
        >
          {getModalContent(CHANGE_SUBSCRIPTION_MODAL)}
        </SideModal>
        <SideModal
          active={isModal(ME_USER_EDIT_FORM)}
          visible={getModalVisibility(ME_USER_EDIT_FORM)}
          onClose={() => turnOffModal(ME_USER_EDIT_FORM)}
        >
          {getModalContent(ME_USER_EDIT_FORM)}
        </SideModal>
        <SideModal
          active={isModal(SMTP_ME_BIND_MODAL)}
          visible={getModalVisibility(SMTP_ME_BIND_MODAL)}
          onClose={() => {
            turnOffModal(SMTP_ME_BIND_MODAL);
            turnOffLoading(SMTP_SYNC_LOADING);
          }}
          left
        >
          {getModalContent(SMTP_ME_BIND_MODAL)}
        </SideModal>
      </div>
      {user && !user.verified && <NoConfirmedModal />}
      {user && user.verified && user.temporalPassword && (
        <TemporalPasswordModal />
      )}
    </div>
  );
}
