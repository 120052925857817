import { produce } from "immer";
import { createEntityAdapter } from "../../helpers/store/create-entity-adapter";
import { ModalEntity, SidebarGroupEntity } from "../interfaces/globalEntity";
import {
  GlobalActions,
  globalInitAction,
  setGlobalLoadingAction,
  setLoadingAction,
  setModalAction,
  setInternalModalAction,
  setSideBarCollapsedAction,
  setSideBarOpenAction,
  deleteModalAction,
  deleteInternalModalAction,
  setOpenSidebarGroups,
  setIsOnlineAction,
} from "./actions";

export interface GlobalState {
  loadings: Record<string, boolean>;
  modals: {
    ids: string[];
    entities: Record<string, ModalEntity>;
  };
  internalModals: {
    ids: string[];
    entities: Record<string, ModalEntity>;
  };
  activeGroups: {
    ids: string[];
    entities: Record<string, SidebarGroupEntity>;
  };
  isOnline: boolean | null;
  globalLoading: boolean;
  inSideBarOpen: boolean;
  isSideBarCollapsed: boolean;
}

const initialState: GlobalState = {
  globalLoading: false,
  isOnline: null,
  loadings: {},
  modals: {
    ids: [],
    entities: {},
  },
  internalModals: {
    ids: [],
    entities: {},
  },
  activeGroups: {
    ids: [],
    entities: {},
  },
  inSideBarOpen: false,
  isSideBarCollapsed: true,
};

const modalsAdapter = createEntityAdapter<ModalEntity>((entity) => entity.id);
const sidebarGroupsAdapter = createEntityAdapter<SidebarGroupEntity>(
  (entity) => entity.id
);

export default produce(
  (draft: GlobalState = initialState, action: GlobalActions) => {
    switch (action.type) {
      case setModalAction.type:
        modalsAdapter.setOne(action.entity, draft.modals);
        modalsAdapter.moveToPosition(
          action.entity.id,
          draft.modals.ids.length - 1,
          draft.modals
        );
        return draft;
      case setIsOnlineAction.type:
        draft.isOnline = action.isOnline;
        return draft;
      case setInternalModalAction.type:
        modalsAdapter.setOne(action.entity, draft.internalModals);
        return draft;
      case deleteModalAction.type:
        modalsAdapter.removeOne(action.id, draft.modals);
        return draft;
      case deleteInternalModalAction.type:
        modalsAdapter.removeOne(action.id, draft.internalModals);
        return draft;
      case setSideBarOpenAction.type:
        draft.inSideBarOpen = action.value;
        return draft;
      case setSideBarCollapsedAction.type:
        draft.isSideBarCollapsed = action.value;
        return draft;
      case setLoadingAction.type:
        draft.loadings = {
          ...draft.loadings,
          [action.entity.id]: action.entity.value,
        };
        return draft;
      case setGlobalLoadingAction.type:
        draft.globalLoading = action.value;
        return draft;
      case globalInitAction.type:
        return initialState;
      case setOpenSidebarGroups.type:
        if (draft.activeGroups.entities[action.entity.id]) {
          sidebarGroupsAdapter.removeOne(action.entity.id, draft.activeGroups);
        } else {
          sidebarGroupsAdapter.setOne(action.entity, draft.activeGroups);
        }

        return draft;
      default:
        return draft;
    }
  }
);
