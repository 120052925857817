import { push } from "connected-react-router";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import routes from "./routes";

export function useRouterCore() {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const history = useHistory();

  const goTo = useCallback(
    async (route: string) => {
      try {
        dispatch(push(route));
      } catch (e) {
        console.error(e);
      }
    },
    [dispatch]
  );

  return {
    goToLogin: () => goTo(routes.login),
    goToBinding: () => goTo(routes.binding),
    gotoEmails: () => goTo(routes.emails),
    goToCreateAccount: () => goTo(routes.createAccount),
    goToPlans: () => goTo(routes.plans),
    goToUsers: () => goTo(routes.users),
    goToCompany: () => goTo(routes.company),
    goToMeCompany: () => goTo(routes.meCompany),
    goToSocial: () => goTo(routes.social),
    goToFacebookAnalytics: () => goTo(routes.facebookAnalytics),
    goToInstagramAnalytics: () => goTo(routes.instagramAnalytics),
    goToGoogleAnalytics: () => goTo(routes.googleAnalytics),
    goToWooCommerceAnalytics: () => goTo(routes.wooCommerceAnalytics),
    goToMercadolibreAnalytics: () => goTo(routes.mercadolibreAnalytics),
    goToWooCommerceOrders: () => goTo(routes.wooCommerceOrders),
    goToMercadolibreOrders: () => goTo(routes.mercadolibreOrders),
    goToJumpsellerOrders: () => goTo(routes.jumpsellerOrders),
    goToSocialPost: (from: string, repost = false) =>
      repost
        ? goTo(`${routes.socialPost}?from=${from}&repost=${repost}`)
        : goTo(`${routes.socialPost}?from=${from}`),
    goToSocialStory: (from: string) =>
      goTo(`${routes.socialStory}?from=${from}`),
    goToSocialHistory: () => goTo(routes.socialHistory),
    goToSocialManager: () => goTo(routes.socialManager),
    goToSocialCalendar: () => goTo(routes.socialCalendar),
    goToEditCompany: (id: string, from?: string) =>
      goTo(`${routes.company}/${id}?from=${from}`),
    goToWebForms: () => goTo(routes.webForms),
    goToEditWebForm: (id: string) => goTo(`${routes.webForms}/${id}`),
    goToFacebookForms: () => goTo(routes.facebookForm),
    goToViewFacebookForm: (id: string) => goTo(`${routes.facebookForm}/${id}`),
    goToEditWebChat: () => goTo(routes.editWebChat),
    goToWebChat: () => goTo(routes.webChat),
    goToEditWhatsappChat: () => goTo(routes.editWhatsappChat),
    goToFacebookCampaigns: () => goTo(routes.facebookCampaigns),
    goToCreateCampaign: () => goTo(routes.createCampaign),
    goToContacts: () => goTo(routes.contacts),
    goToContactCompanies: () => goTo(routes.contactCompanies),
    goToReports: () => goTo(routes.reports),
    goToResellers: () => goTo(routes.resellers),
    goToActivityBoards: () => goTo(routes.activityBoards),
    goToBusinessBoard: () => goTo(routes.businessBoard),
    goToTasks: () => goTo(routes.tasks),
    goToBusinessBoardAnalytics: () => goTo(routes.businessBoardAnalytics),
    goToEditBoard: (id: string) => goTo(`${routes.boards}/${id}`),
    goToEditActivityBoard: (id: string) =>
      goTo(`${routes.activityBoards}/${id}`),
    goToAutomations: () => goTo(routes.automations),
    goToCalendar: () => goTo(routes.calendar),
    goToNotifications: () => goTo(routes.notifications),

    routes,
    pathname,
    search,
    history,
  };
}
