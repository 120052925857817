import _, { Dictionary } from "lodash";
import React, { ReactNode, useCallback, useEffect, useMemo } from "react";
import { useMeCore } from "../../../../cores/me";
import { useSocialPostCore } from "../../../../cores/socialPost";
import {
  SocialProviderId,
  SocialProviderNameMapper,
  SocialProviderRules,
} from "../../../../cores/socialPost/config";
import { SOCIAL_POST_TYPES } from "../../../../cores/socialPost/consts/socialPostType.constant";
import {
  PostErrorType,
  SocialType,
} from "../../../../cores/socialPost/interfaces/postError";
import { SocialProvider } from "../../../../cores/socialPost/interfaces/socialProvider";
import { PanelWembii } from "../../PanelWembii";
import { FacebookIcon, IconProps } from "../../social/Facebook/FacebookIcon";
import { InstagramIcon } from "../../social/Instagram/InstagramIcon";
import { JumpsellerIcon } from "../../social/Jumpseller/JumpsellerIcon";
import { useIntl } from "react-intl";
import { LinkedInBusinessIcon } from "../../social/LinkedIn/LinkedInBusinessIcon";
import { LinkedInIcon } from "../../social/LinkedIn/LinkedInIcon";
import { MailchimpIcon } from "../../social/Mailchimp/MailchimpIcon";
import { TwitterIcon } from "../../social/Twitter/TwitterIcon";
import { WooCommerceIcon } from "../../social/WooCommerce/WooCommerceIcon";
import { SocialProviderIcon } from "./SocialProviderIcon";
import { TiktokIcon } from "../../social/Tiktok/TiktokIcon";

export const IconMapper: Dictionary<(props: IconProps) => ReactNode> = {
  facebook: (props) => <FacebookIcon {...props} />,
  instagram: (props) => <InstagramIcon {...props} />,
  linkedIn: (props) => <LinkedInIcon {...props} />,
  twitter: (props) => <TwitterIcon {...props} />,
  linkedInBusiness: (props) => <LinkedInBusinessIcon {...props} />,
  Jumpseller: (props) => <JumpsellerIcon {...props} />,
  wooCommerce: (props) => <WooCommerceIcon {...props} />,
  mailchimp: (props) => <MailchimpIcon {...props} />,
  tiktok: (props) => <TiktokIcon {...props} />,
};

export const ableToPost = [
  SocialProviderId.Facebook.toLowerCase(),
  SocialProviderId.Instagram.toLowerCase(),
  SocialProviderId.Twitter.toLowerCase(),
  SocialProviderId.LinkedIn.toLowerCase(),
  SocialProviderId.LinkedInBusiness.toLowerCase(),
  SocialProviderId.TikTok.toLowerCase(),
];

export const SocialPostSelector = () => {
  const intl = useIntl();
  const { socialSettings } = useMeCore();
  const {
    socialProviders,
    toggleSocialProvider,
    clearSocialProviders,
    selectSocialProvider,
    postErrors,
    images,
    video,
    selectedPost,
    addPostError,
    removePostError,
  } = useSocialPostCore();

  const hasMaxImageError: (id: string) => boolean = useCallback(
    (id: string) =>
      postErrors.filter(
        ({ type, socialProviderId }) =>
          socialProviderId === id && type === PostErrorType.MaxImagesPerPost
      ).length > 0,
    [postErrors]
  );

  const hasVideoNotAllowedError: (id: string) => boolean = useCallback(
    (id: string) =>
      postErrors.filter(
        ({ type, socialProviderId }) =>
          socialProviderId === id && type === PostErrorType.VideoNotAllowed
      ).length > 0,
    [postErrors]
  );

  const hasMediaRequireError: (id: string) => boolean = useCallback(
    (id: string) =>
      postErrors.filter(
        ({ type, socialProviderId }) =>
          socialProviderId === id && type === PostErrorType.MediaRequired
      ).length > 0,
    [postErrors]
  );

  const hasMaxImageSizeError: (id: string) => boolean = useCallback(
    (id: string) =>
      postErrors.filter(
        ({ type, socialProviderId }) =>
          socialProviderId === id && type === PostErrorType.MaxImageSize
      ).length > 0,
    [postErrors]
  );

  const hasMaxVideoSizeError: (id: string) => boolean = useCallback(
    (id: string) =>
      postErrors.filter(
        ({ type, socialProviderId }) =>
          socialProviderId === id && type === PostErrorType.MaxVideoSize
      ).length > 0,
    [postErrors]
  );

  const hasMaxVideoDurationError: (id: string) => boolean = useCallback(
    (id: string) =>
      postErrors.filter(
        ({ type, socialProviderId }) =>
          socialProviderId === id && type === PostErrorType.MaxVideoDuration
      ).length > 0,
    [postErrors]
  );

  const hasImageAspectRatioError: (id: string) => boolean = useCallback(
    (id: string) =>
      postErrors.filter(
        ({ type, socialProviderId }) =>
          socialProviderId === id && type === PostErrorType.ImageAspectRatio
      ).length > 0,
    [postErrors]
  );

  const hasImageAspectRatioSizeError: (id: string) => boolean = useCallback(
    (id: string) =>
      postErrors.filter(
        ({ type, socialProviderId }) =>
          socialProviderId === id && type === PostErrorType.ImageAspectRatioSize
      ).length > 0,
    [postErrors]
  );

  const hasVideoAspectRatioSizeError: (id: string) => boolean = useCallback(
    (id: string) =>
      postErrors.filter(
        ({ type, socialProviderId }) =>
          socialProviderId === id && type === PostErrorType.VideoAspectRatioSize
      ).length > 0,
    [postErrors]
  );

  const videoNotAllowedProviders: SocialProvider[] = useMemo(
    () =>
      socialProviders.filter(({ id }) => !SocialProviderRules[id].videoAllowed),
    [socialProviders]
  );

  const mediaRequiredProviders: SocialProvider[] = useMemo(
    () =>
      socialProviders.filter(
        ({ id }) => SocialProviderRules[id].postMediaRequired === true
      ),
    [socialProviders]
  );

  const maxVideoSizeProviders: SocialProvider[] = useMemo(
    () =>
      socialProviders.filter(
        ({ id }) => SocialProviderRules[id].maxVideoSize !== -1
      ),
    [socialProviders]
  );

  const maxImageSizeProviders: SocialProvider[] = useMemo(
    () =>
      socialProviders.filter(
        ({ id }) => SocialProviderRules[id].maxImageSize !== -1
      ),
    [socialProviders]
  );

  const imageAspectRatioSizeProviders: SocialProvider[] = useMemo(
    () =>
      socialProviders.filter(
        ({ id }) => SocialProviderRules[id].imageAspectRatioSizeAllowed
      ),
    [socialProviders]
  );

  const maxVideoDurationProviders: SocialProvider[] = useMemo(
    () =>
      socialProviders.filter(
        ({ id }) => SocialProviderRules[id].maxVideoDuration
      ),
    [socialProviders]
  );

  const imageAspectRatioProviders: SocialProvider[] = useMemo(
    () =>
      socialProviders.filter(
        ({ id }) =>
          SocialProviderRules[id].imageAspectRatioAllowed &&
          SocialProviderRules[id].imageAspectRatioAllowed!.length > 0
      ),
    [socialProviders]
  );

  const videoAspectRatioSizeProviders: SocialProvider[] = useMemo(
    () =>
      socialProviders.filter(
        ({ id }) => SocialProviderRules[id].videoAspectRatioSizeAllowed
      ),
    [socialProviders]
  );

  useEffect(() => {
    return () => {
      clearSocialProviders();
    };
  }, [clearSocialProviders]);

  useEffect(() => {
    socialProviders.forEach(({ id }) => {
      let cont = images.length;
      if (selectedPost && selectedPost.post.type === SOCIAL_POST_TYPES.IMAGE) {
        cont += selectedPost?.media?.length;
      }

      SocialProviderRules[id].maxPostPhotoAllowed < cont
        ? !hasMaxImageError(id) &&
          addPostError({
            socialProviderId: id as SocialProviderId,
            type: PostErrorType.MaxImagesPerPost,
            message: `Máximo de imágenes por publicación alcanzado. ${_.capitalize(
              id
            )} permite ${
              SocialProviderRules[id].maxPostPhotoAllowed
            } imágenes `,
          })
        : hasMaxImageError(id) &&
          removePostError(
            id as SocialProviderId,
            PostErrorType.MaxImagesPerPost
          );
    });
  }, [
    addPostError,
    hasMaxImageError,
    images.length,
    removePostError,
    socialProviders,
    selectedPost,
  ]);

  useEffect(() => {
    videoNotAllowedProviders.forEach(({ id }) =>
      video
        ? !hasVideoNotAllowedError(id) &&
          addPostError({
            socialProviderId: id as SocialProviderId,
            type: PostErrorType.VideoNotAllowed,
            message: "Esta Red Social no permite la publicación de Videos",
          })
        : hasVideoNotAllowedError(id) &&
          removePostError(id as SocialProviderId, PostErrorType.VideoNotAllowed)
    );
  }, [
    addPostError,
    removePostError,
    video,
    videoNotAllowedProviders,
    hasVideoNotAllowedError,
  ]);

  useEffect(() => {
    mediaRequiredProviders.forEach(({ id }) =>
      images.length === 0 &&
      !video &&
      (!selectedPost || selectedPost.media.length === 0)
        ? !hasMediaRequireError(id) &&
          addPostError({
            socialProviderId: id as SocialProviderId,
            type: PostErrorType.MediaRequired,
            message: intl.formatMessage({
              id: "app.socialMediaPost.errorMsg1",
            }),
          })
        : hasMediaRequireError(id) &&
          removePostError(id as SocialProviderId, PostErrorType.MediaRequired)
    );
  }, [
    addPostError,
    images,
    removePostError,
    video,
    selectedPost,
    mediaRequiredProviders,
    hasMediaRequireError,
    intl,
  ]);

  useEffect(() => {
    maxVideoSizeProviders.forEach(({ id }) =>
      video && video.size > SocialProviderRules[id].maxVideoSize * 1024 * 1024
        ? !hasMaxVideoSizeError(id) &&
          addPostError({
            socialProviderId: id as SocialProviderId,
            type: PostErrorType.MaxVideoSize,
            message: `${intl.formatMessage({
              id: "app.socialMediaPost.errorMsg2",
            })} ${_.capitalize(id)} ${intl.formatMessage({
              id: "app.socialMediaPost.errorMsg3",
            })}  
            ${SocialProviderRules[id].maxVideoSize}
            ${intl.formatMessage({ id: "app.socialMediaPost.errorMsg4" })}`,
          })
        : hasMaxVideoSizeError(id) &&
          removePostError(id as SocialProviderId, PostErrorType.MaxVideoSize)
    );
  }, [
    addPostError,
    hasMaxVideoSizeError,
    removePostError,
    video,
    maxVideoSizeProviders,
    intl,
  ]);

  useEffect(() => {
    maxImageSizeProviders.forEach(({ id }) =>
      images.filter(
        (image) =>
          image.size > SocialProviderRules[id].maxImageSize * 1024 * 1024
      ).length > 0
        ? !hasMaxImageSizeError(id) &&
          addPostError({
            socialProviderId: id as SocialProviderId,
            type: PostErrorType.MaxImageSize,
            message: `${intl.formatMessage({
              id: "app.socialMediaPost.errorMsg5",
            })} ${_.capitalize(id)} ${intl.formatMessage({
              id: "app.socialMediaPost.errorMsg6",
            })}
            ${SocialProviderRules[id].maxImageSize} 
            ${intl.formatMessage({ id: "app.socialMediaPost.errorMsg7" })}`,
          })
        : hasMaxImageSizeError(id) &&
          removePostError(id as SocialProviderId, PostErrorType.MaxImageSize)
    );
  }, [
    addPostError,
    hasMaxImageSizeError,
    removePostError,
    images,
    selectedPost,
    maxImageSizeProviders,
    intl,
  ]);

  useEffect(() => {
    imageAspectRatioProviders.forEach(({ id }) =>
      images.length &&
      images.find(
        ({ aspectRatio }) =>
          aspectRatio &&
          !SocialProviderRules[id].imageAspectRatioAllowed?.includes(
            aspectRatio
          )
      )
        ? !hasImageAspectRatioError(id) &&
          addPostError({
            socialProviderId: id as SocialProviderId,
            type: PostErrorType.ImageAspectRatio,
            message: intl.formatMessage(
              { id: "app.socialMediaPost.errorMsg9" },
              {
                provider: _.capitalize(id),
                aspectRatio:
                  SocialProviderRules[id].imageAspectRatioAllowed?.join(", "),
              }
            ),
            linkText: "Canva",
            link: "https://www.canva.com/search/templates?q=instagram",
          })
        : hasImageAspectRatioError(id) &&
          removePostError(
            id as SocialProviderId,
            PostErrorType.ImageAspectRatio
          )
    );
  }, [
    addPostError,
    removePostError,
    images.length,
    images,
    imageAspectRatioProviders,
    hasImageAspectRatioError,
    intl,
  ]);

  useEffect(() => {
    imageAspectRatioSizeProviders.forEach(({ id }) =>
      images.length &&
      images.find((data) => {
        const { width, height } = data;
        const aspectRatioSize = SocialProviderRules[id]
          .imageAspectRatioSizeAllowed || [0, Number.MAX_SAFE_INTEGER];
        const size = width && height ? width / height : 0;
        return size < aspectRatioSize[0] || size > aspectRatioSize[1];
      })
        ? !hasImageAspectRatioSizeError(id) &&
          addPostError({
            socialProviderId: id as SocialProviderId,
            type: PostErrorType.ImageAspectRatioSize,
            message: intl.formatMessage(
              { id: "app.socialMediaPost.errorMsg8" },
              {
                provider: _.capitalize(id),
                minAspectRatio:
                  SocialProviderRules[id].imageAspectRatioSizeAllowed![0],
                maxAspectRatio:
                  SocialProviderRules[id].imageAspectRatioSizeAllowed![1],
              }
            ),
            linkText: "Canva",
            link: "https://www.canva.com/search/templates?q=instagram",
          })
        : hasImageAspectRatioSizeError(id) &&
          removePostError(
            id as SocialProviderId,
            PostErrorType.ImageAspectRatioSize
          )
    );
  }, [
    addPostError,
    removePostError,
    images.length,
    images,
    imageAspectRatioSizeProviders,
    hasImageAspectRatioSizeError,
    intl,
  ]);

  useEffect(() => {
    videoAspectRatioSizeProviders.forEach(({ id }) => {
      const aspectRatioSize = SocialProviderRules[id]
        .videoAspectRatioSizeAllowed || [0, Number.MAX_SAFE_INTEGER];
      const aspectRatio =
        video && video.width && video.height ? video.width / video.height : 0;

      video &&
      (aspectRatio < aspectRatioSize[0] || aspectRatio > aspectRatioSize[1])
        ? !hasVideoAspectRatioSizeError(id) &&
          addPostError({
            socialProviderId: id as SocialProviderId,
            type: PostErrorType.VideoAspectRatioSize,
            message: intl.formatMessage(
              { id: "app.socialMediaPost.errorMsg13" },
              {
                provider: _.capitalize(id),
                minAspectRatio:
                  SocialProviderRules[id].videoAspectRatioSizeAllowed![0],
                maxAspectRatio:
                  SocialProviderRules[id].videoAspectRatioSizeAllowed![1],
              }
            ),
            linkText: "Canva",
            link: "https://www.canva.com/search/templates?q=instagram",
          })
        : hasVideoAspectRatioSizeError(id) &&
          removePostError(
            id as SocialProviderId,
            PostErrorType.VideoAspectRatioSize
          );
    });
  }, [
    addPostError,
    removePostError,
    video,
    videoAspectRatioSizeProviders,
    hasVideoAspectRatioSizeError,
    intl,
  ]);

  useEffect(() => {
    maxVideoDurationProviders.forEach(({ id }) =>
      video && video.duration > SocialProviderRules[id].maxVideoDuration!
        ? !hasMaxVideoDurationError(id) &&
          addPostError({
            socialProviderId: id as SocialProviderId,
            type: PostErrorType.MaxVideoDuration,
            message: intl.formatMessage(
              { id: "app.socialMediaPost.maxVideoDurationError" },
              { duration: SocialProviderRules[id].maxVideoDuration }
            ),
          })
        : hasMaxVideoDurationError(id) &&
          removePostError(
            id as SocialProviderId,
            PostErrorType.MaxVideoDuration
          )
    );
  }, [
    addPostError,
    removePostError,
    video,
    maxVideoDurationProviders,
    hasMaxVideoDurationError,
    intl,
  ]);

  const isSelected = useCallback(
    (id: string): boolean =>
      Boolean(socialProviders.filter((sp) => sp.id === id).length),
    [socialProviders]
  );

  const socialProvidersAbleToPost: SocialProvider[] = useMemo(() => {
    let providers = ableToPost
      .filter((social) => socialSettings![social])
      .map(
        (social): SocialProvider => ({
          id: social,
          name: SocialProviderNameMapper[social],
          displayName: socialSettings![social]?.name,
          avatar: socialSettings![social]?.avatarUrl,
          icon: IconMapper[social],
        })
      );

    if (selectedPost) {
      providers = providers.filter(
        (social) => social.id === selectedPost.post.socialProvider
      );
      selectSocialProvider(providers[0]);
    }

    return providers;
  }, [socialSettings, selectedPost, selectSocialProvider]);
  return (
    <div className="d-flex justify-content-around flex-wrap">
      {socialProvidersAbleToPost.map((sp) => (
        <PanelWembii
          shadow
          className="px-md-5 px-xs-2 py-4 my-1 pointer"
          key={sp.id}
          style={{ maxWidth: 150, minWidth: 124 }}
          onClick={() => (!selectedPost ? toggleSocialProvider(sp) : null)}
        >
          <SocialProviderIcon
            socialProvider={sp}
            isSelected={isSelected(sp.id)}
            socialType={SocialType.Post}
          />
        </PanelWembii>
      ))}
    </div>
  );
};
