import React, { useMemo, useCallback, useEffect } from "react";
import { Button } from "reactstrap";
import { FacebookProvider, Login } from "react-facebook";
import { useIntl } from "react-intl";
import _ from "lodash";
import { FacebookIcon } from "./FacebookIcon"; // Asegúrate de tener este ícono
import { useFacebookCore } from "../../../../cores/facebook";
import { APP_ID, SCOPE } from "../../../../cores/facebook/config";
import { useGlobalCore } from "../../../../cores/globals";
import { useMeCore } from "../../../../cores/me";
import { daysRemainingFromToday } from "../../../utils/dateUtils";
import FacebookAccounts from "./FacebookAccounts";
import { FACEBOOK_SYNC_LOADING } from "../../../../constants/loading.constant";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface FacebookProviderResponse {
  tokenDetail: { accessToken: string };
}

const FacebookAuthButton: React.FC<{
  genericSync?: boolean;
  customText?: string;
}> = ({ genericSync, customText }) => {
  const intl = useIntl();
  const { get, settings, unbind, postAccounts } = useFacebookCore();
  const { company, getMeSocialSettings } = useMeCore();
  const { turnOnModal, isLoading } = useGlobalCore();

  // Fetch the Facebook settings on mount
  useEffect(() => {
    if (company?.id) {
      get(company.id);
    }
  }, [company, get]);

  // Social provider details
  const socialProvider = useMemo(
    () => ({
      id: "Facebook",
      name: "Facebook",
      icon: <FacebookIcon size="5x" active={!_.isEmpty(settings)} />,
      displayName: settings?.name,
      avatar: settings?.avatarUrl,
    }),
    [settings]
  );

  // Remaining bind days calculation
  const remainingBindDays = useMemo(
    () =>
      settings?.expiresIn ? daysRemainingFromToday(settings?.expiresIn) : null,
    [settings]
  );

  // Handle login errors
  const handleOnError = useCallback((error: string) => {
    console.error(error);
  }, []);

  // Handle login success (completed)
  const handleOnCompleted = useCallback(
    async ({ tokenDetail: { accessToken } }: FacebookProviderResponse) => {
      // Post the Facebook account data to the backend
      await postAccounts(company!.id, accessToken);

      // Turn on the modal to show connected Facebook accounts
      turnOnModal(
        "FACEBOOK_ACCOUNTS_SIDE_MODAL_RIGHT",
        <FacebookAccounts userToken={accessToken} />
      );
    },
    [company, postAccounts, turnOnModal]
  );

  // Handle account unbinding
  const handleUnbind = useCallback(() => {
    unbind(company!.id, () => {
      getMeSocialSettings(company?.id!);
    });
  }, [company, unbind, getMeSocialSettings]);

  // Sync button logic (either Sync or Disconnect)
  const facebookSyncButton = useMemo(() => {
    return _.isEmpty(settings) ? (
      <FacebookProvider version="v21.0" appId={APP_ID}>
        <Login
          scope={SCOPE}
          onCompleted={handleOnCompleted}
          onError={handleOnError}
        >
          {({ handleClick }: { handleClick: () => void }) => (
            <Button
              className="w-100 mx-auto"
              color="primary"
              onClick={handleClick}
              disabled={isLoading(FACEBOOK_SYNC_LOADING)}
            >
              {intl.formatMessage({ id: "app.home.syncUp" })}
            </Button>
          )}
        </Login>
      </FacebookProvider>
    ) : (
      <Button
        className="w-100 d-flex"
        color="danger"
        outline
        onClick={handleUnbind}
        disabled={isLoading(FACEBOOK_SYNC_LOADING)}
      >
        <FontAwesomeIcon icon={faSignOutAlt} className="me-2 my-auto" />
        {intl.formatMessage({ id: "app.home.disconnect" })}
      </Button>
    );
  }, [
    settings,
    handleOnCompleted,
    handleOnError,
    handleUnbind,
    intl,
    isLoading,
  ]);

  return (
    <>
      {genericSync ? (
        <FacebookProvider version="v21.0" appId={APP_ID}>
          <Login
            scope={SCOPE}
            onCompleted={handleOnCompleted}
            onError={handleOnError}
          >
            {({ handleClick }: { handleClick: () => void }) => (
              <Button
                className="w-100 mx-auto"
                color="primary"
                onClick={handleClick}
                disabled={isLoading(FACEBOOK_SYNC_LOADING)}
              >
                {customText
                  ? customText
                  : intl.formatMessage({ id: "app.home.syncUp" })}
              </Button>
            )}
          </Login>
        </FacebookProvider>
      ) : (
        facebookSyncButton
      )}
    </>
  );
};

export default FacebookAuthButton;
