import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { createEntityAdapter } from "../../helpers/store/create-entity-adapter";
import { Contact, GetContactsParams } from "../interfaces/api/get-contacts";
import {
  ContactAction,
  addMassAssignmentAction,
  cleanMassAssignmentAction,
  deselectAllContactsAction,
  deselectContactAction,
  removeMassAssignmentAction,
  resetHeadersAction,
  selectContactAction,
  setCompanyFiltersAction,
  setContactFiltersAction,
  setContactStateRequestAction,
  setHeadersAction,
  setReplaceAssignmentAction,
  setSourceAction,
} from "./actions";
import {
  ContactApiAction,
  deleteCompanyFailureAction,
  deleteCompanyInitAction,
  deleteCompanySuccessAction,
  deleteContactCompanyFailureAction,
  deleteContactCompanyInitAction,
  deleteContactCompanySuccessAction,
  deleteContactTagFailureAction,
  deleteContactTagInitAction,
  deleteContactTagSuccessAction,
  getCompaniesFailureAction,
  getCompaniesInitAction,
  getCompaniesSuccessAction,
  getCompanyFailureAction,
  getCompanyInitAction,
  getCompanySuccessAction,
  getContactFailureAction,
  getContactInitAction,
  getContactSuccessAction,
  getContactsFailureAction,
  getContactsInitAction,
  getContactsSuccessAction,
  getContactTagsFailureAction,
  getContactTagsInitAction,
  getContactTagsSuccessAction,
  getExportContactsFailureAction,
  getExportContactsInitAction,
  getExportContactsSuccessAction,
  postCompanyFailureAction,
  postCompanyInitAction,
  postCompanySuccessAction,
  postContactCompanyFailureAction,
  postContactCompanyInitAction,
  postContactCompanySuccessAction,
  postContactTagFailureAction,
  postContactTagInitAction,
  postContactTagSuccessAction,
  postImportContactsFailureAction,
  postImportContactsInitAction,
  postImportContactsSuccessAction,
  putCompanyFailureAction,
  putCompanyInitAction,
  putCompanySuccessAction,
  putContactTagFailureAction,
  putContactTagInitAction,
  putContactTagSuccessAction,
  getCustomFieldsSuccessAction,
  getCustomFieldsInitAction,
  getCustomFieldsFailureAction,
  postCustomFieldInitAction,
  postCustomFieldFailureAction,
  putCustomFieldInitAction,
  putCustomFieldFailureAction,
  deleteCustomFieldInitAction,
  deleteCustomFieldFailureAction,
  postCustomFieldSuccessAction,
  putCustomFieldSuccessAction,
  deleteCustomFieldSuccessAction,
  putContactSuccessAction,
  putContactFailureAction,
  putContactInitAction,
  getContactConfigInitAction,
  getContactConfigFailureAction,
  getContactConfigSuccessAction,
  postContactConfigInitAction,
  postContactConfigFailureAction,
  deleteContactConfigInitAction,
  deleteContactConfigFailureAction,
  postContactConfigSuccessAction,
  deleteContactConfigSuccessAction,
  postCommentSuccessAction,
  putCommentSuccessAction,
  deleteCommentSuccessAction,
  deleteContactStateFailureAction,
  deleteContactStateInitAction,
  getContactStatesFailureAction,
  getContactStatesInitAction,
  postContactStateFailureAction,
  postContactStateInitAction,
  putContactStateFailureAction,
  putContactStateInitAction,
  putContactStateSuccessAction,
  deleteContactStateSuccessAction,
  postContactStateSuccessAction,
  getContactStatesSuccessAction,
  getNewContactsCountSuccessAction,
  getNewCompaniesCountSuccessAction,
} from "./api-actions";
import { ContactTag } from "../interfaces/api/get-contact-tags";
import { Company } from "../interfaces/api/get-company";
import {
  Card,
  CustomFieldValue,
  Email,
  Comment,
} from "../interfaces/api/get-contact";
import { CustomField } from "../interfaces/api/get-custom-fields";
import { GetCompaniesParams } from "../interfaces/api/get-companies";
import { Config } from "../interfaces/api/get-config";
import { ContactState as ctState } from "../interfaces/api/get-contact-states";
import { ContactStateRequest } from "../interfaces/contact-state-request";

export interface ContactState {
  newCompaniesCount: number;
  contacts: {
    ids: number[];
    entities: Record<string, Contact>;
  };
  contact: Contact | null;
  contactStateRequest: ContactStateRequest;
  contactCompanies: {
    ids: string[];
    entities: Record<string, Company>;
  };
  contactEmails: {
    ids: number[];
    entities: Record<string, Email>;
  };
  customFieldValues: {
    ids: string[];
    entities: Record<string, CustomFieldValue>;
  };
  contactCards: {
    ids: string[];
    entities: Record<string, Card>;
  };
  contactComments: {
    ids: string[];
    entities: Record<string, Comment>;
  };
  selectedContacts: {
    ids: string[];
    entities: Record<string, Contact>;
  };
  massAssignment: string[];
  companies: {
    ids: number[];
    entities: Record<string, Company>;
  };
  company: Company | null;
  tags: {
    ids: number[];
    entities: Record<string, ContactTag>;
  };
  states: {
    ids: number[];
    entities: Record<string, ctState>;
  };
  customFields: {
    ids: string[];
    entities: Record<string, CustomField>;
  };
  config: {
    ids: string[];
    entities: Record<string, Config>;
  };
  newContactsCount: number;
  total: number;
  headers: Record<string, number>;
  contactFilters: GetContactsParams;
  companyFilters: GetCompaniesParams;
  replaceAssigment: boolean;
  source: string;
}

const initialState: ContactState = {
  newContactsCount: 0,
  newCompaniesCount: 0,
  contacts: {
    ids: [],
    entities: {},
  },
  contactStateRequest: "all",
  contact: null,
  contactCompanies: {
    ids: [],
    entities: {},
  },
  company: null,
  contactEmails: {
    ids: [],
    entities: {},
  },
  customFieldValues: {
    ids: [],
    entities: {},
  },
  contactCards: {
    ids: [],
    entities: {},
  },
  contactComments: {
    ids: [],
    entities: {},
  },
  selectedContacts: {
    ids: [],
    entities: {},
  },
  companies: {
    ids: [],
    entities: {},
  },
  tags: {
    ids: [],
    entities: {},
  },
  states: {
    ids: [],
    entities: {},
  },
  customFields: {
    ids: [],
    entities: {},
  },
  config: {
    ids: [],
    entities: {},
  },
  total: 0,
  headers: {
    name: -1,
    surname: -1,
    phone: -1,
    email: -1,
    enterprise: -1,
  },
  contactFilters: {
    page: 1,
    perPage: 10,
  },
  companyFilters: {
    page: 1,
    perPage: 10,
  },
  massAssignment: [],
  replaceAssigment: true,
  source: "",
};

const contactUsersAdapter = createEntityAdapter<Contact>(
  (payload) => payload.id
);
const contactTagsAdapter = createEntityAdapter<ContactTag>(
  (payload) => payload.id
);
const contactStatesAdapter = createEntityAdapter<ctState>(
  (payload) => payload.id
);
const companyAdapter = createEntityAdapter<Company>((payload) => payload.id);
const contactCompanyAdapter = createEntityAdapter<Company>(
  (payload) => payload.id
);
const contactEmailAdapter = createEntityAdapter<Email>((payload) => payload.id);
const contactCommentAdapter = createEntityAdapter<Comment>(
  (payload) => payload.id
);
const customFieldAdapter = createEntityAdapter<CustomField>(
  (payload) => payload.id
);
const customFieldValueAdapter = createEntityAdapter<CustomFieldValue>(
  (payload) => payload.customFieldId
);
const configAdapter = createEntityAdapter<Config>((payload) => payload.id);
const cardAdapter = createEntityAdapter<Card>((payload) => payload.id);

export default produce(
  (
    draft: ContactState = initialState,
    action: ContactApiAction | ContactAction | GlobalActions
  ) => {
    switch (action.type) {
      case getNewCompaniesCountSuccessAction.type:
        draft.newCompaniesCount = action.total;
        return draft;
      case getNewContactsCountSuccessAction.type:
        draft.newContactsCount = action.total;
        return draft;
      case getContactsSuccessAction.type:
        contactUsersAdapter.addMany(action.contacts, draft.contacts);
        draft.total = action.total;
        return draft;
      case setHeadersAction.type:
        draft.headers = action.headers;
        return draft;
      case setContactStateRequestAction.type:
        draft.contactStateRequest = action.contactStateRequest;
        return draft;
      case resetHeadersAction.type:
        draft.headers = initialState.headers;
        return draft;
      case getContactTagsSuccessAction.type:
        contactTagsAdapter.addMany(action.tags, draft.tags);
        return draft;
      case getContactStatesSuccessAction.type:
        contactStatesAdapter.addMany(action.states, draft.states);
        return draft;
      case getContactSuccessAction.type:
        draft.contact = action.contact;
        contactCompanyAdapter.addMany(
          action.contact.companies,
          draft.contactCompanies
        );
        contactEmailAdapter.addMany(action.contact.emails, draft.contactEmails);
        customFieldValueAdapter.addMany(
          action.contact.fields,
          draft.customFieldValues
        );
        cardAdapter.addMany(action.cards, draft.contactCards);
        contactCommentAdapter.addMany(
          action.contact.comments,
          draft.contactComments
        );
        return draft;
      case postContactTagSuccessAction.type:
        contactTagsAdapter.setOne(action.tag, draft.tags);
        return draft;
      case putContactTagSuccessAction.type:
        contactTagsAdapter.updateOne(action.tag, draft.tags);
        return draft;
      case deleteContactTagSuccessAction.type:
        contactTagsAdapter.removeOne(action.id, draft.tags);
        return draft;
      case postContactStateSuccessAction.type:
        contactStatesAdapter.setOne(action.state, draft.states);
        return draft;
      case putContactStateSuccessAction.type:
        contactStatesAdapter.updateOne(action.state, draft.states);
        return draft;
      case deleteContactStateSuccessAction.type:
        contactStatesAdapter.removeOne(action.id, draft.states);
        return draft;
      case selectContactAction.type:
        contactUsersAdapter.setOne(action.contact, draft.selectedContacts);
        return draft;
      case deselectContactAction.type:
        contactUsersAdapter.removeOne(action.id, draft.selectedContacts);
        return draft;
      case deselectAllContactsAction.type:
        contactUsersAdapter.removeAll(draft.selectedContacts);
        return draft;
      case setSourceAction.type:
        draft.source = action.source;
        return draft;
      case setContactFiltersAction.type:
        draft.contactFilters = action.filters;
        return draft;
      case setCompanyFiltersAction.type:
        draft.companyFilters = action.filters;
        return draft;
      case getCompaniesSuccessAction.type:
        companyAdapter.addMany(action.companies, draft.companies);
        draft.total = action.total;
        return draft;
      case getCompanySuccessAction.type:
        draft.company = action.company;
        customFieldValueAdapter.addMany(
          action.company.fields,
          draft.customFieldValues
        );
        return draft;
      case postCompanySuccessAction.type:
        draft.company = action.company;
        companyAdapter.setOne(action.company, draft.companies);
        companyAdapter.moveToFirstPosition(action.company.id, draft.companies);
        return draft;
      case putCompanySuccessAction.type:
        draft.company = action.company;
        companyAdapter.setOne(action.company, draft.companies);
        return draft;
      case deleteCompanySuccessAction.type:
        companyAdapter.removeOne(+action.id, draft.companies);
        draft.company = null;
        return draft;
      case getCustomFieldsSuccessAction.type:
        customFieldAdapter.addMany(action.fields, draft.customFields);
        return draft;
      case postCustomFieldSuccessAction.type:
        customFieldAdapter.setOne(action.field, draft.customFields);
        return draft;
      case putCustomFieldSuccessAction.type:
        customFieldAdapter.updateOne(action.field, draft.customFields);
        return draft;
      case deleteCustomFieldSuccessAction.type:
        customFieldAdapter.removeOne(action.id, draft.customFields);
        return draft;
      case getContactConfigSuccessAction.type:
        configAdapter.addMany(action.config, draft.config);
        return draft;
      case postContactConfigSuccessAction.type:
        configAdapter.setOne(action.config, draft.config);
        return draft;
      case deleteContactConfigSuccessAction.type:
        configAdapter.removeOne(+action.id, draft.config);
        return draft;
      case postCommentSuccessAction.type:
        contactCommentAdapter.setOne(action.comment, draft.contactComments);
        contactCommentAdapter.moveToFirstPosition(
          action.comment.id,
          draft.contactComments
        );
        return draft;
      case putCommentSuccessAction.type:
        contactCommentAdapter.updateOne(action.comment, draft.contactComments);
        return draft;
      case deleteCommentSuccessAction.type:
        contactCommentAdapter.removeOne(+action.id, draft.contactComments);
        return draft;
      case addMassAssignmentAction.type:
        draft.massAssignment = [...draft.massAssignment, action.id];
        return draft;
      case removeMassAssignmentAction.type:
        draft.massAssignment = draft.massAssignment.filter(
          (item) => item !== action.id
        );
        return draft;
      case cleanMassAssignmentAction.type:
        draft.massAssignment = [];
        return draft;
      case setReplaceAssignmentAction.type:
        draft.replaceAssigment = action.replace;
        return draft;
      case resetAction.type:
        return initialState;
      case getContactInitAction.type:
      case getContactFailureAction.type:
      case getContactsInitAction.type:
      case putContactInitAction.type:
      case putContactFailureAction.type:
      case putContactSuccessAction.type:
      case getContactsFailureAction.type:
      case getExportContactsInitAction.type:
      case getExportContactsSuccessAction.type:
      case getExportContactsFailureAction.type:
      case postImportContactsInitAction.type:
      case postImportContactsSuccessAction.type:
      case postImportContactsFailureAction.type:
      case postContactTagInitAction.type:
      case postContactTagFailureAction.type:
      case putContactTagInitAction.type:
      case putContactTagFailureAction.type:
      case getContactTagsInitAction.type:
      case getContactTagsFailureAction.type:
      case deleteContactTagInitAction.type:
      case deleteContactTagFailureAction.type:
      case postContactStateInitAction.type:
      case postContactStateFailureAction.type:
      case putContactStateInitAction.type:
      case putContactStateFailureAction.type:
      case getContactStatesInitAction.type:
      case getContactStatesFailureAction.type:
      case deleteContactStateInitAction.type:
      case deleteContactStateFailureAction.type:
      case getCompanyInitAction.type:
      case getCompanyFailureAction.type:
      case getCompaniesInitAction.type:
      case getCompaniesFailureAction.type:
      case postCompanyFailureAction.type:
      case postCompanyInitAction.type:
      case putCompanyFailureAction.type:
      case putCompanyInitAction.type:
      case deleteCompanyFailureAction.type:
      case deleteCompanyInitAction.type:
      case postContactCompanyInitAction.type:
      case postContactCompanySuccessAction.type:
      case postContactCompanyFailureAction.type:
      case deleteContactCompanyInitAction.type:
      case deleteContactCompanySuccessAction.type:
      case deleteContactCompanyFailureAction.type:
      case getCustomFieldsInitAction.type:
      case getCustomFieldsFailureAction.type:
      case postCustomFieldInitAction.type:
      case postCustomFieldFailureAction.type:
      case putCustomFieldInitAction.type:
      case putCustomFieldFailureAction.type:
      case deleteCustomFieldInitAction.type:
      case deleteCustomFieldFailureAction.type:
      case getContactConfigInitAction.type:
      case getContactConfigFailureAction.type:
      case postContactConfigInitAction.type:
      case postContactConfigFailureAction.type:
      case deleteContactConfigInitAction.type:
      case deleteContactConfigFailureAction.type:
      default:
        return draft;
    }
  }
);
