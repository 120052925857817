import { useLocation } from "react-router-dom";
import { useTiktokCore } from "../../../../cores/tiktok";
import React, { useEffect } from "react";
import { useMeCore } from "../../../../cores/me";
import { useRouterCore } from "../../../../cores/router";
import QueryString from "query-string";
export default function TiktokPopUp() {
  const { search } = useLocation();
  const { bind } = useTiktokCore();
  const { company, getMeSocialSettings } = useMeCore();
  const { goToSocial } = useRouterCore();

  useEffect(() => {
    const { code } = QueryString.parse(search);
    if (code) {
      bind(company!.id, code as string, () => {
        getMeSocialSettings(company?.id!);
      });
      goToSocial();
    }
  }, [search, company, bind, goToSocial, getMeSocialSettings]);

  return <div />;
}
