import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { BoardList, ListRestriction } from "../interfaces/api/get-board";
import { Board } from "../interfaces/Board";
import { PanelFilters } from "../interfaces/PanelFilters";
import { Quote } from "../interfaces/Quote";

export const changeListOrderAction = createAction(
  "[Board] Change List Order Action",
  props<{ id: string; index: number }>()
);

export const changeCardOrderAction = createAction(
  "[Board] Change Card Order Action",
  props<{
    id: string;
    listSourceId: string;
    listDestinationId: string;
    index: number;
  }>()
);
export const addListRestrictionAction = createAction(
  "[Board] Add List Restriction Action",
  props<{
    restriction: ListRestriction;
    listId: string;
  }>()
);
export const removeListRestrictionAction = createAction(
  "[Board] Remove List Restriction Action",
  props<{
    restrictionId: string;
    listId: string;
  }>()
);

export const setFilterAction = createAction(
  "[Board] Set Filter Data Action",
  props<{ filters: PanelFilters }>()
);

export const clearFilterAction = createAction(
  "[Board] clear Filter Action",
  props<{ id: string }>()
);

export const setShowTagTextAction = createAction(
  "[Board] Set Show Tag Text Action",
  props<{ showTagText: boolean }>()
);

export const setQuoteAction = createAction(
  "[Board] Set Quote Action",
  props<{ quote: Quote }>()
);

export const cleanCardAction = createAction("[Board] Clean Card Action");

export const setBoardUpdateAction = createAction(
  "[Board] Set Board Update Action",
  props<{ board: Board }>()
);

const actions = joinActions({
  changeListOrderAction,
  changeCardOrderAction,
  setFilterAction,
  clearFilterAction,
  setShowTagTextAction,
  setQuoteAction,
  cleanCardAction,
  setBoardUpdateAction,
  addListRestrictionAction,
  removeListRestrictionAction,
});

export type BoardAction = typeof actions;
