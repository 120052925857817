import cn from "classnames";
import _ from "lodash";
import React, { useCallback, useEffect } from "react";
import { useSocialPostCore } from "../../../../cores/socialPost";
import {
  SocialProviderId,
  SocialProviderRules,
} from "../../../../cores/socialPost/config";
import {
  PostErrorType,
  SocialType,
} from "../../../../cores/socialPost/interfaces/postError";
import { isUrl } from "../../../utils/stringUtils";
import { IconMapper } from "../selector/SocialPostSelector";

interface Props {
  socialType: SocialType;
}

export const TextMeter = ({ socialType }: Props) => {
  const {
    socialProviders,
    text,
    mentions,
    addPostError,
    removePostError,
    addStoryError,
    removeStoryError,
    postErrors,
    storyErrors,
  } = useSocialPostCore();

  const counter = useCallback(
    (id: string) => {
      const isTwitter = id === SocialProviderId.Twitter;
      // Twitter counts any url with 23 characters
      const twitterUrl =
        text
          .split(/\s+/)
          .reduce(
            (prev, curr) => prev + 1 + (isUrl(curr) ? 23 : curr.length),
            0
          ) - 1;
      let length = isTwitter ? twitterUrl : text.length;

      // add empty new lines
      length += (text.match(/(^[ \t]*(\n|$))/gm) || []).length;

      mentions.forEach((mention) => {
        const submention = mention.mentions.find(
          (submention) => submention.socialProviderId === id
        );
        const mentionLength = submention
          ? submention.longitud
          : mention.mentions[0].longitud;
        length = length + mentionLength - mention.mentions[0].longitud - 1;

        if (id === SocialProviderId.Twitter) {
          // Twitter adds a @ for mentions
          length += 1;
        }
      });

      return SocialProviderRules[id].maxCharactersAllowed - length;
    },
    [text, mentions]
  );

  const hasTextSizeError = useCallback(
    (id: string) => {
      const errors = socialType === SocialType.Post ? postErrors : storyErrors;
      return (
        errors.filter(
          ({ socialProviderId, type }) =>
            socialProviderId === id && type === PostErrorType.TextSize
        ).length > 0
      );
    },
    [postErrors, storyErrors, socialType]
  );

  useEffect(() => {
    const add = socialType === SocialType.Post ? addPostError : addStoryError;
    const remove =
      socialType === SocialType.Post ? removePostError : removeStoryError;
    socialProviders.forEach(({ id }) =>
      counter(id) < 0
        ? !hasTextSizeError(id) &&
          add({
            socialProviderId: id as SocialProviderId,
            type: PostErrorType.TextSize,
            message: "Error en la longitud del texto para la red social",
          })
        : hasTextSizeError(id) &&
          remove(id as SocialProviderId, PostErrorType.TextSize)
    );
  }, [
    socialType,
    text,
    socialProviders,
    counter,
    hasTextSizeError,
    addPostError,
    removePostError,
    addStoryError,
    removeStoryError,
  ]);

  return (
    <div>
      {socialProviders.map(({ id }) => (
        <span className="p-2 mr-3" key={id}>
          <span className="mr-1">
            {_.isFunction(IconMapper[id]) &&
              IconMapper[id]({ active: true, size: "1x" })}
          </span>
          <span
            className={cn({
              "text-danger": counter(id) < 0,
            })}
          >
            {counter(id)}
          </span>
        </span>
      ))}
    </div>
  );
};
