import React, { useCallback } from "react";
import { Button } from "reactstrap";
import { useIntl } from "react-intl";
import { Office365Icon } from "./Office365Icon";

const Office365Button: React.FC<{
  isActive: boolean;
  isLoading: boolean;
  onBind: () => void;
  onUnbind: () => void;
  row?: boolean;
}> = ({ isActive, onBind, onUnbind, isLoading, row = false }) => {
  const intl = useIntl();

  const handleClick = useCallback(() => {
    if (!isActive) {
      onBind();
    } else {
      onUnbind();
    }
  }, [isActive, onBind, onUnbind]);

  return (
    <div className={`w-100  ${row ? "d-flex" : ""} justify-content-between`}>
      <Button
        className={`w-100 mx-auto ${
          row
            ? "d-flex justify-content-between my-auto"
            : "d-flex justify-content-center"
        } `}
        color="primary"
        outline={isActive}
        onClick={handleClick}
        disabled={isLoading}
      >
        {row ? (
          <Office365Icon
            className="my-auto"
            color="white"
            size="1x"
            active={isActive}
          />
        ) : (
          <></>
        )}
        <p className="my-auto">
          {" "}
          {!isActive
            ? intl.formatMessage({ id: "app.home.syncUp" })
            : intl.formatMessage({ id: "app.home.disconnect" })}
        </p>
      </Button>
    </div>
  );
};

export default Office365Button;
