import React, { useCallback } from "react";
import { Button } from "reactstrap";
import Disconneted from "../../assets/disconneted.jpeg";

export const ConnectionIssuePage = () => {
  const userWantsSupport = useCallback(async () => {
    // @ts-ignore
    const $crisp = window["$crisp"];
    $crisp.push(["do", "chat:open"]);
    $crisp.push([
      "set",
      "message:text",
      [
        "¡Hola! Tengo inconvenientes para ingresar a Wembii y necesito su asistencia",
      ],
    ]);
  }, []);

  return (
    <div className="container d-flex flex-column " style={{ height: "100vh" }}>
      <div className="d-flex flex-column justify-content-center items-center my-auto container ">
        <img
          className="rounded-pill mx-auto my-4"
          style={{ width: "200px", height: "200px" }}
          src={Disconneted}
        />
        <h1 className="text-center">Problemas de Conexión</h1>
        <p className="text-center mx-auto my-4" style={{ width: "60%" }}>
          Estamos experimentando problemas de conexión. Por favor, verifica tu
          conexión a internet o intenta nuevamente más tarde.
        </p>
        <div className="d-flex flex-column flex-md-row mx-auto">
          <p className="my-4 ">Si tienes dudas o necesitas ayuda</p>
          <button
            onClick={userWantsSupport}
            className="my-auto mx-1  btn text-primary"
          >
            comunicate con soporte
          </button>
        </div>

        <Button
          onClick={() => window.location.reload()}
          className="my-2"
          color="primary"
          block
        >
          Reintentar conexión
        </Button>
      </div>
    </div>
  );
};
