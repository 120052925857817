import { io, Socket } from "socket.io-client";

const create: () => Socket = (): Socket => {
  const socket = io(
    process.env.REACT_APP_SOCKET_URL || "http://localhost:15000",
    { transports: ["websocket"], autoConnect: true }
  );
  if (socket.connected) {
    console.log(`Socket disponible en: ${process.env.REACT_APP_SOCKET_URL}`);
  }
  return socket;
};

export { create };
