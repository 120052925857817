import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Button, Col, Container, Form, Row } from "reactstrap";
import { OPENAI_SOCIAL_FORM } from "../../../../constants/modal.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { useMeCore } from "../../../../cores/me";
import { useOpenAiCore } from "../../../../cores/openAi";
import { useSocialPostCore } from "../../../../cores/socialPost";
import { CheckboxWembii } from "../../CheckboxWembii";
import { InputWembii } from "../../InputWembii";
import { useEmailCore } from "../../../../cores/email";

export default function OpenAiForm({
  emailTemplates,
}: {
  emailTemplates?: boolean;
}) {
  const intl = useIntl();
  const { clear, addText, socialProviders } = useSocialPostCore();
  const {
    templates,
    showTemplates,
    setShowTemplates,
    getSocialTemplate,
    getEmailTemplate,
  } = useOpenAiCore();
  const { company } = useMeCore();
  const { turnOffModal } = useGlobalCore();
  const { setMessage } = useEmailCore();
  const [selected, setSelected] = useState(0);
  const [industry, setIndustry] = useState("");
  const [subject, setSubject] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [emoticons, setEmoticons] = useState(true);
  const [hashtags, setHashtags] = useState(true);

  const onSelecting = useCallback(
    (template) => {
      clear();
      if (emailTemplates) {
        setMessage(template.body);
      } else {
        addText(template.body);
      }
      turnOffModal(OPENAI_SOCIAL_FORM);
    },
    [clear, addText, turnOffModal]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      const socialMedias = socialProviders.map((social) => social.name!);
      if (emailTemplates) {
        getEmailTemplate(company?.id!, {
          industry,
          subject,
          emoticons,
          hashtags,
          additionalInfo,
          socialMedias,
        });
      } else {
        getSocialTemplate(company?.id!, {
          industry,
          subject,
          emoticons,
          hashtags,
          additionalInfo,
          socialMedias,
        });
      }
    },
    [
      industry,
      subject,
      emoticons,
      hashtags,
      additionalInfo,
      company,
      socialProviders,
      getSocialTemplate,
      getEmailTemplate,
    ]
  );

  const template = useMemo(
    () => templates[selected] || null,
    [templates, selected]
  );

  const increase = useCallback(() => {
    if (selected < templates.length - 1) {
      setSelected(selected + 1);
    }
  }, [selected, templates]);

  const decrease = useCallback(() => {
    if (selected > 0) {
      setSelected(selected - 1);
    }
  }, [selected]);

  const back = useCallback(() => {
    setShowTemplates(false);
  }, [setShowTemplates]);

  const next = useCallback(() => {
    setShowTemplates(true);
  }, [setShowTemplates]);

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div
            className={classNames("d-flex flex-column h-100 p-3", {
              "justify-content-between": showTemplates,
            })}
          >
            <div>
              <h2 className="h2 d-flex justify-content-center align-items-center mb-4">
                {showTemplates
                  ? intl.formatMessage({ id: "app.socialStory.template" })
                  : "Chat GPT"}
              </h2>
            </div>

            {showTemplates ? (
              <>
                <div className="d-flex justify-content-between align-items-center">
                  <FontAwesomeIcon
                    icon={["fas", "angle-left"]}
                    size="2x"
                    className="mr-4 pointer textEditor-icon"
                    onClick={decrease}
                  />

                  <div className="d-flex align-items-center align-items-between">
                    {template ? (
                      <div className="d-flex flex-column align-items-center">
                        {/* <h3 className="mb-3">{template.title}</h3> */}
                        <div className="pre-wrap">{template.body}</div>

                        <div className="text-muted mt-3">
                          {selected + 1} / {templates.length}
                        </div>

                        <Button
                          color="primary"
                          className="mt-3"
                          onClick={() => onSelecting(template)}
                        >
                          {intl.formatMessage({
                            id: "app.socialStory.template.select",
                          })}
                        </Button>
                      </div>
                    ) : (
                      <label className="text-muted">
                        No existe ningun template
                      </label>
                    )}
                  </div>

                  <FontAwesomeIcon
                    icon={["fas", "angle-right"]}
                    size="2x"
                    className="ml-4 pointer textEditor-icon"
                    onClick={increase}
                  />
                </div>

                <Button
                  className="my-1 mt-3"
                  color="white"
                  block
                  onClick={back}
                >
                  Volver a generar
                </Button>
              </>
            ) : (
              <div className="d-flex flex-column">
                <Form onSubmit={handleSubmit}>
                  <InputWembii
                    label="Industria"
                    type="text"
                    name="industry"
                    required
                    placeholder="Industria"
                    value={industry}
                    onChange={setIndustry}
                  />

                  <InputWembii
                    label={emailTemplates ? "Propósito" : "Temática"}
                    type="text"
                    name="subject"
                    required
                    placeholder={emailTemplates ? "Propósito" : "Temática"}
                    value={subject}
                    onChange={setSubject}
                  />

                  <InputWembii
                    label="Información adicional"
                    type="textarea"
                    name="additional"
                    required
                    placeholder="Información adicional"
                    value={additionalInfo}
                    onChange={setAdditionalInfo}
                  />

                  <CheckboxWembii
                    label="No usar emoticones"
                    checked={!emoticons}
                    onChange={() => setEmoticons(!emoticons)}
                  />

                  <CheckboxWembii
                    label="No usar hashtags"
                    checked={!hashtags}
                    onChange={() => setHashtags(!hashtags)}
                  />

                  {templates.length > 0 && (
                    <Button
                      className="my-1 mt-4"
                      color="white"
                      block
                      onClick={next}
                    >
                      Ver generados último
                    </Button>
                  )}
                  <Button className="my-1 mt-3" color="primary" block>
                    Generar
                  </Button>
                </Form>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
