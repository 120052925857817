import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { Col, Container, Row } from "reactstrap";
import footerImage1 from "../../../../assets/preview/instagram-buttons-footer.png";
import footerImage2 from "../../../../assets/preview/instagram-preview-footer-2.png";
import headerImage from "../../../../assets/preview/instagram-preview-header.png";
import fakeText from "../../../../assets/preview/preview-fake-text-big.png";
import fakeTextHeader from "../../../../assets/preview/preview-fake-text-header.png";
import { useInstagramCore } from "../../../../cores/instagram";
import { useSocialPostCore } from "../../../../cores/socialPost";
import { SocialProviderId } from "../../../../cores/socialPost/config";
import { PostError } from "../../../../cores/socialPost/interfaces/postError";
import { ErrorList } from "./ErrorList";
import { MediaPreview } from "./MediaPreview";

export const InstagramPostPreview = () => {
  const { settings } = useInstagramCore();
  const { text, mentions, postErrors } = useSocialPostCore();
  const { name, avatarUrl } = settings || {};

  const providerErrors: PostError[] = useMemo(
    () =>
      postErrors.filter(
        ({ socialProviderId }) =>
          socialProviderId === SocialProviderId.Instagram
      ),
    [postErrors]
  );
  const formattedText = useMemo(() => {
    const orderMentions = mentions.sort((a, b) =>
      a.offset > b.offset ? -1 : 1
    );

    let txt = text;
    orderMentions.forEach((mention) => {
      const submention = mention.mentions.find(
        (submention) =>
          submention.socialProviderId === SocialProviderId.Instagram
      );
      const name = submention
        ? `<span style="color:#0d4b7e;">${submention.username}</span>`
        : mention.mentions[0].username;
      const offset = mention.offset + mention.mentions[0].username.length + 1;
      txt = txt.slice(0, mention.offset) + name + txt.slice(offset);
    });

    return txt;
  }, [text, mentions]);

  return providerErrors.length > 0 ? (
    <ErrorList errors={providerErrors} />
  ) : (
    <div className="instagramPreview-container neu-out p-2">
      <Container fluid>
        <Row className="instagramPreview-header">
          <Col xs="12" className="p-0">
            <img className="w-100" src={headerImage} alt="header" />
          </Col>
        </Row>
        <Row className="instagramPreview-header">
          <Col xs="6" className="d-flex align-items-center p-0 px-2">
            <div>
              <img
                className="instagramPreview-header-avatar"
                src={avatarUrl}
                alt="avatar"
              />
            </div>
            <div>
              <div>{name}</div>
              <div>
                <img src={fakeTextHeader} alt="avatar" />
              </div>
            </div>
          </Col>
          <Col
            xs="6"
            className="align-content-center col-6 d-flex justify-content-end"
          >
            <FontAwesomeIcon icon={["fas", "ellipsis-h"]} size="1x" />
          </Col>
        </Row>
        <Row>
          <Col xs="12" className="p-0">
            <MediaPreview className="instagramPreview-image" />
          </Col>
        </Row>
        <Row>
          <img
            className="instagramPreview-footer-1"
            src={footerImage1}
            alt="footer"
          />
        </Row>
        <Row className="instagramPreview-description">
          <Col xs="12" className="p-0">
            {formattedText ? (
              <div className="px-2 line-break-anywhere pre-wrap">
                <div
                  dangerouslySetInnerHTML={{
                    __html: formattedText,
                  }}
                ></div>
              </div>
            ) : (
              <img
                src={fakeText}
                alt="fake-text"
                className="instagramPreview-footer"
              />
            )}
          </Col>
        </Row>
        <Row>
          <img
            className="instagramPreview-footer"
            src={footerImage2}
            alt="footer"
          />
        </Row>
      </Container>
    </div>
  );
};
