import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Button, Container, Input } from "reactstrap";

import { useBoardCore } from "../../../../cores/boards";
import FieldRestrictionItem from "../FieldRestrictionItem";
import { useMeCore } from "../../../../cores/me";
import { useGlobalCore } from "../../../../cores/globals";
import { BOARDS_LIST_RESTRICTIONS_LOADING } from "../../../../constants/loading.constant";
import { BOARD_LIST_RESTRICTIONS } from "../../../../constants/modal.constant";
import { toast } from "react-toastify";
import { UpdateLoadingCard } from "../../UpdateLoadingCard";
import { CustomField } from "../../../../cores/boards/interfaces/api/get-custom-fields";
import { ListRestriction } from "../../../../cores/boards/interfaces/api/get-board";

const Restrictions: React.FC<{
  listId: string;
  listName: string;
  boardId: string;
}> = ({ listId, listName = "Sin nombre", boardId }) => {
  const { company } = useMeCore();
  const { turnOffLoading, turnOnLoading, isLoading, turnOffModal } =
    useGlobalCore();
  const {
    customFields,
    postListRestrictions,
    restrictions,
    getListRestrictions,
    deleteListRestrictions,
    addListRestriction,
    removeListRestriction,
    standardFields,
  } = useBoardCore();
  useEffect(() => {
    turnOnLoading(BOARDS_LIST_RESTRICTIONS_LOADING);
    getListRestrictions(company?.id!, boardId, listId);
    turnOffLoading(BOARDS_LIST_RESTRICTIONS_LOADING);
  }, [
    getListRestrictions,
    turnOnLoading,
    turnOffLoading,
    company?.id,
    boardId,
    listId,
  ]);
  const intl = useIntl();
  const [currentField, setCurrentField] = useState<CustomField | null>(null);
  const [data, setData] = useState<{
    [key: string]: {
      condition: string;
      value: string;
      required?: boolean;
    };
  }>({});
  const applyRestrictions = () => {
    turnOnLoading(BOARDS_LIST_RESTRICTIONS_LOADING);
    postListRestrictions(
      company?.id!,
      data,
      listId,
      (restriction: ListRestriction) => {
        toast.success("Restricción creada");
        addListRestriction(restriction, listId);
        turnOffLoading(BOARDS_LIST_RESTRICTIONS_LOADING);
        turnOffModal(BOARD_LIST_RESTRICTIONS);
      }
    );
  };
  const removeRestriction = (_id: string) => {
    turnOnLoading(BOARDS_LIST_RESTRICTIONS_LOADING);

    deleteListRestrictions(company?.id!, _id, () => {
      toast.success("Restricción eliminada");
      removeListRestriction(listId, _id);
      turnOffLoading(BOARDS_LIST_RESTRICTIONS_LOADING);
      turnOffModal(BOARD_LIST_RESTRICTIONS);
    });
  };
  const fields = [
    //TODO: Comento esta linea para añadirla luego
    // ...Object.keys(standardFields).map((key) => standardFields[key]),
    ...customFields,
  ];
  const getFieldName = (id: string | null) => {
    if (id !== null) {
      return Object.values(standardFields).find((value) => value.id === id)
        ?.name;
    }
    return "";
  };
  return (
    <>
      {isLoading(BOARDS_LIST_RESTRICTIONS_LOADING) ? (
        <UpdateLoadingCard loadingId={BOARDS_LIST_RESTRICTIONS_LOADING} />
      ) : (
        <Container>
          <div className="d-flex flex-column p-4">
            <h2 className="h2 text-center mb-4">
              {intl.formatMessage({ id: "app.crmBusiness.restrictionsTitle" })}
              {listName}
            </h2>
          </div>
          <section>
            <h6>Añadir restricción</h6>

            <form className="d-flex rounded-lg">
              <select
                className="form-control"
                onChange={(event: any) =>
                  event.target.value != "null"
                    ? setCurrentField(fields[event.target.value])
                    : setCurrentField(null)
                }
              >
                <option value="null">Seleccione un campo</option>
                {fields.map((field, index) => (
                  <option value={index}>{field.name}</option>
                ))}
              </select>
            </form>
            {currentField !== null ? (
              <>
                <FieldRestrictionItem
                  data={data}
                  setData={setData}
                  field={currentField}
                />
                <Button
                  type="button"
                  onClick={applyRestrictions}
                  disabled={Object.keys(data).length ? false : true}
                  className="m-auto w-100 bg-primary border-0 "
                >
                  Aplicar
                </Button>
              </>
            ) : (
              <></>
            )}
          </section>
          <hr />
          {restrictions.length ? (
            <section className="my-4">
              <h2 className="text-center">Restricciones activas</h2>
              <form className="p-2  rounded-lg overflow-auto h-100 container">
                {restrictions.map(
                  ({
                    field,
                    value,
                    required,
                    condition,
                    _id,
                    standardFieldId,
                  }) => {
                    return (
                      <main
                        key={_id}
                        style={{ borderRadius: "1rem" }}
                        className="border px-2 my-2"
                      >
                        <h5 className="text-lg mt-2">
                          {field !== null
                            ? field?.name
                            : getFieldName(standardFieldId)}
                        </h5>

                        <div
                          key={field?.id}
                          className="d-flex my-auto  justify-content-between"
                        >
                          {
                            <p className="my-auto">
                              {condition === "eq"
                                ? "Sea igual a "
                                : condition === "ht"
                                ? "Sea mayor qué"
                                : condition === "lt"
                                ? "Sea menor qué"
                                : condition === "contains"
                                ? "Contenga"
                                : "Sin condición"}
                            </p>
                          }

                          <div className=" d-flex justify-content-between">
                            {condition && (
                              <Input
                                className="my-auto rounded-pill"
                                required={required}
                                name={field?.name}
                                value={value}
                                readOnly
                                type={field?.type as any}
                              />
                            )}
                            <button
                              onClick={() => removeRestriction(_id)}
                              type="button"
                              className="btn btn-danger w-fit h-fit my-auto mx-1 text-sm"
                            >
                              Eliminar
                            </button>
                          </div>
                        </div>
                        {required && (
                          <p className="my-auto">El campo es requerido(*)</p>
                        )}
                        <hr />
                      </main>
                    );
                  }
                )}
              </form>
            </section>
          ) : (
            <></>
          )}
        </Container>
      )}
    </>
  );
};

export default Restrictions;
