import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UnsubscribeMessage } from "../component/unsubscribeSuccess/unsubscribeSuccessMessage";
import { UNSUBSCRIBE_CONFIG } from "../../cores/unsubscribedSuccess/config";

const UnsubscribedSuccess = () => {
  const location = useLocation();
  const { webUrl } = UNSUBSCRIBE_CONFIG;
  const queryParams = new URLSearchParams(location.search);
  const [unsubscribe] = useState(queryParams.get("unsubscribe"));
  const [group] = useState(queryParams.get("group"));
  useEffect(() => {
    if (unsubscribe === null) window.location.href = webUrl!;
  }, [unsubscribe, webUrl]);

  return (
    <UnsubscribeMessage
      group={group!}
      mainUrl={webUrl!}
      unsubscribe={unsubscribe!}
    />
  );
};

export default UnsubscribedSuccess;
