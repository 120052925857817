import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  TIKTOK_SYNC_LOADING,
  TWITTER_GET_LOADING,
  TWITTER_POST_LOADING,
  TWITTER_PROFILES_GET_LOADING,
  TWITTER_SYNC_LOADING,
} from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { Media } from "../socialPost/interfaces/api/get-social-posts";
import { PostImage } from "../socialPost/interfaces/postImage";
import { PostVideo } from "../socialPost/interfaces/postVideo";
import { tiktokApiService } from "./services/api";
import { useIntl } from "react-intl";
import {
  getTiktokFailureAction,
  getTiktokInitAction,
  getTiktokLoginFailureAction,
  getTiktokLoginInitAction,
  getTiktokLoginSuccessAction,
  getTiktokProfilesInitAction,
  getTiktokProfilesSuccessAction,
  getTiktokSuccessAction,
  postBindTiktokInitAction,
  postBindTiktokSuccessAction,
  postTiktokSocialPostFailureAction,
  postTiktokSocialPostInitAction,
  postTiktokSocialPostSuccessAction,
  postUnbindTiktokFailureAction,
  postUnbindTiktokInitAction,
  postUnbindTiktokSuccessAction,
  putTiktokSocialPostFailureAction,
  putTiktokSocialPostInitAction,
  putTiktokSocialPostSuccessAction,
} from "./store/api-actions";
import {
  loginUrlSelector,
  profilesSelector,
  settingSelector,
} from "./store/selector";
import { Mentions } from "../socialPost/interfaces/Mention";

export function useTiktokCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const settings = useSelector(settingSelector);
  const loginUrl = useSelector(loginUrlSelector);
  const profiles = useSelector(profilesSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();

  const unbind = useCallback(
    async (companyId: string, onSuccess?: () => void) => {
      try {
        dispatch(postUnbindTiktokInitAction());
        await tiktokApiService.unbind(companyId);
        dispatch(postUnbindTiktokSuccessAction());
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postUnbindTiktokFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const bind = useCallback(
    async (companyId: string, code: string, onSuccess?: () => void) => {
      try {
        dispatch(postBindTiktokInitAction());
        turnOnLoading(TIKTOK_SYNC_LOADING);
        const response = await tiktokApiService.bind(companyId, {
          code,
        });
        dispatch(postBindTiktokSuccessAction({ settings: response }));
        turnOffLoading(TIKTOK_SYNC_LOADING, 500);
        toast.success(intl.formatMessage({ id: "app.toast.sucess26" }));
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(TIKTOK_SYNC_LOADING);
        toast.error(intl.formatMessage({ id: "app.toast.error55" }));
        console.error(error);
        dispatch(postUnbindTiktokFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const get = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getTiktokInitAction());
        turnOnLoading(TWITTER_GET_LOADING);
        const response = await tiktokApiService.get(companyId);
        dispatch(getTiktokSuccessAction({ settings: response }));
        turnOffLoading(TWITTER_GET_LOADING, 1500);
      } catch (error: any) {
        turnOffLoading(TWITTER_GET_LOADING);
        console.error(error);
        dispatch(getTiktokFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );

  const login = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getTiktokLoginInitAction());
        turnOnLoading(TWITTER_SYNC_LOADING);
        const response = await tiktokApiService.login(companyId);
        dispatch(getTiktokLoginSuccessAction(response));
        return response.url;
      } catch (error: any) {
        turnOffLoading(TWITTER_SYNC_LOADING);
        console.error(error);
        toast.error(intl.formatMessage({ id: "app.toast.error56" }));
        dispatch(getTiktokLoginFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const getProfiles = useCallback(
    async (companyId: string, name: string) => {
      try {
        dispatch(getTiktokProfilesInitAction());
        turnOnLoading(TWITTER_PROFILES_GET_LOADING);
        const response = await tiktokApiService.getProfiles(companyId, name);
        dispatch(getTiktokProfilesSuccessAction(response));
        turnOffLoading(TWITTER_PROFILES_GET_LOADING);
      } catch (error: any) {
        console.error(error);
        turnOffLoading(TWITTER_PROFILES_GET_LOADING);
        toast.error(`${intl.formatMessage({ id: "app.toast.error56" })}`);
        dispatch(getTiktokLoginFailureAction({ error }));
      }
    },
    [dispatch, intl, turnOffLoading, turnOnLoading]
  );

  const socialPostVideo = useCallback(
    async (
      companyId: string,
      text: string,
      mentions: Mentions[],
      video: PostVideo,
      scheduleDate: string,
      repostId: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postTiktokSocialPostInitAction());
        turnOnLoading(TWITTER_POST_LOADING);
        await tiktokApiService.socialPostVideo(
          text,
          mentions,
          video,
          scheduleDate,
          repostId,
          companyId
        );
        dispatch(postTiktokSocialPostSuccessAction());
        turnOffLoading(TWITTER_POST_LOADING);
        toast.success(
          scheduleDate
            ? intl.formatMessage({ id: "app.toast.sucess27" })
            : intl.formatMessage({ id: "app.toast.sucess28" })
        );
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(TWITTER_POST_LOADING);
        toast.error(
          scheduleDate
            ? intl.formatMessage({ id: "app.toast.error57" })
            : intl.formatMessage({ id: "app.toast.error58" })
        );
        console.error(error);
        dispatch(postTiktokSocialPostFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const socialPost = useCallback(
    async (
      companyId: string,
      text: string,
      mentions: Mentions[],
      images: PostImage[],
      scheduleDate: string,
      repostId: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postTiktokSocialPostInitAction());
        turnOnLoading(TWITTER_POST_LOADING);
        await tiktokApiService.socialPost(
          text,
          mentions,
          images,
          scheduleDate,
          repostId,
          companyId
        );
        dispatch(postTiktokSocialPostSuccessAction());
        turnOffLoading(TWITTER_POST_LOADING);
        toast.success(
          scheduleDate
            ? intl.formatMessage({ id: "app.toast.sucess27" })
            : intl.formatMessage({ id: "app.toast.sucess28" })
        );
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(TWITTER_POST_LOADING);
        toast.error(
          scheduleDate
            ? intl.formatMessage({ id: "app.toast.error57" })
            : intl.formatMessage({ id: "app.toast.error58" })
        );
        console.error(error);
        dispatch(postTiktokSocialPostFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const modifySocialPost = useCallback(
    async (
      companyId: string,
      postId: string,
      text: string,
      mentions: Mentions[],
      images: PostImage[],
      scheduleDate: string,
      media: Media[],
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putTiktokSocialPostInitAction());
        turnOnLoading(TWITTER_POST_LOADING);
        await tiktokApiService.putSocialPost(
          postId,
          text,
          mentions,
          images,
          scheduleDate,
          companyId,
          media
        );
        dispatch(putTiktokSocialPostSuccessAction());
        turnOffLoading(TWITTER_POST_LOADING);
        toast.success(
          scheduleDate
            ? intl.formatMessage({ id: "app.toast.sucess27" })
            : intl.formatMessage({ id: "app.toast.sucess28" })
        );
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(TWITTER_POST_LOADING);
        toast.error(
          scheduleDate
            ? intl.formatMessage({ id: "app.toast.error57" })
            : intl.formatMessage({ id: "app.toast.error58" })
        );
        console.error(error);
        dispatch(putTiktokSocialPostFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const modifySocialPostVideo = useCallback(
    async (
      companyId: string,
      postId: string,
      text: string,
      mentions: Mentions[],
      video: PostVideo | null,
      scheduleDate: string,
      media: Media[],
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putTiktokSocialPostInitAction());
        turnOnLoading(TWITTER_POST_LOADING);
        await tiktokApiService.putSocialPostVideo(
          postId,
          text,
          mentions,
          video,
          scheduleDate,
          companyId,
          media
        );
        dispatch(putTiktokSocialPostSuccessAction());
        turnOffLoading(TWITTER_POST_LOADING);
        toast.success(
          scheduleDate
            ? intl.formatMessage({ id: "app.toast.sucess27" })
            : intl.formatMessage({ id: "app.toast.sucess28" })
        );
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(TWITTER_POST_LOADING);
        toast.error(
          scheduleDate
            ? intl.formatMessage({ id: "app.toast.error57" })
            : intl.formatMessage({ id: "app.toast.error58" })
        );
        console.error(error);
        dispatch(putTiktokSocialPostFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  return {
    settings,
    loginUrl,
    profiles,
    unbind,
    bind,
    get,
    login,
    socialPost,
    socialPostVideo,
    modifySocialPost,
    modifySocialPostVideo,
    getProfiles,
  };
}
