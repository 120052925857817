import _ from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import {
  GMAIL_GET_LOADING,
  GMAIL_SYNC_LOADING,
} from "../../../../constants/loading.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { useMeCore } from "../../../../cores/me";
import {
  SocialProviderId,
  SocialProviderName,
} from "../../../../cores/socialPost/config";
import { SocialProvider } from "../../../../cores/socialPost/interfaces/socialProvider";
import { daysRemainingFromToday } from "../../../utils/dateUtils";
import SocialCard from "../SocialCard";
import { GmailIcon } from "./GmailIcon";
import { useGmailCore } from "../../../../cores/gmail";
import { GoogleButton } from "../Google/GoogleButton";

export default function GmailAuthButton() {
  const { get, settings, unbind, login } = useGmailCore();
  const { company, getMeSocialSettings } = useMeCore();
  const { isLoading } = useGlobalCore();

  useEffect(() => {
    get(company!.id);
  }, [company, get, login]);

  const remainingBindDays = useMemo(
    () =>
      settings?.expiresIn ? daysRemainingFromToday(settings?.expiresIn) : null,
    [settings]
  );

  const isActive = useMemo((): boolean => !_.isEmpty(settings), [settings]);

  const handleBind = useCallback(async () => {
    const loginUrl = await login(company!.id);
    if (loginUrl) window.open(loginUrl, "_self");
  }, [company, login]);

  const handleUnbind = useCallback(() => {
    unbind(company!.id, false, () => {
      getMeSocialSettings(company?.id!);
    });
  }, [company, unbind, getMeSocialSettings]);

  const socialProvider = useMemo(
    (): SocialProvider => ({
      id: SocialProviderId.Gmail,
      name: SocialProviderName.Gmail,
      icon: <GmailIcon size="6x" active={isActive} />,
      displayName: settings?.name,
      avatar: settings?.avatarUrl,
      email: settings?.email,
    }),
    [settings, isActive]
  );

  return (
    <GoogleButton
      isActive={isActive}
      onClick={!isActive ? handleBind : handleUnbind}
    />
  );
}
