import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { Badge, Col, Container, Label, Row } from "reactstrap";
import { useEventCore } from "../../../../cores/event";
import _ from "lodash";
import { useMeCore } from "../../../../cores/me";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { COLOR_DANGER, COLOR_PRIMARY } from "../../../../constants/theme";
import { InputWembii } from "../../InputWembii";
import { Errors } from "../../../../constants/errors";
import { EVENT_TIMEZONE } from "../../../../cores/event/constants/eventTimezone.constant";
import { LabelWembii } from "../../LabelWembii";
import InternalModal from "../../../layout/InternalModal";
import TimeForm from "../creator/TimeForm";
import { useGlobalCore } from "../../../../cores/globals";
import { TIME_AVAILABILITY } from "../../../../constants/internalModal.constant";
import { WeekdayOptions } from "../../../../cores/event/interface/Weekday";
import { isAlphanumeric } from "../../../utils/stringUtils";
import {
  ContactInformationLabels,
  ContactInformationOptions,
} from "../../../../cores/contacts/interfaces/DefaultInformation";
import { useContactCore } from "../../../../cores/contacts";
import { Input } from "../../../../cores/event/interface/api/get-calendar";

export default function CalendarConfig() {
  const intl = useIntl();
  const { company, user } = useMeCore();
  const { customFields } = useContactCore();
  const [useDefaultHours, setUseDefaultHours] = useState(false);
  const {
    isInternalModal,
    getInternalModalContent,
    turnOffInternalModal,
    turnOnInternalModal,
  } = useGlobalCore();
  const {
    calendar,
    inputs,
    hours,
    title,
    description,
    timezone,
    errorCode,
    refreshCalendarId,
    setDescription,
    setTimezone,
    setTitle,
    putCalendar,
    deleteCalendarTime,
    postContactInput,
    deleteContactInput,
    postCalendarTime,
  } = useEventCore();
  const [calendarId, setCalendarId] = useState("");
  const [debounceSearchText, setDebounceSearchText] = useState("");
  const [input, setInput] = useState("");

  const debouncedSearch = useRef(
    _.debounce((id) => setCalendarId(id), 1000)
  ).current;

  useEffect(() => {
    if (calendarId && calendar?.calendarId !== calendarId) {
      putCalendar(company?.id!, calendar?.id!, { calendarId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarId, company, putCalendar]);

  useEffect(() => {
    if (calendar) {
      setDebounceSearchText(calendar.calendarId);
      setTimezone(calendar.timezone);
      setTitle(calendar.title);
      setDescription(calendar.description);
    }
  }, [calendar, setTimezone, setTitle, setDescription]);

  useEffect(() => {
    debouncedSearch(debounceSearchText);
  }, [debounceSearchText, debouncedSearch]);

  const handleRefreshApikey = useCallback(() => {
    refreshCalendarId(company?.id!, calendar?.id!);
  }, [refreshCalendarId, calendar, company]);

  const error = useMemo(() => {
    switch (errorCode) {
      case Errors.DuplicatedData:
        return (
          <Label className="text-danger">
            {intl.formatMessage({ id: "app.calendar.idDuplicated" })}
          </Label>
        );
    }
  }, [errorCode, intl]);

  const getHours = useCallback(
    (weekday) => hours.filter((hour) => hour.weekday === weekday),
    [hours]
  );
  const setDefaultHours = async (e: any) => {
    e.preventDefault();
    WeekdayOptions.map((day) => {
      if (useDefaultHours) {
        getHours(day.value)?.map((hour) =>
          deleteCalendarTime(company?.id!, calendar?.calendarId!, hour.id)
        );

        setUseDefaultHours(false);
      } else {
        postCalendarTime(company?.id!, calendar?.id!, {
          weekday: day.value,
          end: "18:00",
          start: "09:00",
        });
        setUseDefaultHours(true);
      }
    });

    turnOffInternalModal(TIME_AVAILABILITY);
  };
  const onAddingHours = useCallback(
    (weekday) => {
      turnOnInternalModal(TIME_AVAILABILITY, <TimeForm weekday={weekday} />);
    },
    [turnOnInternalModal]
  );

  const onDeletingHour = useCallback(
    (id) => {
      deleteCalendarTime(company?.id!, calendar?.calendarId!, id);
    },
    [deleteCalendarTime, company, calendar]
  );

  const contactInputs = useMemo(
    () => [
      ...ContactInformationOptions.filter(
        (option) => !inputs.find((input) => input.field === option.value)
      ).map((option) => ({
        value: option.value,
        label: intl.formatMessage({ id: option.label }),
        customField: false,
      })),
      ...customFields
        .filter(
          (option) => !inputs.find((input) => input.field === `${option.id}`)
        )
        .map((option) => ({
          value: `${option.id}`,
          label: option.name,
          customField: true,
        })),
    ],
    [inputs, customFields, intl]
  );

  const getFieldName = useCallback(
    (input: Input) => {
      const name = input.customField
        ? customFields.find((field) => `${field.id}` === input.field)?.name!
        : intl.formatMessage({ id: ContactInformationLabels[input.field] });
      return name;
    },
    [customFields, intl]
  );

  const onCreatingInput = useCallback(() => {
    if (input) {
      const field = contactInputs.find((field) => field.value === input);
      postContactInput(company?.id!, calendar?.id!, {
        field: input,
        customField: field?.customField!,
      });
      setInput("");
    }
  }, [postContactInput, company, calendar, input, contactInputs]);

  const onDeletingInput = useCallback(
    (e: any, id: string) => {
      e.stopPropagation();
      deleteContactInput(company?.id!, calendar?.id!, id);
    },
    [deleteContactInput, company, calendar]
  );

  return (
    <div className="position-relative h-100">
      <InternalModal
        active={isInternalModal(TIME_AVAILABILITY)}
        onClose={() => turnOffInternalModal(TIME_AVAILABILITY)}
      >
        {getInternalModalContent(TIME_AVAILABILITY)}
      </InternalModal>
      <Container className="h-100" fluid>
        <Row className="h-100">
          <Col xs="12">
            <div className="d-flex flex-column h-100 p-4">
              <h2 className="h2 d-flex justify-content-center align-items-center mb-4">
                {intl.formatMessage({ id: "app.calendar.title" })}
              </h2>

              <InputWembii
                type="text"
                label="Calendar ID"
                required
                rounded
                validateTxt={isAlphanumeric}
                className="mt-1"
                mb={false}
                onChange={setDebounceSearchText}
                value={debounceSearchText}
                icon={
                  <FontAwesomeIcon
                    icon={["fas", "redo"]}
                    size="1x"
                    color={COLOR_PRIMARY}
                    className="m-1 pointer z-index-50"
                    onClick={handleRefreshApikey}
                  />
                }
              />
              {error && error}

              <div className="my-3">
                <InputWembii
                  type="text"
                  label={intl.formatMessage({ id: "app.calendar.field.title" })}
                  mb={false}
                  onChange={setTitle}
                  value={title}
                />
                <span className="text-muted">
                  {intl.formatMessage(
                    { id: "app.calendar.field.title.default" },
                    { user: `${user?.firstName} ${user?.lastName}` }
                  )}
                </span>
              </div>

              <InputWembii
                type="textarea"
                label={intl.formatMessage({
                  id: "app.calendar.field.description",
                })}
                onChange={setDescription}
                value={description}
              />

              <InputWembii
                type="select"
                label={intl.formatMessage({ id: "app.createEvent.timezone" })}
                required
                value={timezone}
                onChange={setTimezone}
              >
                {EVENT_TIMEZONE.map((timezone) => (
                  <option key={timezone} value={timezone}>
                    {timezone}
                  </option>
                ))}
              </InputWembii>

              <LabelWembii>Horas laborales</LabelWembii>
              <div className="d-flex ">
                <InputWembii
                  checked={useDefaultHours}
                  type="checkbox"
                  className="my-auto mx-auto"
                  onClick={(e) => setDefaultHours(e)}
                />
                <p className="my-auto mx-auto">
                  Establecer horario por defecto: 09:00 a 18:00
                </p>
              </div>
              {WeekdayOptions.map((day) => (
                <div key={day.value}>
                  <hr className="horizontal-separator" />
                  <Row>
                    <Col lg="3" className="font-weight-bold">
                      {intl.formatMessage({ id: day.label })}
                    </Col>
                    <Col>
                      {getHours(day.value)?.map((hour) => (
                        <div className="d-flex justify-content-between m-2">
                          <div>
                            {hour.start} - {hour.end}
                          </div>
                          <FontAwesomeIcon
                            icon={["fas", "trash"]}
                            className="ml-3 pointer"
                            size="lg"
                            color={COLOR_DANGER}
                            onClick={() => onDeletingHour(hour.id)}
                          />
                        </div>
                      ))}
                    </Col>
                    <Col
                      lg="1"
                      className="d-flex align-items-center justify-content-end"
                    >
                      <FontAwesomeIcon
                        icon={["fas", "plus"]}
                        className="ml-3 pointer"
                        color="gray"
                        size="lg"
                        onClick={() => onAddingHours(day.value)}
                      />
                    </Col>
                  </Row>
                </div>
              ))}
              <hr className="horizontal-separator" />

              <LabelWembii>
                {intl.formatMessage({ id: "app.contacts.info" })}
              </LabelWembii>
              <div className="d-flex align-items-center mb-2">
                <InputWembii
                  type="select"
                  mb={false}
                  className="w-100 mr-2"
                  value={input}
                  onChange={setInput}
                >
                  <option value="">
                    {intl.formatMessage({ id: "app.select.placeholder" })}
                  </option>
                  {contactInputs.map(({ value, label }) => (
                    <option key={value} value={"" + value}>
                      {label}
                    </option>
                  ))}
                </InputWembii>
                <FontAwesomeIcon
                  icon={["fas", "plus"]}
                  size="2x"
                  className="pointer"
                  onClick={onCreatingInput}
                  color={COLOR_PRIMARY}
                />
              </div>
              <div className="d-flex flex-wrap">
                <Badge
                  className="m-1 d-flex align-items-center text-light"
                  pill
                >
                  {intl.formatMessage({ id: "app.contacts.email" })}
                </Badge>
                <Badge
                  className="m-1 d-flex align-items-center text-light"
                  pill
                >
                  {intl.formatMessage({ id: "app.contacts.name" })}
                </Badge>
                {inputs.map((input) => (
                  <Badge
                    key={input.id}
                    className="m-1 d-flex align-items-center pointer text-light"
                    onClick={() => null}
                    pill
                  >
                    <span>{getFieldName(input)}</span>
                    <FontAwesomeIcon
                      icon={["fas", "times"]}
                      size="1x"
                      color="white"
                      className="m-1 z-index-5 ml-1"
                      onClick={(e) => onDeletingInput(e, input.id)}
                    />
                  </Badge>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
