import { Event } from "../../event/interface/api/get-event";
import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { OfficeEvent } from "../interface/api/get-office365-calendar";
import { Wembii365Event } from "../interface/api/get-office365-event";
import { Office365Setting } from "../interface/api/post-office365-bind";

export const postUnbindOffice365InitAction = createAction(
  "[Office365] Unbind Office365 Init"
);
export const postUnbindOffice365SuccessAction = createAction(
  "[Office365] Unbind Office365 Success",
  props<{ asUser: boolean }>()
);
export const postUnbindOffice365FailureAction = createAction(
  "[Office365] Unbind Office365 Failure",
  props<{ error: string }>()
);

export const postBindOffice365InitAction = createAction(
  "[Office365] Bind Office365 Init"
);
export const postBindOffice365SuccessAction = createAction(
  "[Office365] Bind Office365 Success",
  props<{ settings: Office365Setting; asUser: boolean }>()
);
export const postBindOffice365FailureAction = createAction(
  "[Office365] Bind Office365 Failure",
  props<{ error: string }>()
);

export const postOffice365EmailInitAction = createAction(
  "[Office365] Post Office365 Email Init"
);
export const postOffice365EmailSuccessAction = createAction(
  "[Office365] Post Office365 Email Success"
);
export const postOffice365EmailFailureAction = createAction(
  "[Office365] Post Office365 Email Failure",
  props<{ error: string }>()
);

export const getOffice365InitAction = createAction(
  "[Office365] Get Office365 Settings Init"
);
export const getOffice365CalendarEventsSuccessAction = createAction(
  "[Office365] Get Office365 Calendar Events Success",
  props<{ events: OfficeEvent[] }>()
);
export const getOffice365CalendarEventSuccessAction = createAction(
  "[Office365] Get Office365 Calendar Event Success",
  props<{ selectedEvent: Event }>()
);
export const deleteOffice365CalendarEventSuccessAction = createAction(
  "[Office365] Delete Office365 Calendar Event Success",
  props<{ id: string }>()
);
export const getOffice365SuccessAction = createAction(
  "[Office365] Get Office365 Settings Success",
  props<{ settings: Office365Setting; asUser: boolean }>()
);
export const getOffice365FailureAction = createAction(
  "[Office365] Get Office365 Settings Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  postUnbindOffice365InitAction,
  postUnbindOffice365SuccessAction,
  postUnbindOffice365FailureAction,
  postBindOffice365InitAction,
  postBindOffice365SuccessAction,
  postBindOffice365FailureAction,
  getOffice365InitAction,
  getOffice365SuccessAction,
  getOffice365FailureAction,
  postOffice365EmailInitAction,
  postOffice365EmailSuccessAction,
  postOffice365EmailFailureAction,
  getOffice365CalendarEventsSuccessAction,
  getOffice365CalendarEventSuccessAction,
  deleteOffice365CalendarEventSuccessAction,
});

export type Office365ApiAction = typeof actions;
