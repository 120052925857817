import QueryString from "query-string";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import wembiiImage from "../../assets/wembii-logo.png";
import { useGlobalCore } from "../../cores/globals";
import { useRouterCore } from "../../cores/router";
import { useSessionCore } from "../../cores/session";
import { useIntl } from "react-intl";
import { LocalSelector } from "../component/LocaleSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import useVisibility from "../../hooks/useVisibility/useVisibility.hook";

export const Login = () => {
  const intl = useIntl();
  const { search } = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { toggleVisibility, visible } = useVisibility({ initValue: false });

  const { postSession, logout } = useSessionCore();
  const { routes } = useRouterCore();
  const { reset } = useGlobalCore();

  const onFormSubmit = (e: any) => {
    e.preventDefault();
    postSession(email, password);
  };

  const disabled = useCallback(() => !email || !password, [email, password]);

  useEffect(() => {
    const { emailConfirmed } = QueryString.parse(search);
    if (emailConfirmed) {
      reset();
      logout();
    }
  }, [logout, reset, search]);

  return (
    <Container id="login" className="h-100" fluid>
      <Row className="h-100 d-flex justify-content-center">
        <Col xs="9" className="d-none d-md-block p-0">
          <div className="bg-light h-100 border-right bg" />
        </Col>
        <Col md="3" sm="8" xs="12">
          <div className="d-flex flex-column h-100 justify-content-center">
            <div className="w-100 d-flex flex-column align-items-center justify-content-center font-weight-bold">
              <img src={wembiiImage} width={200} alt="login" />
              <span className="mb-3">
                {intl.formatMessage({ id: "app.LogInToYourAccount" })}
              </span>
            </div>
            <Form onSubmit={onFormSubmit}>
              <FormGroup>
                <Label for="login-email">
                  {intl.formatMessage({ id: "app.emailTxt" })}
                </Label>
                <Input
                  type="email"
                  name="email"
                  id="login-email"
                  placeholder={intl.formatMessage({ id: "app.emailTxt" })}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="login-password" className="w-100">
                  {intl.formatMessage({ id: "app.passwordTxt" })}
                  <div className="d-flex border">
                    <Input
                      type={visible ? "text" : "password"}
                      name="password"
                      id="login-password"
                      placeholder={intl.formatMessage({
                        id: "app.passwordTxt",
                      })}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <button
                      type="button"
                      onClick={toggleVisibility}
                      className=" border border-0"
                    >
                      <FontAwesomeIcon
                        className="d-flex fixed flex-col justify-content-center p-2 "
                        icon={visible ? faEyeSlash : faEye}
                        size="1x"
                      />
                    </button>
                  </div>
                </Label>

                <div className="text-right mt-2">
                  <Link to={routes.forgotPassword}>
                    {intl.formatMessage({ id: "app.forgotPassword" })}
                  </Link>
                </div>
              </FormGroup>
              <Button
                className="mb-2"
                color="primary"
                block
                disabled={disabled()}
              >
                {intl.formatMessage({ id: "app.loginButton" })}
              </Button>

              <div className="text-center">
                <LocalSelector />
              </div>
            </Form>
            <hr className="horizontal-separator my-4" />
            <div className="align-items-center d-flex flex-column justify-content-center">
              <span>
                {intl.formatMessage({ id: "app.YouDoNotHaveAnAccount" })}
              </span>
              <Link to={routes.createAccount}>
                {intl.formatMessage({ id: "app.checkIn" })}
              </Link>
            </div>
            <hr className="horizontal-separator my-4" />

            <div className="align-items-center my-4 d-flex flex-column justify-content-end">
              <a
                target="_blank"
                className="text-sm"
                href="https://www.wembii.com/terminos-y-condiciones/"
              >
                {intl.formatMessage({ id: "app.terms" })}
              </a>
              <a
                target="_blank"
                href="https://www.wembii.com/privacy-policy/"
                className="my-2 text-sm"
              >
                {intl.formatMessage({ id: "app.privacyPolicy" })}
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
