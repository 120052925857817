import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const tiktokRoot = (state: State) => state.tiktok;

export const settingSelector = createSelector(
  tiktokRoot,
  (tiktok) => tiktok.settings
);

export const loginUrlSelector = createSelector(
  tiktokRoot,
  (tiktok) => tiktok.url
);

export const profilesSelector = createSelector(
  tiktokRoot,
  (tiktok) => tiktok.profiles
);
